import React, { useState, useRef, FC } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'

import {
  setOpen,
  setTitle,
  setChildren,
  setBD,
  showBA,
} from '../../../../store/slices/app/comps/modal.js'
import {
  Container,
  InfoBlock,
  InfoRow,
  ImageBlock,
  StyledImage,
  SliderWrapper,
  SliderInner,
  ActiveDot,
  InactiveDot,
  EmptyDot,
  ModalImage,
} from './styles.ts'

import { IMeterData } from '../../../types.models.views.ts'

interface GeneralInformationProps {
  data: IMeterData
}

const GeneralInformation: FC<GeneralInformationProps> = ({ data }) => {
  const dispatch = useDispatch()
  const slider = useRef<HTMLDivElement>(null)
  const [selectedImage, setSelectedImage] = useState(0)

  const emptyMeter = require('../../../../img/emptyMeter.jpg') as string

  const showImageFull = (param: string) => {
    dispatch(setTitle(''))
    dispatch(showBA(false))
    dispatch(setBD('Закрыть окно'))
    dispatch(setChildren(<ModalImage alt='' src={param} />))
    dispatch(setOpen(true))
  }

  const images = Array.isArray(data?.images) ? data.images : []
  const hasImages = images.length > 0
  const imageSrc = hasImages ? images[selectedImage]?.path : emptyMeter

  const infoList = [
    { id: 'registry', label: 'Номер в реестре ФИФ :', value: data?.fif_number ?? '-' },
    { id: 'name', label: 'Наименование счетчика :', value: data?.name_si ?? '-' },
    { id: 'type', label: 'Модификация :', value: data?.type_si ?? '-' },
    { id: 'country', label: 'Страна производства :', value: data?.manufacturer_countries ?? '-' },
    { id: 'city', label: 'Город производства :', value: data?.manufacturer_cities ?? '-' },
    { id: 'manufacturer', label: 'Завод производитель :', value: data?.manufacturer_name ?? '-' },
    { id: 'method', label: 'Название методики :', value: data?.methods_text ?? '-' },
    { id: 'method', label: 'Основание для применения :', value: data?.base ?? '-' },
  ]

  return (
    <Container style={{ alignItems: 'flex-start' }}>
      <InfoBlock>
        <Typography variant='h2'>Общие данные о приборе</Typography>

        {infoList.map(item => (
          <InfoRow key={item.id}>
            <Typography variant='subtitle2'>{item.label}</Typography>
            <Typography variant='subtitle1'>{item.value}</Typography>
          </InfoRow>
        ))}
      </InfoBlock>

      <ImageBlock>
        <StyledImage alt='Фото счетчика' src={imageSrc} onClick={() => showImageFull(imageSrc)} />

        <SliderWrapper>
          <SliderInner>
            {hasImages ? (
              <React.Fragment>
                <ActiveDot
                  ref={slider}
                  left={
                    selectedImage !== 0 ? `${selectedImage * 20 + selectedImage * 10}px` : '0px'
                  }
                />
                {images.map((_, index) => (
                  <InactiveDot key={index} onClick={() => setSelectedImage(index)} />
                ))}
              </React.Fragment>
            ) : (
              <EmptyDot ref={slider} />
            )}
          </SliderInner>
        </SliderWrapper>
      </ImageBlock>
    </Container>
  )
}

export default GeneralInformation
