// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Collapse } from '@mui/material'
import InnerDataCase from '../../components/Cases/InnerDataCase'
import Measurements from '../../components/Cases/Measurements'
import Methods from '../../components/Cases/Methods'
import TypeMethod from '../../components/Cases/TypeMethod'
import MethodPoint from '../../components/Cases/MethodPoint'
import CheckPlace from '../../components/Cases/CheckPlace'
import Kits from '../../components/Cases/Kits'
import { resetCreateData } from '../../../store/slices/app/views/cases'
import { refreshCases } from '../../../store/slices/app/controlers/updater'
import R from '../../../services/app/client-server/request.service.js'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import { Content, ContentFrame, LabelFrame } from './style.js'
import Button from '../../../ui/button/index.tsx'
import BackButton from '../../../ui/back-button/index.tsx'

const AddCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const innerName = useSelector(state => state.casesReducer.createData.innerName)
  const arshinName = useSelector(state => state.casesReducer.createData.arshinName)
  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)
  const methodId = useSelector(state => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)
  const kitIds = useSelector(state => state.casesReducer.createData.kitIds)

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setValidationErrors({})

    const data = {
      inner_name: innerName,
      arshin_master_id: arshinName,
      point_ids: methodPointIds,
      kit_ids: kitIds,
    }

    if (userRole === 'super') {
      data.company_id = companyId
    }

    const { status, data: responseData } = await R.addCases(data)

    if (status === 200) {
      dispatch(setMessage('Комплект СП успешно создан'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshCases())
      navigate('/metriva/cases')
    } else if (status === 422) {
      setValidationErrors(responseData.errors)
      dispatch(setMessage('Комплект СП не добавлен - ошибка валидации'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage(responseData.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className='main'>
      <BackButton to='/metriva/cases' label='Все комплекты СП' />

      <Content style={{ margin: '14px 0' }}>
        <Typography variant='h2' style={{ marginBottom: '10px' }}>
          Данные о комплекте СП
        </Typography>

        <InnerDataCase validationErrors={validationErrors} />

        <Typography variant='h2' style={{ marginBottom: '10px' }}>
          Область измерения и методика поверки
        </Typography>

        <ContentFrame style={{ marginBottom: '10px' }}>
          <LabelFrame sx={{ width: '50%' }}>
            <Measurements />
          </LabelFrame>

          <LabelFrame sx={{ width: '50%' }}>
            <Methods />
          </LabelFrame>
        </ContentFrame>

        <ContentFrame style={{ marginBottom: '10px' }}>
          <LabelFrame sx={{ width: '50%' }}>
            <CheckPlace />
          </LabelFrame>
          <LabelFrame sx={{ width: '50%' }}>
            <TypeMethod />
          </LabelFrame>
        </ContentFrame>

        <LabelFrame sx={{ width: '100%' }}>
          <MethodPoint />
          {validationErrors.point_ids && (
            <Typography
              color='error'
              sx={{ fontStyle: 'italic', paddingLeft: '10px', fontSize: '12px' }}
            >
              * {validationErrors.point_ids[0]}
            </Typography>
          )}
        </LabelFrame>

        {!!measurementId && !!methodId && !!typeMethodId && methodPointIds.length > 0 && (
          <Collapse
            in={!!measurementId && !!methodId && !!typeMethodId && methodPointIds.length > 0}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '14px',
                marginTop: '6px',
              }}
            >
              <Typography variant='h2'>Средства поверки</Typography>
              <Kits />
            </div>
          </Collapse>
        )}
      </Content>

      <div style={{ marginTop: '14px' }}>
        <Button
          onClick={handleSave}
          disabled={isDisabledBtn}
          label='Добавить комплект СП'
          fullWidth={true}
        />
      </div>
    </main>
  )
}

export default AddCases
