/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from '../LocalElements/Table/Table'
import Tooltip from '../../../ui/tooltip/index.tsx'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import PencilIcon from '../../../img/icon/PencilIcon'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro'

import { reUpdateEtalonRepairs, changeEtalon } from '../../../store/slices/app/views/etalon'

interface RowDataType {
  id: number
  reason: string
  checkPlace: string
  checkDate: string
  discription: string
  conclusion: string
}

interface TableDataType {
  id?: number
  fault_reason?: string
  place?: string
  date?: string | any
  procedure_description?: string
  conclusion?: string
}

const TabsContainerRepairTable: React.FC = () => {
  const dispatch = useDispatch()

  const tableData = useSelector(
    (state: any) => state.etalonReducer.createData.kitRepairs,
  ) as TableDataType[]

  const initializeRows = (): RowDataType[] => {
    return tableData && tableData.length > 0
      ? tableData.map((row, index) => ({
          id: index,
          reason: row.fault_reason || '-',
          checkPlace: row.place || '',
          checkDate:
            typeof row.date !== 'string' ? row.date.format('YYYY-MM-DD') : row.date ? row.date : '',
          discription: row.procedure_description || '-',
          conclusion: row.conclusion || '-',
        }))
      : []
  }

  const [rows, setRows] = useState<RowDataType[]>(initializeRows)
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    setRows(initializeRows())
  }, [tableData])

  const handleEditClick = (id: GridRowId) => () => {
    if (id === rows[0].id) {
      setRowModesModel(prevModel => ({
        ...prevModel,
        [id]: { mode: GridRowModes.Edit },
      }))
    }
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }))
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    // ----------------------------------------------------------------
    // setRows(rows.filter(row => row.id !== id))
    // ----------------------------------------------------------------

    false && console.log(tableData)
    false && console.log(id)

    dispatch(
      changeEtalon({
        value: 'kitRepairs',
        label: tableData.filter((row, index) => index !== id),
      }),
    )
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
  }

  const processRowUpdate = (newRow: RowDataType) => {
    setRows(rows.map(row => (row.id === newRow.id ? newRow : row)))
    return newRow
  }

  const colData = [
    {
      field: 'reason',
      headerName: 'Причина брака, неисправности, модификации, регулировки',
      editable: true,
    },
    {
      field: 'checkPlace',
      headerName: 'Место проведения',
      editable: true,
    },
    {
      field: 'checkDate',
      headerName: 'Дата проведения',
      editable: false,
    },
    {
      field: 'discription',
      headerName: 'Описание проведенной процедуры',
      editable: true,
    },
    {
      field: 'conclusion',
      headerName: 'Заключение',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: ({ id }: { id: GridRowId }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        const isLastRow = id === rows[0].id

        if (isInEditMode && isLastRow) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }

        if (isLastRow) {
          return [
            <Tooltip title={'Редактировать запись целиком'} disableHoverListener={undefined}>
              <GridActionsCellItem
                icon={<PencilIcon onClick={() => {}} />}
                label='Edit'
                onClick={handleEditClick(id)}
                color='inherit'
              />
            </Tooltip>,
            <Tooltip title={'Удалить запись целиком'} disableHoverListener={undefined}>
              <GridActionsCellItem
                icon={<DeleteIcon onClick={() => {}} />}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
              />
            </Tooltip>,
          ]
        }

        return [
          <Tooltip
            title={'Редактирование старых записей недоступно'}
            disableHoverListener={undefined}
          >
            <GridActionsCellItem
              icon={<PencilIcon onClick={() => {}} />}
              label='Edit'
              onClick={() => {}}
              color='inherit'
              sx={{ opacity: 0.6 }}
            />
          </Tooltip>,
          <Tooltip title={'Удалить запись целиком'} disableHoverListener={undefined}>
            <GridActionsCellItem
              icon={<DeleteIcon onClick={() => {}} />}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
            />
          </Tooltip>,
        ]
      },
    },
  ]

  return (
    <Table
      colData={colData}
      rowData={rows}
      rowCallback={() => {}}
      onRowUpdate={(updatedRows: any) => dispatch(reUpdateEtalonRepairs(updatedRows))}
      rowSelectionCallback={() => {}}
      hasCheckboxSelection={false}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10 },
        },
      }}
      rowModesModel={rowModesModel}
      onRowModesModelChange={setRowModesModel}
      onProcessRowUpdate={processRowUpdate}
    />
  )
}

export default TabsContainerRepairTable
