import React from 'react'
import Select from 'react-select'

const Selector: React.FC<any> = ({
  placeholder,
  isClearable = true,
  isSearchable,
  isMulti = false,
  onChange,
  value,
  options,
  noOptionsMessage,
  defaultValue = null,
  isDisabled = false,
  onInputChange,
}) => {
  const selectStyles = {
    control: (theme: any) => ({
      ...theme,
      border: 'none',
      outline: 'none',
      boxShadow: '0px 0px 1.5px grey',
      minHeight: '55px',
      fontSize: '14px',
      paddingBottom: '1px',
      paddingTop: '1px',
      borderRadius: '4px',
    }),
    placeholder: (theme: any) => ({
      ...theme,
      paddingLeft: '4px',
      paddingBottom: '2px',
    }),
    singleValue: (theme: any) => ({
      ...theme,
      paddingLeft: '4px',
    }),
    input: (theme: any) => ({
      ...theme,
      paddingLeft: '4px',
    }),
    menu: (theme: any) => ({
      ...theme,
      overflow: 'hidden',
      paddingTop: 6,
      paddingBottom: 6,
      width: '90%',
      marginTop: 10,
      border: 'none',
      zIndex: 20,
    }),
    menuList: (theme: any) => ({
      ...theme,
      display: 'block',
      position: 'relative',
      width: 'calc(100% + 23px)',
    }),
    option: (theme: any, { isSelected, isFocused }: any) => ({
      ...theme,
      fontSize: '13px',
      padding: '12px 0',
      paddingLeft: '13px',
      paddingBottom: '12.8px',
      lineHeight: '20px',
      cursor: 'pointer',
      color: '#2E2E2E',
      transition: 'all 400ms',
      ...(isSelected && {
        backgroundColor: '#f0f0f0',
      }),
      ...(isFocused && {
        backgroundColor: '#f0f0f0',
      }),
    }),
    multiValueLabel: (theme: any) => ({
      ...theme,
      color: '#123532',
      height: '30px',
      lineHeight: '24px',
      fontSize: '13px',
      paddingLeft: '10px',
      paddingRight: '10px',
      zIndex: 10,
    }),
    multiValueRemove: (theme: any) => ({
      ...theme,
      marginRight: '8px',
      marginLeft: '2px',
      marginTop: '1px',
      color: '#123532',
    }),
    dropdownIndicator: (provided: any, state: { selectProps: { menuIsOpen: any } }) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : null,
      transition: 'transform 0.3s ease',
    }),
  }

  return (
    <React.Fragment>
      <Select
        classNamePrefix='custom-select'
        styles={selectStyles}
        defaultValue={defaultValue ?? null}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        options={options}
        onChange={onChange}
        value={value}
        noOptionsMessage={noOptionsMessage}
        isDisabled={isDisabled}
        onInputChange={onInputChange}
        closeMenuOnSelect={true}
        blurInputOnSelect={false}
        onMenuOpen={() => {}}
        onMenuClose={() => {}}
      />
    </React.Fragment>
  )
}

export default React.memo(Selector)
