// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import Input from '../../../ui/input/index.tsx'
import Tooltip from '../../../ui/tooltip/index.tsx'
import Button from '../../../ui/button/index.tsx'
import InfoCircle from '../../../img/icon/InfoCircle'
import R from '../../../services/app/client-server/request.service.js'
import Selector from '../../components/LocalElements/Selector/reactSelect/Selector'
import { resetCreateData, changeData } from '../../../store/slices/app/views/masterArshin'
import { refreshMasterArshin } from '../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import { Content, ContentFrame, LabelFrame } from './style.js'
import BackButton from '../../../ui/back-button/index.tsx'

const AddOfficialUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [errors, setErrors] = useState({})
  const [data, setData] = useState([])
  const userRole = useSelector(state => state.headerReducer.role)
  const companyId = useSelector(state => state.companyIdReducer.companyId)

  const name = useSelector(state => state.masterArshinReducer.createData.name)
  const surname = useSelector(state => state.masterArshinReducer.createData.surname)
  const patr = useSelector(state => state.masterArshinReducer.createData.patr)
  const role = useSelector(state => state.masterArshinReducer.createData.role)
  const snils = useSelector(state => state.masterArshinReducer.createData.snils)
  const measurementIds = useSelector(state => state.masterArshinReducer.createData.measurementIds)

  const handleNameChange = param => {
    dispatch(changeData({ value: 'name', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, name: '' }))
  }
  const handleSurNameChange = param => {
    dispatch(changeData({ value: 'surname', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, surname: '' }))
  }
  const handlePatrNameChange = param => {
    dispatch(changeData({ value: 'patr', label: param }))
  }
  const handleRoleChange = param => {
    dispatch(changeData({ value: 'role', label: param }))
  }
  const handleSnilsChange = param => {
    let formattedValue = param
      .replace(/\D/g, '')
      .slice(0, 11)
      .replace(/(\d{3})(?=\d{3})/g, '$1-')
      .replace(/(\d{2})$/, ' $1')
    dispatch(changeData({ value: 'snils', label: formattedValue }))
    setErrors(prevErrors => ({ ...prevErrors, snils: '' }))
  }

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(changeData({ value: 'measurementIds', label: selectedIds }))
    setErrors(prevErrors => ({ ...prevErrors, measurements: '' }))
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      surname: surname,
      patr: patr,
      post: role,
      snils: snils,
      measurements: measurementIds,
    }

    if (userRole === 'super') {
      data.company_id = companyId
    }

    const { status, data: caseResponse } = await R.postMaster(data)

    if (status === 200) {
      dispatch(setMessage('Мастер успешно создан.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshMasterArshin())
      navigate('/metriva/cases')
    } else if (status === 422) {
      setErrors(caseResponse.errors || {})
      dispatch(setMessage('Мастер не добавлен - ошибка валидации'))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage(caseResponse.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className='main'>
      <BackButton to='/metriva/cases' label='Все официальные пользователи СП' />

      <Content style={{ margin: '0px 0px' }}>
        <Typography variant='h2' style={{ marginBottom: '10px' }}>
          Основные данные
        </Typography>

        <ContentFrame style={{ marginBottom: '10px' }}>
          <LabelFrame sx={{ width: '32.8%' }}>
            <Input
              label={'Фамилия пользователя'}
              type={'text'}
              placeholder={'Введите фамилию'}
              value={surname}
              error={!!errors.surname}
              actions={{
                change: handleSurNameChange,
              }}
            />
            {errors.surname && <div className='error'>{errors.surname[0]}</div>}
          </LabelFrame>

          <LabelFrame sx={{ width: '32.8%' }}>
            <Input
              label={'Имя пользователя'}
              type={'text'}
              placeholder={'Введите имя'}
              value={name}
              error={!!errors.name}
              actions={{
                change: handleNameChange,
              }}
            />
            {errors.name && <div className='error'>{errors.name[0]}</div>}
          </LabelFrame>

          <LabelFrame sx={{ width: '32.8%' }}>
            <Input
              label={'Отчество (необязательно)'}
              type={'text'}
              placeholder={'Введите отчество'}
              value={patr}
              actions={{
                change: handlePatrNameChange,
              }}
            />
          </LabelFrame>
        </ContentFrame>

        <ContentFrame>
          <LabelFrame sx={{ width: '32.8%' }}>
            <Input
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Должность в компании
                  <Tooltip
                    title='Рабочая должность официального пользователя комплекта СП, нужна для заполнения журнала выдачи средств поверки'
                    style={{ marginLeft: '7px' }}
                  >
                    <InfoCircle />
                  </Tooltip>
                </div>
              }
              type={'text'}
              placeholder={'Пример ввода - Поверитель СИ'}
              value={role}
              actions={{
                change: handleRoleChange,
              }}
            />
          </LabelFrame>

          <LabelFrame sx={{ width: '32.8%' }}>
            <Input
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Номер СНИЛС
                  <Tooltip
                    title='Информация необходима для передачи данных в Росаккредитацию (ФСА)'
                    style={{ marginLeft: '7px' }}
                  >
                    <InfoCircle />
                  </Tooltip>
                </div>
              }
              type={'text'}
              placeholder={'XXX-XXX-XXX XX'}
              value={snils}
              error={!!errors.snils}
              actions={{
                change: handleSnilsChange,
              }}
            />
            {errors.snils && <div className='error'>{errors.snils[0]}</div>}
          </LabelFrame>

          <LabelFrame sx={{ width: '32.8%' }}>
            <Selector
              placeholder={'Выберите область измерния'}
              options={options}
              value={options.filter(option => measurementIds.includes(option.value))}
              onChange={getValue}
              isMulti={true}
              isSearchable={true}
              noOptionsMessage={() => 'Нет доступных областей измерения'}
              label={{
                text: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Рабочая область измерения
                    <Tooltip
                      title='Область или области измерения, в рамках которой пользователь имеет официальное право осуществлять поверочную деятельность'
                      style={{ marginLeft: '7px' }}
                    >
                      <InfoCircle />
                    </Tooltip>
                  </div>
                ),
                topBg: 'white',
                bottomBg: 'white',
              }}
            />
            {errors.measurements && <div className='error'>{errors.measurements[0]}</div>}
          </LabelFrame>
        </ContentFrame>
      </Content>

      <div style={{ marginTop: '14px' }}>
        <Button
          onClick={handleSave}
          disabled={isDisabledBtn}
          label='Добавить пользователя'
          fullWidth
        />
      </div>
    </main>
  )
}

export default AddOfficialUser
