// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Fab, useMediaQuery } from '@mui/material'
import Table from '../../LocalElements/Table/Table'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import FilterMeasurement from '../../LocalElements/Filter/FilterMeasurement'
import MasterArshin from '../../LocalElements/Filter/AccountingVerification'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import InfoCircle from '../../../../img/icon/InfoCircle'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import { refreshReceiveds, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  showBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setSearchQuery,
  setComplete,
  setDateFrom,
  setDateUntil,
  setCreateDateFrom,
  setCreateDateUntil,
  setCreator,
  setTotalAccepted,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
  setTypeMethod,
  setMasterArshin,
} from '../../../../store/slices/app/views/journals'
import { setMeasurementIdFilter } from '../../../../store/slices/app/views/protocols'
import Tooltip from '../../../../ui/tooltip/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index.tsx'
import theme from '../../../../utils/theme.ts'
import { IconFrame, Wrapper, FrameFilter } from '../style'
import R from '../../../../services/app/client-server/request.service.js'
import { archiveTitle, archiveMessage, ModalContent } from '../archiveModal/archiveModal'

const TableReceived = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { handleDownload } = useExcelCsvPdf()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const userRole = useSelector(state => state.headerReducer.role)
  const data = useSelector(state => state.journalReducer.dataReceived)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const complete = useSelector(state => state.journalReducer.complete)
  const dateFrom = useSelector(state => state.journalReducer.dateFrom)
  const dateUntil = useSelector(state => state.journalReducer.dateUntil)
  const createDateFrom = useSelector(state => state.journalReducer.createDateFrom)
  const createDateUntil = useSelector(state => state.journalReducer.createDateUntil)
  const creator = useSelector(state => state.journalReducer.creator)
  const totalAccepted = useSelector(state => state.journalReducer.totalAccepted)
  const typeMethod = useSelector(state => state.journalReducer.typeMethod)

  const journalId = useSelector(state => state.journalReducer.journalId)
  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData,
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date

  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)
  const entryId = useSelector(state => state.journalReducer.entryId)

  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (masterArshine) filtersCount++
    if (dateFrom) filtersCount++
    if (dateUntil) filtersCount++
    if (createDateFrom) filtersCount++
    if (createDateUntil) filtersCount++
    if (creator) filtersCount++
    if (totalAccepted) filtersCount++
    if (measurementIdFilter) filtersCount++
    if (typeMethod) filtersCount++

    if (
      !complete &&
      !dateFrom &&
      !dateUntil &&
      !createDateFrom &&
      !createDateUntil &&
      !creator &&
      !totalAccepted &&
      !measurementIdFilter &&
      !typeMethod &&
      !masterArshine
    )
      filtersCount = 0
    return filtersCount
  }, [
    complete,
    createDateFrom,
    createDateUntil,
    creator,
    dateFrom,
    dateUntil,
    measurementIdFilter,
    totalAccepted,
    typeMethod,
    masterArshine,
  ])

  const resetActions = [
    setComplete,
    setDateFrom,
    setDateUntil,
    setCreateDateFrom,
    setCreateDateUntil,
    setCreator,
    setTotalAccepted,
    setMeasurementIdFilter,
    setTypeMethod,
    setMasterArshin,
  ]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const changeMeasurementId = param => {
    dispatch(setMeasurementIdFilter(param))
  }

  const changeUserId = param => {
    dispatch(setMasterArshin(param))
  }

  const changeComplete = event => {
    dispatch(setComplete(event.target.value))
  }

  const changeTotalAccepted = event => {
    dispatch(setTotalAccepted(event.target.value))
  }

  const changeTypeMethod = event => {
    dispatch(setTypeMethod(event.target.value))
  }

  const handleDateFromChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(''))
    }
  }

  const handleDateUntilChange = date => {
    if (date) {
      dispatch(setDateUntil(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateUntil(''))
    }
  }

  const handleCreateDateFromChange = date => {
    if (date) {
      dispatch(setCreateDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setCreateDateFrom(''))
    }
  }

  const handleCreateDateUntilChange = date => {
    if (date) {
      dispatch(setCreateDateUntil(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setCreateDateUntil(''))
    }
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_received`)
  }

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_received')
  }

  const handleDelete = async journalId => {
    const { status } = await R.deleteReceived(journalId, companyId)
    if (status === 200) {
      dispatch(refreshReceiveds())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpenModal(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
      dispatch(resetState())
    }
  }

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveReceivedJournal(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(false))
      dispatch(refreshReceiveds())
      dispatch(refreshArchives())
      dispatch(resetState())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(
        setTitle(
          <Typography variant='subtitle1' sx={{ color: theme.palette.secondary.dark }}>
            Архивация приостановлена
          </Typography>,
        ),
      )
      dispatch(
        setChildren(
          <div style={{ width: '620px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant='subtitle1'>
              Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
              некоторых записях документа
            </Typography>
            <Typography variant='subtitle1'>
              Пожалуйста, найдите такие записи с помощью фильтра "Заполнение записи", дождитесь
              появления / внесите недостающие данные и повторите процесс архивации.
            </Typography>
          </div>,
        ),
      )
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpenModal(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )
    const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Zhurnal_ucheta_postupivshih_na_poverku_SI_i_provedeniya_poverochnyh_rabot_(arhiv)_${currentDate}.pdf`,
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (archiveDateFromBySettings && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFromBySettings, archiveDateTo])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  let colData = [
    { field: 'Num', headerName: '№ п/п' },
    {
      field: 'IncomingDate',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Дата поступления СИ
          <Tooltip
            title={'Данные добавляются в запись только при проведении поверки в лаборатории'}
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'WhoGet',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО принявшего СИ
          <Tooltip
            title={
              'Необходимо указать ФИО принимающего СИ во вкладке Настройки. Данные добавляются в запись только при проведении поверки в лаборатории'
            }
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    { field: 'Owner', headerName: 'Наименование владельца СИ' },
    { field: 'Num SI', headerName: 'Номер СИ в госреестре' },
    { field: 'Name SI', headerName: 'Наименование и тип СИ' },
    { field: 'Num customer', headerName: 'Заводской номер' },
    { field: 'Check date', headerName: 'Дата поверки' },
    { field: 'Num protocol', headerName: 'Номер протокола поверки' },
    {
      field: 'Name executor',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО проводившего поверку
          <Tooltip
            title={'Владелец комплекта СИ, чьи данные передаются во ФГИС «Аршин» и ФСА'}
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    { field: 'Conclusion', headerName: 'Заключение' },
    { field: 'numCertificate', headerName: '№ свидетельства / извещения' },
    {
      field: 'Final date',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Дата выдачи СИ заказчику
          <Tooltip
            title={'Данные добавляются в запись только при проведении поверки в лаборатории'}
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'WhoPass',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ФИО выдавшего СИ
          <Tooltip
            title={
              'Необходимо указать Ф.И.О выдающего СИ во вкладке “Настройки”. Данные добавляются в запись только при проведении поверки в лаборатории.'
            }
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {params.row.isAutogenerated && params.row.verificationMethod === 'field' ? (
              <Tooltip title='Редактирование данных доступно только через протокол т.к. он является источником данных для записей о выездных поверках.'>
                <div>
                  <PencilIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}
            <Tooltip title='Удалить запись'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOpenModal(true))
                  dispatch(showBA(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={archiveDateFromBySettings}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  const StaticControls = (
    <IconFrame>
      {count > 0 ? (
        <Tooltip title='Для скачивания PDF отключите фильтры'>
          <div style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}>
            <Pdf />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title='Скачать в формате PDF'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={async () => {
              !entryId && handleDownload('pdf')
              !!entryId && handleDownloadSelectionPdf()
            }}
          >
            <Pdf />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Csv'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('csv')}
          >
            <Csv />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Excel'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('excel')}
          >
            <Excel />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <React.Fragment>
          {count > 0 ? (
            <Tooltip title='Для архивации документа отключите фильтры'>
              <div
                style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}
              >
                <ArchiveIcon />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Архивировать документ'>
              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <ArchiveIcon
                  onClick={() => {
                    dispatch(setTitle('Архивация документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(setBA('Архивировать'))
                    dispatch(setBD('Отменить'))
                    dispatch(showBD(true))
                    dispatch(setConfirmAction(handleArchiveClick))
                    dispatch(setOpenModal(true))
                  }}
                />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </IconFrame>
  )

  return (
    <main>
      <RightSideContainer blockTitle={'Фильтры'}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle1'>Область измерения</Typography>
            <FilterMeasurement
              measurementId={measurementIdFilter}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>ФИО проводившего поверку</Typography>
            <MasterArshin userId={masterArshine} setUserId={changeUserId} />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Заключение поверки</Typography>
            <RadioButton
              value={totalAccepted}
              onChange={changeTotalAccepted}
              trueLabel={'Пригоден'}
              falseLabel={'Непригоден'}
              trueValue={'accepted'}
              falseValue={'non-accepted'}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Способ поверки</Typography>
            <RadioButton
              value={typeMethod}
              onChange={changeTypeMethod}
              trueLabel={'На выезде'}
              falseLabel={'В лаборатории'}
              trueValue={'field'}
              falseValue={'laboratory'}
            />
          </FrameFilter>
          {!entryId && (
            <FrameFilter>
              <Typography variant='subtitle1'>Заполнение записи</Typography>
              <RadioButton
                value={complete}
                onChange={changeComplete}
                trueLabel={'Частичное'}
                falseLabel={'Полное'}
                trueValue={'non-complete'}
                falseValue={'complete'}
              />
            </FrameFilter>
          )}
          <FrameFilter>
            <Typography variant='subtitle1'>Создатель записи</Typography>
            <RadioButton
              value={creator}
              onChange={event => dispatch(setCreator(event.target.value))}
              trueLabel={'Система'}
              falseLabel={'Пользователь'}
              trueValue={'system'}
              falseValue={'user'}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата поверки</Typography>
            <DatePicker selectedDate={dateFrom} dateChange={handleDateFromChange} />

            <DatePicker selectedDate={dateUntil} dateChange={handleDateUntilChange} />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата создания записи</Typography>
            <DatePicker selectedDate={createDateFrom} dateChange={handleCreateDateFromChange} />

            <DatePicker selectedDate={createDateUntil} dateChange={handleCreateDateUntilChange} />
          </FrameFilter>
          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>
      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          Num: item.number,
          IncomingDate: item?.received_date ?? '-',
          Owner: item.customer_arshin_name ?? '-',
          WhoGet: item.received_by_name ?? '-',
          'Num SI': item.fif_number ?? '-',
          'Name SI': item.name_type_si ?? '-',
          'Num customer': item.meter_factory_number ?? '-',
          'Check date': item.verification_date ?? '-',
          'Num protocol': item.protocol_number ?? '-',
          'Name executor': item.arshin_master_name ?? '-',
          Conclusion: item.total_accepted === true ? 'Пригоден' : 'Не пригоден',
          numCertificate: item.certificate ?? '-',
          'Final date': item.issued_date ?? '-',
          WhoPass: item.issued_by_name ?? '-',
          isAutogenerated: item.is_autogenerated ?? '-',
          verificationMethod: item.verification_method ?? '-',
        }))}
        hasCheckboxSelection={false}
        noRowsText={'Записи отсутствуют'}
        showToolbar={true}
        noCreating={!entryId ? false : true}
        actions={{
          static: StaticControls,
          dinamic: null,
        }}
        filtersVariant={'server'}
        addButtonCallback={handleAddClick}
        setIsDrawerOpenServer={() => {
          dispatch(setOpenRightContainer(true))
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </main>
  )
}

export default TableReceived
