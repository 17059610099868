// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Collapse } from '@mui/material'
import InnerDataCase from '../../components/Cases/InnerDataCase'
import Measurements from '../../components/Cases/Measurements'
import Methods from '../../components/Cases/Methods'
import TypeMethod from '../../components/Cases/TypeMethod'
import MethodPoint from '../../components/Cases/MethodPoint'
import CheckPlace from '../../components/Cases/CheckPlace'
import Kits from '../../components/Cases/Kits'
import R from '../../../services/app/client-server/request.service.js'
import Feedback from '../../components/LocalElements/Feedback/Feedback'
import { setShow } from '../../../store/slices/app/comps/feedback'
import { changeData, resetCreateData } from '../../../store/slices/app/views/cases'
import { refreshCases } from '../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import { Content, ContentFrame, LabelFrame } from './style.js'
import Button from '../../../ui/button/index.tsx'
import BackButton from '../../../ui/back-button/index.tsx'

const EditCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cases_id } = useParams()

  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  const innerName = useSelector(state => state.casesReducer.createData.innerName)
  const arshinName = useSelector(state => state.casesReducer.createData.arshinName)
  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)
  const methodId = useSelector(state => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)
  const kitIds = useSelector(state => state.casesReducer.createData.kitIds)

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: caseData } = await R.getCasesShow(cases_id)
        const data = caseData.data

        dispatch(
          changeData({
            value: 'innerName',
            label: data.inner_name,
          }),
        )
        dispatch(
          changeData({
            value: 'arshinName',
            label: data.arshinMaster?.id,
          }),
        )

        dispatch(
          changeData({
            value: 'measurementId',
            label: data.method?.measurement.id,
          }),
        )
        dispatch(
          changeData({
            value: 'methodId',
            label: data.method?.id,
          }),
        )

        const methodPlaces = data.points.map(point => point.verification_method)

        dispatch(
          changeData({
            value: 'methodPlaces',
            label: methodPlaces,
          }),
        )

        const typeMethod = data.points.map(point => point.verification_type)

        dispatch(
          changeData({
            value: 'typeMethodId',
            label: typeMethod,
          }),
        )

        const pointIds = data.points.map(point => point.id)
        dispatch(
          changeData({
            value: 'methodPoints',
            label: pointIds,
          }),
        )

        const kitIds = data.kits.map(kit => ({ deviceId: kit.device_id, id: kit.id }))

        dispatch(
          changeData({
            value: 'deviceIds',
            label: kitIds,
          }),
        )
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [cases_id])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setValidationErrors({})

    const data = {
      inner_name: innerName,
      arshin_master_id: arshinName,
      point_ids: methodPointIds,
      kit_ids: kitIds,
    }

    const { status, data: responseData } = await R.addCasesPut(cases_id, data)

    if (status === 200) {
      dispatch(setMessage('Комплект СП успешно отредактирован'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshCases())
      navigate('/metriva/cases')
    } else if (status === 422) {
      setValidationErrors(responseData.errors)
      dispatch(setMessage(`Комплект СП не отредактирован - ошибка валидации`))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage(responseData.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <BackButton to='/metriva/cases' label='Все комплекты СП' />

          <Content style={{ margin: '14px 0' }}>
            <Typography variant='h2' style={{ marginBottom: '10px' }}>
              Данные о комплекте СП
            </Typography>

            <InnerDataCase validationErrors={validationErrors} />

            <Typography variant='h2' style={{ marginBottom: '10px' }}>
              Область измерения и методика поверки
            </Typography>

            <ContentFrame style={{ marginBottom: '10px' }}>
              <LabelFrame sx={{ width: '50%' }}>
                <Measurements />
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Methods />
              </LabelFrame>
            </ContentFrame>

            <ContentFrame style={{ marginBottom: '10px' }}>
              <LabelFrame sx={{ width: '50%' }}>
                <CheckPlace />
              </LabelFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <TypeMethod />
              </LabelFrame>
            </ContentFrame>

            <LabelFrame sx={{ width: '100%' }}>
              <MethodPoint />
              {validationErrors.point_ids && (
                <Typography
                  color='error'
                  sx={{ fontStyle: 'italic', paddingLeft: '10px', fontSize: '12px' }}
                >
                  * {validationErrors.point_ids[0]}
                </Typography>
              )}
            </LabelFrame>

            {!!measurementId && !!methodId && !!typeMethodId && methodPointIds.length > 0 && (
              <Collapse
                in={!!measurementId && !!methodId && !!typeMethodId && methodPointIds.length > 0}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '14px',
                    marginTop: '6px',
                  }}
                >
                  <Typography variant='h2'>Средства поверки</Typography>
                  <Kits />
                </div>
              </Collapse>
            )}
          </Content>

          <Button
            onClick={handleSave}
            disabled={isDisabledBtn}
            label='Редактировать комплект СП'
            fullWidth
          />
        </React.Fragment>
      )}
    </main>
  )
}

export default EditCases
