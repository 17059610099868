import React from 'react'
import api from '../client-server/axios-config.service'
import { saveAs } from 'file-saver'
import Cookies from 'js-cookie'
import PdfIcon from '../../../img/icon/PdfIcon'

const PdfDownloadButton = ({ measurementId, meterId, title = 'undefined' }) => {
  const handlePdfDownload = async () => {
    try {
      const token = Cookies.get('accessToken')
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${measurementId}/${meterId}/pdf`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/pdf',
      }

      const response = await api.get(apiUrl, {
        headers,
        responseType: 'blob',
      })

      const fileName = `${title}.pdf`

      saveAs(response.data, fileName)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div style={{ cursor: 'pointer' }}>
      <PdfIcon onClick={handlePdfDownload} />
    </div>
  )
}

export default PdfDownloadButton
