import React from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import Input from '../../../../../LocalElements/Input/InputMUI.jsx'
import { sanitizeInput } from '../../../../../../../utils/sanitizeInput.js'
import { changeProperty } from '../../state.js'

export const MeasurementInputs = ({
  index,
  start,
  measurementConsumption,
  finish,
  volumeEtalon,
  fieldStart,
  fieldMeasurementConsumption,
  fieldFinish,
  fieldVolumeEtalon,
}) => {
  const dispatch = useDispatch()

  const handleChange = (field, value) => {
    dispatch(changeProperty({ value: field, label: sanitizeInput(value) }))
  }

  return (
    <div className='flexContainerWithGap' style={{ alignItems: 'center' }}>
      <div className='numberBox'>
        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>{index}</Typography>
      </div>

      <div className='width24Point5Percent'>
        <Input
          label={'Показания на начало'}
          placeholder={'0.000'}
          value={start}
          actions={{ change: value => handleChange(fieldStart, value) }}
        />
      </div>

      <div className='width24Point5Percent'>
        <Input
          label={'Расход во время измерения'}
          placeholder={'0.0000'}
          value={measurementConsumption}
          actions={{ change: value => handleChange(fieldMeasurementConsumption, value) }}
        />
      </div>

      <div className='width24Point5Percent'>
        <Input
          label={'Показания на конец'}
          placeholder={'0.000'}
          value={finish}
          actions={{ change: value => handleChange(fieldFinish, value) }}
        />
      </div>

      <div className='width24Point5Percent'>
        <Input
          label={'Объём, по показаниям эталона, м³'}
          placeholder={'0.000000'}
          value={volumeEtalon}
          actions={{ change: value => handleChange(fieldVolumeEtalon, value) }}
        />
      </div>
    </div>
  )
}
