import React from 'react'
import { StyledRadioGroup, StyledFormControlLabel, StyledRadio } from './styles.ts'

const RadioButton: React.FC<any> = ({
  value = '',
  onChange,
  trueLabel,
  falseLabel,
  trueValue,
  falseValue,
  threeValue,
  threeLabel = 'ПО отсутствует',
  trueDisabled = false,
  falseDisabled = false,
  threeDisabled = false,
  threeBtn = false,
  notMatch = false,
}) => {
  return (
    <StyledRadioGroup
      row
      aria-labelledby='demo-row-radio-buttons-group-label'
      name='row-radio-buttons-group'
      value={value}
      onChange={onChange}
    >
      <StyledFormControlLabel
        value={trueValue}
        control={<StyledRadio />}
        label={trueLabel}
        disabled={trueDisabled}
      />
      <StyledFormControlLabel
        value={falseValue}
        control={<StyledRadio notMatch={notMatch} />}
        label={falseLabel}
        disabled={falseDisabled}
      />
      {threeBtn && (
        <StyledFormControlLabel
          value={threeValue}
          control={<StyledRadio />}
          label={threeLabel}
          disabled={threeDisabled}
        />
      )}
    </StyledRadioGroup>
  )
}

export default RadioButton
