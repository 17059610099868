import { changeProperty } from './state.js'

export const getAcceptanceChecks = (isMechanicalAccepted, isTightnessAccepted, dispatch) => [
  {
    title: 'Внешний осмотр',
    description:
      'Раздел 2 «Методика поверки «Pr»: есть возможность визуально считывать показания счётчика, не нарушена целостность счётного механизма счетчика, отсутствуют механические повреждения на корпусе счётчика, комплектность счётчика соответствует описанию типа, знак утверждения типа нанесён на счётчик в установленном виде',
    label: 'Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015',
    value: isMechanicalAccepted,
    onChange: event => {
      const newValue = event.target.value === 'true'
      dispatch(changeProperty({ value: 'isMechanicalAccepted', label: newValue }))
    },
  },
  {
    title: 'Опробование',
    description:
      'Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5 минут в местах соединений и на корпусах счётчиков и эталона не наблюдается каплевыделений и течи воды',
    label: 'Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015',
    value: isTightnessAccepted,
    onChange: event => {
      const newValue = event.target.value === 'true'
      dispatch(changeProperty({ value: 'isTightnessAccepted', label: newValue }))
    },
  },
]
