export const getProtocolData = state => {
  const {
    caseId,
    verificationType,
    pointId,
    customerName,
    customerAddress,
    customerFullName,
    customerPhoneNumber,
    isMechanicalAccepted,
    isTightnessAccepted,
    isMarkPassport,
    isMarkSi,
    isTotalAccepted,
    indications,
    conditions,
    meterId,
    meterViewStatus,
    valueMod,
    valueAnotherMod,
    valueAdditional,
    meterFactoryNumber,
    meterYear,
    is_custom_modification,
    diameter,
    meterLocation,
    meterType,
    q_min,
    q_t,
    q_max,
    q_actual,
    q_min_limit,
    q_max_limit,
    defaultData,
    dateVerification,
    nextVerificationDate,
    nextVerificationDateAutoGenerate,
    agreementNumber,
    comment,
    file,
    doChecked,
    details,
  } = state

  return {
    case_id: caseId,
    verification_type_id: verificationType,
    point_id: pointId,

    customer_arshin_name: customerName,
    customer_address: customerAddress,
    customer_fullname: customerFullName,
    customer_phone: customerPhoneNumber,

    mechanical_accepted: isMechanicalAccepted,
    tightness_accepted: isTightnessAccepted,
    mark_passport: isMarkPassport,
    mark_si: isMarkSi,
    total_accepted: isTotalAccepted,

    indications,
    conditions,

    ...(meterViewStatus === undefined || meterViewStatus === 'default'
      ? { meterable_fif_number: meterId }
      : {}),

    details: {
      id: details,
      modification: valueMod === 'another' ? valueAnotherMod : valueMod,
      additional_option: valueAdditional || null,
      meter_factory_number: meterFactoryNumber,
      meter_factory_year: meterYear,
      is_custom_modification,
      diameter: parseFloat(diameter),
      orientation: meterLocation,
      meter_type: meterType,
      q_min: parseFloat(q_min),
      q_t: parseFloat(q_t),
      q_max: parseFloat(q_max),
      q_actual: parseFloat(q_actual),
      q_min_limit: parseFloat(q_min_limit),
      q_max_limit: parseFloat(q_max_limit),
      conversion_factor: defaultData?.conversion_factor,
      mpi: defaultData.mpi_cold ?? defaultData.mpi_hot,
    },

    verification_date: dateVerification,
    next_verification_date: nextVerificationDateAutoGenerate || nextVerificationDate,
    agreement_number: agreementNumber,

    comment_files: comment,
    files: file,
    is_send: doChecked,
  }
}
