// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getProtocolData } from '../getProtocolData.js'
import { getAcceptanceChecks } from '../acceptanceChecks'
import useNextVerificationDate from '../getNextVerificationDate.js'
import api from '../../../../../../services/app/client-server/axios-config.service.js'
import formatPhoneNumber from '../../../../../../utils/formatPhoneNumber.js'

import MeterData from '../../../views/MeterData/MeterWater/MeterData.jsx'
import DescriptionType from '../components/DescriptionType.jsx'
import Customer from '../../../views/Customer/Customer.jsx'
import Accepted from '../../../views/Accepted/Accepted.jsx'
import Conditions from '../components/ConditionsAdd.jsx'
import Indications from '../components/IndicationsAdd.jsx'
import OpticalSensor from '../components/OpticalSensorAdd.jsx'
import TotalAccepted from '../../../views/TotalAccepted/TotalAccepted.jsx'
import VerificationMark from '../../../views/VerificationMark/VerificationMark.jsx'
import ProtocolDate from '../../../views/ProtocolDate/ProtocolDate.jsx'
import ImageUpload from '../../../views/ImagesUploading/ImagesUploading.jsx'
import DataTransfer from '../../../views/DataTransfer/DataTransfer.jsx'

import Button from '@mui/material/Button'

import { changeProperty, setDefaultState } from '../state.js'
import {
  resetCase,
  resetMeasurementId,
} from '../../../../../../store/slices/app/views/protocols.js'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../../store/slices/app/comps/snackbar.js'
import {
  refreshReceiveds,
  refreshConditions,
  refreshIssueCase,
  refreshPersonalMetrolog,
  refreshTickets,
  refreshProtocols,
} from '../../../../../../store/slices/app/controlers/updater.js'

import css from '../../../styles.css.js'
const { PageContainer, BoxContainer } = css

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const token = useSelector(state => state.authReducer.authData.token)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const verificationType = useSelector(state => state.protocolReducer.case.verificationType)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterId = useSelector(state => state.mi15922015Reducer.meterId)
  const modifications = useSelector(state => state.mi15922015Reducer.modifications)
  const selectedMod = useSelector(state => state.mi15922015Reducer.selectedMod)
  const selectedAdditionals = useSelector(state => state.mi15922015Reducer.selectedAdditionals)
  const valueMod = useSelector(state => state.mi15922015Reducer.valueMod)
  const valueAdditional = useSelector(state => state.mi15922015Reducer.valueAdditional)
  const valueAnotherMod = useSelector(state => state.mi15922015Reducer.valueAnotherMod)
  const is_custom_modification = useSelector(
    state => state.mi15922015Reducer.is_custom_modification,
  )
  const defaultData = useSelector(state => state.mi15922015Reducer.defaultData)
  const mpiColdAdditionals = useSelector(state => state.mi15922015Reducer.mpiColdAdditionals)
  const mpiHotAdditionals = useSelector(state => state.mi15922015Reducer.mpiHotAdditionals)
  const meterFactoryNumber = useSelector(state => state.mi15922015Reducer.meterFactoryNumber)
  const meterYear = useSelector(state => state.mi15922015Reducer.meterYear)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const customerName = useSelector(state => state.mi15922015Reducer.customerName)
  const customerNameType = useSelector(state => state.mi15922015Reducer.customerNameType)
  const customerAddress = useSelector(state => state.mi15922015Reducer.customerAddress)
  const customerFullName = useSelector(state => state.mi15922015Reducer.customerFullName)
  const customerPhoneNumber = useSelector(state => state.mi15922015Reducer.customerPhoneNumber)
  const agreementNumber = useSelector(state => state.mi15922015Reducer.agreementNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const diameter = useSelector(state => state.mi15922015Reducer.diameter)
  const meterLocation = useSelector(state => state.mi15922015Reducer.meterLocation)
  const meterType = useSelector(state => state.mi15922015Reducer.meterType)
  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_max = useSelector(state => state.mi15922015Reducer.q_max)
  const q_actual = useSelector(state => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMechanicalAccepted = useSelector(state => state.mi15922015Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector(state => state.mi15922015Reducer.isTightnessAccepted)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMarkPassport = useSelector(state => state.mi15922015Reducer.isMarkPassport)
  const isMarkSi = useSelector(state => state.mi15922015Reducer.isMarkSi)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)
  const nextVerificationDate = useSelector(state => state.mi15922015Reducer.nextVerificationDate)
  const nextVerificationDateAutoGenerate = useSelector(
    state => state.mi15922015Reducer.nextVerificationDateAutoGenerate,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const comment = useSelector(state => state.mi15922015Reducer.comment)
  const file = useSelector(state => state.mi15922015Reducer.file)
  const doChecked = useSelector(state => state.mi15922015Reducer.doChecked)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isTotalAccepted = useSelector(state => state.mi15922015Reducer.isTotalAccepted)
  const indicationPass = useSelector(state => state.mi15922015Reducer.indicationsPass)
  const indications = useSelector(state => state.mi15922015Reducer.indications)
  const conditions = useSelector(state => state.mi15922015Reducer.conditions)

  const acceptanceChecks = getAcceptanceChecks(isMechanicalAccepted, isTightnessAccepted, dispatch)

  useNextVerificationDate({
    defaultData,
    meterType,
    dateVerification,
    mpiColdAdditionals,
    mpiHotAdditionals,
  })

  const capitalizeFirstLetter = input => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const changeName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerName', label: newValue }))
  }

  const changeNameType = event => {
    dispatch(changeProperty({ value: 'customerNameType', label: event.target.value }))
    dispatch(changeProperty({ value: 'customerName', label: event.target.value }))
  }

  const changeAddress = selectedOption => {
    dispatch(
      changeProperty({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const changeFullName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerFullName', label: newValue }))
  }

  const changePhone = param => {
    const formattedPhone = formatPhoneNumber(param)
    dispatch(changeProperty({ value: 'customerPhoneNumber', label: formattedPhone }))
  }

  const handleMarkPassport = event => {
    dispatch(
      changeProperty({
        value: 'isMarkPassport',
        label: event.target.checked,
      }),
    )
  }

  const handleMarkSi = event => {
    dispatch(
      changeProperty({
        value: 'isMarkSi',
        label: event.target.checked,
      }),
    )
  }

  const handleNextDateChange = date => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'nextVerificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const handleDoChecked = event => {
    dispatch(
      changeProperty({
        value: 'doChecked',
        label: event.target.checked,
      }),
    )
  }

  const handleAgreementChange = param => {
    dispatch(changeProperty({ value: 'agreementNumber', label: param }))
  }

  useEffect(() => {
    const totalAccepted =
      isMechanicalAccepted && isTightnessAccepted && !indicationPass.includes(false)

    dispatch(changeProperty({ value: 'isTotalAccepted', label: totalAccepted }))
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass, dispatch])

  const handleSave = async () => {
    try {
      setIsDisabledBtn(true)
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const data = getProtocolData({
        caseId,
        verificationType,
        pointId,
        customerName,
        customerAddress,
        customerFullName,
        customerPhoneNumber,
        isMechanicalAccepted,
        isTightnessAccepted,
        isMarkPassport,
        isMarkSi,
        isTotalAccepted,
        indications,
        conditions,
        meterId,
        valueMod,
        valueAnotherMod,
        valueAdditional,
        meterFactoryNumber,
        meterYear,
        is_custom_modification,
        diameter,
        meterLocation,
        meterType,
        q_min,
        q_t,
        q_max,
        q_actual,
        q_min_limit,
        q_max_limit,
        defaultData,
        dateVerification,
        nextVerificationDate,
        nextVerificationDateAutoGenerate,
        agreementNumber,
        comment,
        file,
        doChecked,
      })

      const request = {
        url: apiUrl,
        method: 'POST',
        payload: data,
        headers: headers,
      }

      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        if (!navigator.onLine) {
          navigator.serviceWorker.controller.postMessage({
            form_data: request,
          })
          navigator.serviceWorker.ready.then(function (registration) {
            return registration.sync.register('sendFormData')
          })
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(
            setMessage(
              'Протокол успешно создан. Данные будут отправлены на сервер, когда интернет появится',
            ),
          )
          navigate('/metriva/protocols')
        } else {
          await api.post(apiUrl, data, { headers })
          dispatch(setMessage('Протокол успешно создан'))
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(refreshProtocols())
          dispatch(resetCase())
          dispatch(setDefaultState())
          dispatch(resetMeasurementId())
          navigate('/metriva/protocols')

          dispatch(refreshConditions())
          dispatch(refreshPersonalMetrolog())
          dispatch(refreshReceiveds())
          dispatch(refreshTickets())
          dispatch(refreshIssueCase())
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const serverErrors = error.response.data.errors
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage('Ошибка валидации. Проверьте, что вы заполнили все поля!'))
        setIsDisabledBtn(false)
        dispatch(changeProperty({ value: 'errors', label: serverErrors || {} }))
      }
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setDefaultState())
    }
  }, [])

  return (
    <PageContainer>
      <React.Fragment>
        <BoxContainer margin={'14px 0px 14px'}>
          <Customer
            valueName={customerName}
            changeName={changeName}
            valueNameType={customerNameType}
            changeNameType={changeNameType}
            valueAddress={customerAddress}
            changeAddress={changeAddress}
            valueFullName={customerFullName}
            changeFullName={changeFullName}
            valuePhone={customerPhoneNumber}
            changePhone={changePhone}
            changeAgreement={handleAgreementChange}
          />
        </BoxContainer>

        <BoxContainer>
          <MeterData
            meterFactoryNumber={meterFactoryNumber}
            meterYear={meterYear}
            meterId={meterId}
            modifications={modifications}
            selectedMod={selectedMod}
            selectedAdditionals={selectedAdditionals}
            valueMod={valueMod}
            valueAdditional={valueAdditional}
            valueAnotherMod={valueAnotherMod}
            is_custom_modification={is_custom_modification}
            changeMeter={changeProperty}
            defaultData={defaultData}
          />
        </BoxContainer>

        <BoxContainer>
          <DescriptionType />
        </BoxContainer>

        {acceptanceChecks.map((check, index) => {
          return (
            <BoxContainer key={index}>
              <Accepted
                title={check.title}
                description={check.description}
                label={check.label}
                value={check.value}
                onChange={check.onChange}
                notMatch={true}
              />
            </BoxContainer>
          )
        })}

        <BoxContainer>
          <Conditions />
        </BoxContainer>

        <BoxContainer>{pointId === 3 ? <OpticalSensor /> : <Indications />}</BoxContainer>

        <BoxContainer
          style={{
            backgroundColor: 'transparent',
            padding: '0px',
            border: 'none',
          }}
        >
          <TotalAccepted value={isTotalAccepted} />
        </BoxContainer>

        {isTotalAccepted && (
          <BoxContainer>
            <VerificationMark
              isTotalAccepted={isTotalAccepted}
              valuePassport={isMarkPassport}
              onChangePassport={handleMarkPassport}
              valueSi={isMarkSi}
              onChangeSi={handleMarkSi}
            />
          </BoxContainer>
        )}

        <BoxContainer>
          <ProtocolDate
            currentDate={dateVerification}
            nextDate={nextVerificationDate}
            nextDateAutoGenerate={nextVerificationDateAutoGenerate}
            changeNextDate={handleNextDateChange}
          />
        </BoxContainer>

        {!isTrusted ? (
          <BoxContainer margin={'0px 0px 14px'}>
            <ImageUpload />
          </BoxContainer>
        ) : (
          <BoxContainer>
            <DataTransfer doChecked={doChecked} handleDoChecked={handleDoChecked} />
          </BoxContainer>
        )}
      </React.Fragment>
      <Button
        variant='contained'
        onClick={handleSave}
        disabled={isDisabledBtn}
        style={{
          textTransform: 'none',
          fontSize: '15px',
          fontFamily: '"Wix Madefor Display", sans-serif',
          padding: '11px 20px 11px',
          margin: 0,
          borderRadius: '12px',
          width: '100%',
        }}
      >
        Сохранить протокол
      </Button>
    </PageContainer>
  )
}

export default Add
