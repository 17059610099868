// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Feedback from '../../LocalElements/Feedback/Feedback'
import { setShow } from '../../../../store/slices/app/comps/feedback'
import R from '../../../../services/app/client-server/request.service.js'
import { changeIssueCase, resetIssueCase } from '../../../../store/slices/app/views/journals'
import { refreshIssueCase } from '../../../../store/slices/app/controlers/updater'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import dayjs from 'dayjs'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index.tsx'
import { Typography } from '@mui/material'
import { Wrapper, Content, ContentFrame, LabelFrame } from '../style'
import BackButton from '../../../../ui/back-button/index.tsx'

import usePrompt from '../../../hooks/usePrompt.ts'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [getPrompt, setGetPrompt] = useState(true)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const [isAutogenerated, setIsAutogenerated] = useState('')
  const measurement = useSelector(state => state.journalReducer.issueCase.measurement)
  const caseNum = useSelector(state => state.journalReducer.issueCase.caseNum)
  const issuedByName = useSelector(state => state.journalReducer.issueCase.issuedName)
  const receivedByName = useSelector(state => state.journalReducer.issueCase.receivedName)
  const isReturned = useSelector(state => state.journalReducer.issueCase.returned)
  const issueDate = useSelector(state => state.journalReducer.issueCase.issueDate)
  const receivedDate = useSelector(state => state.journalReducer.issueCase.receivedDate)

  usePrompt(
    'Вы уверены, что хотите продолжить действие? Все введенные или измененные данные не будут сохранены',
    getPrompt,
  )

  const changeMeasurement = param => {
    dispatch(changeIssueCase({ value: 'measurement', label: param }))
  }

  const handleCaseNumChange = param => {
    dispatch(changeIssueCase({ value: 'caseNum', label: param }))
  }

  const handleIssuedByNameChange = param => {
    dispatch(changeIssueCase({ value: 'issuedName', label: param }))
  }

  const handleReceivedNameChange = param => {
    dispatch(changeIssueCase({ value: 'receivedName', label: param }))
  }

  const handleIssuedDateChange = date => {
    if (date) {
      dispatch(changeIssueCase({ value: 'issueDate', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeIssueCase({ value: 'issueDate', label: '' }))
    }
  }

  const handleReceivedDateChange = date => {
    if (date) {
      dispatch(changeIssueCase({ value: 'receivedDate', label: date.format('YYYY-MM-DD') }))
    } else {
      dispatch(changeIssueCase({ value: 'receivedDate', label: '' }))
    }
  }

  const changeIsReturnedChecked = event => {
    const value = event.target.value === 'true'
    dispatch(changeIssueCase({ value: 'returned', label: value }))
    dispatch(
      changeIssueCase({ value: 'receivedDate', label: dayjs().format('YYYY-MM-DD HH:mm:ss') }),
    )
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      const { data: IssueCase } = await R.getIssueCaseItem(journal_id, companyId)
      const data = IssueCase.data
      setIsAutogenerated(data?.is_autogenerated)

      dispatch(changeIssueCase({ value: 'measurement', label: data?.measurement_id }))
      dispatch(changeIssueCase({ value: 'caseNum', label: data?.inner_name }))
      dispatch(changeIssueCase({ value: 'issuedName', label: data?.issued_by_name }))
      dispatch(changeIssueCase({ value: 'receivedName', label: data?.received_by_name }))
      dispatch(changeIssueCase({ value: 'returned', label: data?.is_returned }))
      dispatch(changeIssueCase({ value: 'issueDate', label: data?.issued_date }))
      dispatch(changeIssueCase({ value: 'receivedDate', label: data?.received_date }))
      dispatch(setShow(false))
    })()
  }, [journal_id, companyId])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setGetPrompt(false)

    const data = isAutogenerated
      ? {
          is_returned: isReturned,
          received_date: receivedDate,
        }
      : {
          measurement_id: measurement,
          inner_name: caseNum,
          issued_date: issueDate,
          issued_by_name: issuedByName,
          received_by_name: receivedByName,
          received_date: receivedDate,
          is_returned: isReturned,
        }

    const { status } = await R.editIssueCase(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshIssueCase())
      dispatch(resetIssueCase())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetIssueCase())
    }
  }, [])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <BackButton to='/metriva/journals' label='Все записи документа' />

          <Typography variant='h2'>Журнал выдачи средств поверки</Typography>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Информация о журнале
            </Typography>

            {!isAutogenerated && (
              <ContentFrame style={{ marginBottom: '4px' }}>
                <LabelFrame sx={{ width: '50%' }}>
                  <Measurement
                    measurementId={measurement}
                    setMeasurementId={changeMeasurement}
                    label={{
                      text: 'Область измерения',
                      topBg: 'white',
                      bottomBg: 'white',
                    }}
                  />
                </LabelFrame>

                <LabelFrame sx={{ width: '50%' }}>
                  <Input
                    label={'№ комплекта СП'}
                    type={'text'}
                    value={caseNum}
                    actions={{
                      change: handleCaseNumChange,
                    }}
                  />
                </LabelFrame>
              </ContentFrame>
            )}

            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '50%' }}>
                  <Input
                    label={'Выдавший (ФИО и должность)'}
                    placeholder={'Введите ФИО выдавшего комплект'}
                    type={'text'}
                    value={issuedByName}
                    actions={{
                      change: handleIssuedByNameChange,
                    }}
                  />
                </LabelFrame>

                <LabelFrame sx={{ width: '50%' }}>
                  <Input
                    label={'Принявший (ФИО и должность)'}
                    placeholder={'Введите ФИО принявшего комплект'}
                    type={'text'}
                    value={receivedByName}
                    actions={{
                      change: handleReceivedNameChange,
                    }}
                  />
                </LabelFrame>
              </ContentFrame>
            )}

            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '50%' }}>
                  <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                    Дата выдачи комплекта
                    {!!issueDate ? (
                      <CheckCircleIcon
                        sx={{
                          color: '#63E6BE',
                          marginLeft: '8px',
                          marginTop: '0px',
                        }}
                      />
                    ) : (
                      <VisibilityToggleIcon
                        sx={{
                          color: '#FFD43B',
                          marginLeft: '8px',
                          marginTop: '0px',
                        }}
                      />
                    )}
                  </Typography>
                  <DatePicker selectedDate={issueDate} dateChange={handleIssuedDateChange} />
                </LabelFrame>
                <LabelFrame sx={{ width: '50%' }}>
                  <Typography variant='subtitle2'>Отметка о возврате</Typography>
                  <RadioButton
                    value={isReturned}
                    onChange={changeIsReturnedChecked}
                    trueLabel={'Присутствует'}
                    falseLabel={'Отсутствует'}
                    trueValue={true}
                    falseValue={false}
                  />
                </LabelFrame>
              </ContentFrame>
            )}

            {isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '50%' }}>
                  <Typography variant='subtitle2'>Отметка о возврате</Typography>
                  <RadioButton
                    value={isReturned}
                    onChange={changeIsReturnedChecked}
                    trueLabel={'Присутствует'}
                    falseLabel={'Отсутствует'}
                    trueValue={true}
                    falseValue={false}
                  />
                </LabelFrame>
              </ContentFrame>
            )}

            <ContentFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                  Дата возврата комплекта
                  {isReturned ? (
                    !!receivedDate ? (
                      <CheckCircleIcon
                        sx={{
                          color: '#63E6BE',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    ) : (
                      <VisibilityToggleIcon
                        sx={{
                          color: '#FFD43B',
                          marginLeft: '8px',
                          marginTop: '-0px',
                        }}
                      />
                    )
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </Typography>
                <DatePicker
                  selectedDate={receivedDate}
                  dateChange={handleReceivedDateChange}
                  minDate={issueDate ? dayjs(issueDate) : null}
                />
              </LabelFrame>
              <LabelFrame sx={{ width: '50%' }}></LabelFrame>
            </ContentFrame>
          </Content>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
