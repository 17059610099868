import React from 'react'
import { Tooltip as MuiTooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTooltip = styled(
  ({
    className,
    placement,
    ...props
  }: any & {
    placement: any
  }) => <MuiTooltip {...props} classes={{ popper: className }} />,
)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    borderRadius: '12px 12px  0px 12px',
    backgroundColor: theme.palette.text.primary,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.20)',
    margin: '10px 16px !important',
    padding: '10px 16px',
    fontWeight: 400,
    fontSize: '13px',
  },
}))

const Tooltip = ({
  title,
  placement = 'top-end',
  children,
  disableHoverListener,
  ...tooltipProps
}) => {
  return (
    <StyledTooltip
      title={title}
      placement={placement}
      {...tooltipProps}
      disableHoverListener={disableHoverListener}
    >
      <span>{children}</span>
    </StyledTooltip>
  )
}

export default Tooltip
