// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { toggleEnableStep } from '../../../../../store/slices/app/controlers/stepper.js'

const ProtocolDate = props => {
  const { currentDate, nextDate, nextDateAutoGenerate, changeNextDate } = props
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  const enableNextStep = useMemo(() => {
    if (currentDate && (nextDate || nextDateAutoGenerate)) return true
    else return false
  }, [currentDate, nextDate, nextDateAutoGenerate])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  return (
    <React.Fragment>
      <Typography variant='h2' style={{ marginBottom: '9px' }}>
        Поверка проведена
      </Typography>

      <div className='flexContainerWithGap' style={width <= 1050 ? { marginBottom: '0px' } : {}}>
        <div className='width32Percent'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography sx={{ fontSize: '15px' }} mb={1}>
              Дата следующей поверки
            </Typography>
            <div>
              {nextDateAutoGenerate || nextDate ? (
                <CheckCircleIcon
                  sx={{
                    color: '#63E6BE',
                  }}
                />
              ) : (
                <VisibilityToggleIcon
                  sx={{
                    color: '#FFD43B',
                  }}
                />
              )}
            </div>
          </div>

          <DatePicker
            selectedDate={nextDateAutoGenerate || nextDate}
            dateChange={changeNextDate}
            isDisabled={!!nextDateAutoGenerate}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProtocolDate
