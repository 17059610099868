import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Meters from '../app/pages/Meters/Meters'
import MeterShow from '../app/pages/Meters/MeterShow.tsx'

const RouteMeters: React.FC = (): ReactElement => {
  return (
    <Routes>
      <Route index element={<Meters />} />
      <Route path=':meters_id' element={<MeterShow />} />
    </Routes>
  )
}

export default RouteMeters
