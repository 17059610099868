export const sanitizeInput = (value, allowNegative = false) => {
  let sanitizedValue = value.replace(',', '.')

  sanitizedValue = allowNegative
    ? sanitizedValue.replace(/[^0-9.-]/g, '')
    : sanitizedValue.replace(/[^0-9.]/g, '')

  if (allowNegative && sanitizedValue.includes('-')) {
    sanitizedValue = '-' + sanitizedValue.replace(/-/g, '')
  }

  const parts = sanitizedValue.split('.')
  if (parts.length > 2) {
    sanitizedValue = parts[0] + '.' + parts.slice(1).join('')
  }

  return sanitizedValue
}
