// -------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// -------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Collapse } from '@mui/material'

import Input from '../../../../LocalElements/Input/InputMUI'

import { sanitizeInput } from '../../../../../../utils/sanitizeInput'

import {
  calculateQt,
  calculateDifference,
  calculatePermissibleError,
  checkWithinLimits,
  calculateAverageError,
  getMeasurementData,
} from '../helpers/helpers.js'

import { MeasurementInputs } from '../ProtocolComponents/indicationComponent/MeasurementInputs'
import { MeasurementResults } from '../ProtocolComponents/indicationComponent/MeasurementResults'
import { AverageValue } from '../ProtocolComponents/indicationComponent/AverageValue'
import { ExtendedTariffBtn } from '../ProtocolComponents/indicationComponent/TariffBtn'

import { changeProperty } from '../state'

const Indications = () => {
  const dispatch = useDispatch()

  const correction = useSelector(state => state.gost83242002Reducer.correction)
  const pressureLoss = useSelector(state => state.gost83242002Reducer.pressureLoss)

  const q_min = useSelector(state => state.gost83242002Reducer.q_min)
  const q_t = useSelector(state => state.gost83242002Reducer.q_t)
  const q_max = useSelector(state => state.gost83242002Reducer.q_max)
  const q_nom = useSelector(state => state.gost83242002Reducer.q_nom)
  const q_t_formula = useSelector(state => state.gost83242002Reducer.q_t_formula)
  const q_max_limit_negative = useSelector(state => state.gost83242002Reducer.q_max_limit_negative)
  const q_max_limit_positive = useSelector(state => state.gost83242002Reducer.q_max_limit_positive)
  const q_min_limit_negative = useSelector(state => state.gost83242002Reducer.q_min_limit_negative)
  const q_min_limit_positive = useSelector(state => state.gost83242002Reducer.q_min_limit_positive)

  // -------------------------------------------------------------
  // Первая точка
  // -------------------------------------------------------------

  const start_1 = useSelector(state => state.gost83242002Reducer.start_1)
  const finish_1 = useSelector(state => state.gost83242002Reducer.finish_1)
  const volumeEtalon_1 = useSelector(state => state.gost83242002Reducer.volumeEtalon_1)
  const measurementConsumption_1 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_1,
  )
  const volumeAirMeter_1 = useSelector(state => state.gost83242002Reducer.volumeAirMeter_1)
  const permissibleError_1 = useSelector(state => state.gost83242002Reducer.permissibleError_1)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const start_extra_2 = useSelector(state => state.gost83242002Reducer.start_extra_2)
  const finish_extra_2 = useSelector(state => state.gost83242002Reducer.finish_extra_2)
  const volumeEtalon_extra_2 = useSelector(state => state.gost83242002Reducer.volumeEtalon_extra_2)
  const measurementConsumption_extra_2 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_extra_2,
  )
  const volumeAirMeter_extra_2 = useSelector(
    state => state.gost83242002Reducer.volumeAirMeter_extra_2,
  )
  const permissibleError_extra_2 = useSelector(
    state => state.gost83242002Reducer.permissibleError_extra_2,
  )
  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const start_extra_3 = useSelector(state => state.gost83242002Reducer.start_extra_3)
  const finish_extra_3 = useSelector(state => state.gost83242002Reducer.finish_extra_3)
  const volumeEtalon_extra_3 = useSelector(state => state.gost83242002Reducer.volumeEtalon_extra_3)
  const measurementConsumption_extra_3 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_extra_3,
  )
  const volumeAirMeter_extra_3 = useSelector(
    state => state.gost83242002Reducer.volumeAirMeter_extra_3,
  )
  const permissibleError_extra_3 = useSelector(
    state => state.gost83242002Reducer.permissibleError_extra_3,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const [averageErrorQMin, setPermissibleErrorTotalQMin] = useState('')

  const [isRelativeError1InRange, setIsRelativeError1InRange] = useState(false)
  const [isRelativeErrorExtra2InRange, setIsRelativeErrorExtra2InRange] = useState(false)
  const [isRelativeErrorExtra3InRange, setIsRelativeErrorExtra3InRange] = useState(false)
  const [isRelativeAverageErrorQMin, setIsRelativeAverageErrorQMin] = useState(false)

  // -------------------------------------------------------------
  // Вторая точка
  // -------------------------------------------------------------

  const start_2 = useSelector(state => state.gost83242002Reducer.start_2)
  const finish_2 = useSelector(state => state.gost83242002Reducer.finish_2)
  const volumeEtalon_2 = useSelector(state => state.gost83242002Reducer.volumeEtalon_2)
  const measurementConsumption_2 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_2,
  )
  const volumeAirMeter_2 = useSelector(state => state.gost83242002Reducer.volumeAirMeter_2)
  const permissibleError_2 = useSelector(state => state.gost83242002Reducer.permissibleError_2)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const start_extra_4 = useSelector(state => state.gost83242002Reducer.start_extra_4)
  const finish_extra_4 = useSelector(state => state.gost83242002Reducer.finish_extra_4)
  const volumeEtalon_extra_4 = useSelector(state => state.gost83242002Reducer.volumeEtalon_extra_4)
  const measurementConsumption_extra_4 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_extra_4,
  )
  const volumeAirMeter_extra_4 = useSelector(
    state => state.gost83242002Reducer.volumeAirMeter_extra_4,
  )
  const permissibleError_extra_4 = useSelector(
    state => state.gost83242002Reducer.permissibleError_extra_4,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const start_extra_5 = useSelector(state => state.gost83242002Reducer.start_extra_5)
  const finish_extra_5 = useSelector(state => state.gost83242002Reducer.finish_extra_5)
  const volumeEtalon_extra_5 = useSelector(state => state.gost83242002Reducer.volumeEtalon_extra_5)
  const measurementConsumption_extra_5 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_extra_5,
  )
  const volumeAirMeter_extra_5 = useSelector(
    state => state.gost83242002Reducer.volumeAirMeter_extra_5,
  )
  const permissibleError_extra_5 = useSelector(
    state => state.gost83242002Reducer.permissibleError_extra_5,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const [averageErrorQT, setPermissibleErrorTotalQT] = useState('')

  const [isRelativeError2InRange, setIsRelativeError2InRange] = useState(false)
  const [isRelativeErrorExtra4InRange, setIsRelativeErrorExtra4InRange] = useState(false)
  const [isRelativeErrorExtra5InRange, setIsRelativeErrorExtra5InRange] = useState(false)
  const [isRelativeAverageErrorQT, setIsRelativeAverageErrorQT] = useState(false)

  // -------------------------------------------------------------
  // Третья точка
  // -------------------------------------------------------------

  const start_3 = useSelector(state => state.gost83242002Reducer.start_3)
  const finish_3 = useSelector(state => state.gost83242002Reducer.finish_3)
  const volumeEtalon_3 = useSelector(state => state.gost83242002Reducer.volumeEtalon_3)
  const measurementConsumption_3 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_3,
  )
  const volumeAirMeter_3 = useSelector(state => state.gost83242002Reducer.volumeAirMeter_3)
  const permissibleError_3 = useSelector(state => state.gost83242002Reducer.permissibleError_3)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const start_extra_6 = useSelector(state => state.gost83242002Reducer.start_extra_6)
  const finish_extra_6 = useSelector(state => state.gost83242002Reducer.finish_extra_6)
  const volumeEtalon_extra_6 = useSelector(state => state.gost83242002Reducer.volumeEtalon_extra_6)
  const measurementConsumption_extra_6 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_extra_6,
  )
  const volumeAirMeter_extra_6 = useSelector(
    state => state.gost83242002Reducer.volumeAirMeter_extra_6,
  )
  const permissibleError_extra_6 = useSelector(
    state => state.gost83242002Reducer.permissibleError_extra_6,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const start_extra_7 = useSelector(state => state.gost83242002Reducer.start_extra_7)
  const finish_extra_7 = useSelector(state => state.gost83242002Reducer.finish_extra_7)
  const volumeEtalon_extra_7 = useSelector(state => state.gost83242002Reducer.volumeEtalon_extra_7)
  const measurementConsumption_extra_7 = useSelector(
    state => state.gost83242002Reducer.measurementConsumption_extra_7,
  )
  const volumeAirMeter_extra_7 = useSelector(
    state => state.gost83242002Reducer.volumeAirMeter_extra_7,
  )
  const permissibleError_extra_7 = useSelector(
    state => state.gost83242002Reducer.permissibleError_extra_7,
  )

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const [averageErrorQMax, setPermissibleErrorTotalQMax] = useState('')

  const [isRelativeError3InRange, setIsRelativeError3InRange] = useState(false)
  const [isRelativeErrorExtra6InRange, setIsRelativeErrorExtra6InRange] = useState(false)
  const [isRelativeErrorExtra7InRange, setIsRelativeErrorExtra7InRange] = useState(false)
  const [isRelativeAverageErrorQMax, setIsRelativeAverageErrorQMax] = useState(false)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  useEffect(() => {
    const calculatedQt = calculateQt(q_t_formula, q_min, q_max, q_nom)
    dispatch(changeProperty({ value: 'q_t', label: calculatedQt }))
  }, [q_t_formula, q_min, q_max, q_nom, dispatch])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_1',
        label: calculateDifference(finish_1, start_1).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_2',
        label: calculateDifference(finish_2, start_2).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_3',
        label: calculateDifference(finish_3, start_3).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_extra_2',
        label: calculateDifference(finish_extra_2, start_extra_2).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_extra_3',
        label: calculateDifference(finish_extra_3, start_extra_3).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_extra_4',
        label: calculateDifference(finish_extra_4, start_extra_4).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_extra_5',
        label: calculateDifference(finish_extra_5, start_extra_5).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_extra_6',
        label: calculateDifference(finish_extra_6, start_extra_6).difference,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_extra_7',
        label: calculateDifference(finish_extra_7, start_extra_7).difference,
      }),
    )
  }, [
    finish_1,
    start_1,
    finish_2,
    start_2,
    finish_3,
    start_3,
    finish_extra_2,
    start_extra_2,
    finish_extra_3,
    start_extra_3,
    finish_extra_4,
    start_extra_4,
    finish_extra_5,
    start_extra_5,
    finish_extra_6,
    start_extra_6,
    finish_extra_7,
    start_extra_7,
  ])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'permissibleError_1',
        label: calculatePermissibleError(volumeAirMeter_1, volumeEtalon_1, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_2',
        label: calculatePermissibleError(volumeAirMeter_2, volumeEtalon_2, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_3',
        label: calculatePermissibleError(volumeAirMeter_3, volumeEtalon_3, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_extra_2',
        label: calculatePermissibleError(volumeAirMeter_extra_2, volumeEtalon_extra_2, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_extra_3',
        label: calculatePermissibleError(volumeAirMeter_extra_3, volumeEtalon_extra_3, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_extra_4',
        label: calculatePermissibleError(volumeAirMeter_extra_4, volumeEtalon_extra_4, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_extra_5',
        label: calculatePermissibleError(volumeAirMeter_extra_5, volumeEtalon_extra_5, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_extra_6',
        label: calculatePermissibleError(volumeAirMeter_extra_6, volumeEtalon_extra_6, correction),
      }),
    )

    dispatch(
      changeProperty({
        value: 'permissibleError_extra_7',
        label: calculatePermissibleError(volumeAirMeter_extra_7, volumeEtalon_extra_7, correction),
      }),
    )
  }, [
    volumeAirMeter_1,
    volumeEtalon_1,
    volumeAirMeter_2,
    volumeEtalon_2,
    volumeAirMeter_3,
    volumeEtalon_3,
    correction,
    volumeAirMeter_extra_2,
    volumeEtalon_extra_2,
    volumeAirMeter_extra_3,
    volumeEtalon_extra_3,
    volumeAirMeter_extra_4,
    volumeEtalon_extra_4,
    volumeAirMeter_extra_5,
    volumeEtalon_extra_5,
    volumeAirMeter_extra_6,
    volumeEtalon_extra_6,
    volumeAirMeter_extra_7,
    volumeEtalon_extra_7,
  ])

  useEffect(() => {
    setIsRelativeError1InRange(
      checkWithinLimits(permissibleError_1, q_min_limit_negative, q_min_limit_positive),
    )
    setIsRelativeErrorExtra2InRange(
      checkWithinLimits(permissibleError_extra_2, q_min_limit_negative, q_min_limit_positive),
    )
    setIsRelativeErrorExtra3InRange(
      checkWithinLimits(permissibleError_extra_3, q_min_limit_negative, q_min_limit_positive),
    )
    setIsRelativeAverageErrorQMin(
      checkWithinLimits(averageErrorQMin, q_min_limit_negative, q_min_limit_positive),
    )
    setIsRelativeError2InRange(
      checkWithinLimits(permissibleError_2, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeErrorExtra4InRange(
      checkWithinLimits(permissibleError_extra_4, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeErrorExtra5InRange(
      checkWithinLimits(permissibleError_extra_5, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeAverageErrorQT(
      checkWithinLimits(averageErrorQT, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeError3InRange(
      checkWithinLimits(permissibleError_3, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeErrorExtra6InRange(
      checkWithinLimits(permissibleError_extra_6, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeErrorExtra7InRange(
      checkWithinLimits(permissibleError_extra_7, q_max_limit_negative, q_max_limit_positive),
    )
    setIsRelativeAverageErrorQMax(
      checkWithinLimits(averageErrorQMax, q_max_limit_negative, q_max_limit_positive),
    )
  }, [
    permissibleError_1,
    permissibleError_extra_2,
    permissibleError_extra_3,
    averageErrorQMin,
    permissibleError_2,
    permissibleError_extra_4,
    permissibleError_extra_5,
    averageErrorQT,
    permissibleError_3,
    permissibleError_extra_6,
    permissibleError_extra_7,
    averageErrorQMax,
    q_min_limit_negative,
    q_min_limit_positive,
    q_max_limit_negative,
    q_max_limit_positive,
  ])

  useEffect(() => {
    setPermissibleErrorTotalQMin(
      calculateAverageError(permissibleError_1, permissibleError_extra_2, permissibleError_extra_3),
    )
    setPermissibleErrorTotalQT(
      calculateAverageError(permissibleError_2, permissibleError_extra_4, permissibleError_extra_5),
    )
    setPermissibleErrorTotalQMax(
      calculateAverageError(permissibleError_3, permissibleError_extra_6, permissibleError_extra_7),
    )
  }, [
    permissibleError_1,
    permissibleError_extra_2,
    permissibleError_extra_3,
    permissibleError_2,
    permissibleError_extra_4,
    permissibleError_extra_5,
    permissibleError_3,
    permissibleError_extra_6,
    permissibleError_extra_7,
  ])

  useEffect(() => {
    const error1 = !!averageErrorQMin ? isRelativeAverageErrorQMin : isRelativeError1InRange
    const error2 = !!averageErrorQT ? isRelativeAverageErrorQT : isRelativeError2InRange
    const error3 = !!averageErrorQMax ? isRelativeAverageErrorQMax : isRelativeError3InRange

    const newIndicationPass = error1 && error2 && error3

    dispatch(changeProperty({ value: 'indicationPass', label: newIndicationPass }))
  }, [
    isRelativeError1InRange,
    isRelativeError2InRange,
    isRelativeError3InRange,
    isRelativeAverageErrorQMin,
    isRelativeAverageErrorQT,
    isRelativeAverageErrorQMax,
    averageErrorQMin,
    averageErrorQT,
    averageErrorQMax,
    dispatch,
  ])

  useEffect(() => {
    if (isRelativeError1InRange) {
      dispatch(changeProperty({ value: 'start_extra_2', label: '' }))
      dispatch(changeProperty({ value: 'measurementConsumption_extra_2', label: '' }))
      dispatch(changeProperty({ value: 'finish_extra_2', label: '' }))
      dispatch(changeProperty({ value: 'volumeEtalon_extra_2', label: '' }))
      dispatch(changeProperty({ value: 'volumeAirMeter_extra_2', label: '' }))
      dispatch(changeProperty({ value: 'permissibleError_extra_2', label: '' }))

      dispatch(changeProperty({ value: 'start_extra_3', label: '' }))
      dispatch(changeProperty({ value: 'measurementConsumption_extra_3', label: '' }))
      dispatch(changeProperty({ value: 'finish_extra_3', label: '' }))
      dispatch(changeProperty({ value: 'volumeEtalon_extra_3', label: '' }))
      dispatch(changeProperty({ value: 'volumeAirMeter_extra_3', label: '' }))
      dispatch(changeProperty({ value: 'permissibleError_extra_3', label: '' }))
    }
  }, [isRelativeError1InRange])

  useEffect(() => {
    if (isRelativeError2InRange) {
      dispatch(changeProperty({ value: 'start_extra_4', label: '' }))
      dispatch(changeProperty({ value: 'measurementConsumption_extra_4', label: '' }))
      dispatch(changeProperty({ value: 'finish_extra_4', label: '' }))
      dispatch(changeProperty({ value: 'volumeEtalon_extra_4', label: '' }))
      dispatch(changeProperty({ value: 'volumeAirMeter_extra_4', label: '' }))
      dispatch(changeProperty({ value: 'permissibleError_extra_4', label: '' }))

      dispatch(changeProperty({ value: 'start_extra_5', label: '' }))
      dispatch(changeProperty({ value: 'measurementConsumption_extra_5', label: '' }))
      dispatch(changeProperty({ value: 'finish_extra_5', label: '' }))
      dispatch(changeProperty({ value: 'volumeEtalon_extra_5', label: '' }))
      dispatch(changeProperty({ value: 'volumeAirMeter_extra_5', label: '' }))
      dispatch(changeProperty({ value: 'permissibleError_extra_5', label: '' }))
    }
  }, [isRelativeError2InRange])

  useEffect(() => {
    if (isRelativeError3InRange) {
      dispatch(changeProperty({ value: 'start_extra_6', label: '' }))
      dispatch(changeProperty({ value: 'measurementConsumption_extra_6', label: '' }))
      dispatch(changeProperty({ value: 'finish_extra_6', label: '' }))
      dispatch(changeProperty({ value: 'volumeEtalon_extra_6', label: '' }))
      dispatch(changeProperty({ value: 'volumeAirMeter_extra_6', label: '' }))
      dispatch(changeProperty({ value: 'permissibleError_extra_6', label: '' }))

      dispatch(changeProperty({ value: 'start_extra_7', label: '' }))
      dispatch(changeProperty({ value: 'measurementConsumption_extra_7', label: '' }))
      dispatch(changeProperty({ value: 'finish_extra_7', label: '' }))
      dispatch(changeProperty({ value: 'volumeEtalon_extra_7', label: '' }))
      dispatch(changeProperty({ value: 'volumeAirMeter_extra_7', label: '' }))
      dispatch(changeProperty({ value: 'permissibleError_extra_7', label: '' }))
    }
  }, [isRelativeError3InRange])

  useEffect(() => {
    const variables = {
      start_1,
      finish_1,
      volumeEtalon_1,
      volumeAirMeter_1,
      measurementConsumption_1,
      permissibleError_1,
      start_2,
      finish_2,
      volumeEtalon_2,
      volumeAirMeter_2,
      measurementConsumption_2,
      permissibleError_2,
      start_3,
      finish_3,
      volumeEtalon_3,
      volumeAirMeter_3,
      measurementConsumption_3,
      permissibleError_3,
      start_extra_2,
      finish_extra_2,
      volumeEtalon_extra_2,
      volumeAirMeter_extra_2,
      measurementConsumption_extra_2,
      permissibleError_extra_2,
      start_extra_3,
      finish_extra_3,
      volumeEtalon_extra_3,
      volumeAirMeter_extra_3,
      measurementConsumption_extra_3,
      permissibleError_extra_3,
      start_extra_4,
      finish_extra_4,
      volumeEtalon_extra_4,
      volumeAirMeter_extra_4,
      measurementConsumption_extra_4,
      permissibleError_extra_4,
      start_extra_5,
      finish_extra_5,
      volumeEtalon_extra_5,
      volumeAirMeter_extra_5,
      measurementConsumption_extra_5,
      permissibleError_extra_5,
      start_extra_6,
      finish_extra_6,
      volumeEtalon_extra_6,
      volumeAirMeter_extra_6,
      measurementConsumption_extra_6,
      permissibleError_extra_6,
      start_extra_7,
      finish_extra_7,
      volumeEtalon_extra_7,
      volumeAirMeter_extra_7,
      measurementConsumption_extra_7,
      permissibleError_extra_7,
      averageErrorQMin,
      averageErrorQT,
      averageErrorQMax,
    }

    const measurementData = getMeasurementData(variables)

    if (measurementData.length > 0) {
      dispatch(
        changeProperty({
          value: 'indications',
          label: measurementData,
        }),
      )
    }
  }, [
    start_1,
    finish_1,
    volumeEtalon_1,
    volumeAirMeter_1,
    measurementConsumption_1,
    permissibleError_1,
    start_2,
    finish_2,
    volumeEtalon_2,
    volumeAirMeter_2,
    measurementConsumption_2,
    permissibleError_2,
    start_3,
    finish_3,
    volumeEtalon_3,
    volumeAirMeter_3,
    measurementConsumption_3,
    permissibleError_3,
    averageErrorQMin,
    averageErrorQT,
    averageErrorQMax,
    dispatch,
  ])

  const getIndexForFirstBlock = (isRelativeError1InRange, isRelativeError2InRange) => {
    return isRelativeError1InRange
      ? isRelativeError2InRange
        ? 3
        : 5
      : isRelativeError2InRange
        ? 5
        : 7
  }

  const getIndexForSecondBlock = (
    isRelativeError1InRange,
    isRelativeError2InRange,
    isRelativeError3InRange,
  ) => {
    if (isRelativeError1InRange && isRelativeError2InRange && !isRelativeError3InRange) return 4
    if (!isRelativeError1InRange && isRelativeError2InRange && !isRelativeError3InRange) return 6
    if (isRelativeError1InRange && !isRelativeError2InRange && !isRelativeError3InRange) return 6
    return 8
  }

  const getIndexForThirdBlock = (
    isRelativeError1InRange,
    isRelativeError2InRange,
    isRelativeError3InRange,
  ) => {
    if (isRelativeError1InRange && isRelativeError2InRange && !isRelativeError3InRange) return 5
    if (!isRelativeError1InRange && isRelativeError2InRange && !isRelativeError3InRange) return 7
    if (isRelativeError1InRange && !isRelativeError2InRange && !isRelativeError3InRange) return 7
    return 9
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant='h2'>Определение относительной погрешности</Typography>
        <ExtendedTariffBtn />
      </div>

      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Input
            label={'Поправка, %'}
            placeholder={'0.00'}
            value={correction}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'correction', label: sanitizeInput(value) })),
            }}
          />
        </div>

        <div className='width50Percent'>
          <Input
            label={'Потеря давления при Qmax, Па'}
            placeholder={'0.00'}
            value={pressureLoss}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'pressureLoss', label: sanitizeInput(value) })),
            }}
          />
        </div>
      </div>

      <Typography variant='subtitle1'>
        Значение Qmin:
        <span style={{ color: '#0084E2', marginLeft: '4px' }}>{q_min || '0.00'} м³/ч</span>
      </Typography>

      <MeasurementInputs
        index={1}
        start={start_1}
        measurementConsumption={measurementConsumption_1}
        finish={finish_1}
        volumeEtalon={volumeEtalon_1}
        fieldStart='start_1'
        fieldMeasurementConsumption='measurementConsumption_1'
        fieldFinish='finish_1'
        fieldVolumeEtalon='volumeEtalon_1'
      />

      <Collapse in={!!start_1 && !!finish_1 && !!volumeEtalon_1} timeout={500} unmountOnExit>
        <MeasurementResults
          volumeAirMeter={volumeAirMeter_1}
          permissibleError={permissibleError_1}
          isRelativeErrorInRange={isRelativeError1InRange}
          q_min_limit_negative={q_min_limit_negative}
          q_min_limit_positive={q_min_limit_positive}
          visibleText={isRelativeError1InRange}
        />
      </Collapse>

      <Collapse in={!isRelativeError1InRange} timeout={500} unmountOnExit>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <MeasurementInputs
            index={2}
            start={start_extra_2}
            measurementConsumption={measurementConsumption_extra_2}
            finish={finish_extra_2}
            volumeEtalon={volumeEtalon_extra_2}
            fieldStart='start_extra_2'
            fieldMeasurementConsumption='measurementConsumption_extra_2'
            fieldFinish='finish_extra_2'
            fieldVolumeEtalon='volumeEtalon_extra_2'
          />

          <Collapse
            in={!!start_extra_2 && !!finish_extra_2 && !!volumeEtalon_extra_2}
            timeout={500}
            unmountOnExit
          >
            <MeasurementResults
              volumeAirMeter={volumeAirMeter_extra_2}
              permissibleError={permissibleError_extra_2}
              isRelativeErrorInRange={isRelativeErrorExtra2InRange}
              q_min_limit_negative={q_min_limit_negative}
              q_min_limit_positive={q_min_limit_positive}
              visibleText={isRelativeError1InRange}
            />
          </Collapse>

          <MeasurementInputs
            index={3}
            start={start_extra_3}
            measurementConsumption={measurementConsumption_extra_3}
            finish={finish_extra_3}
            volumeEtalon={volumeEtalon_extra_3}
            fieldStart='start_extra_3'
            fieldMeasurementConsumption='measurementConsumption_extra_3'
            fieldFinish='finish_extra_3'
            fieldVolumeEtalon='volumeEtalon_extra_3'
          />

          <Collapse
            in={!!start_extra_3 && !!finish_extra_3 && !!volumeEtalon_extra_3}
            timeout={500}
            unmountOnExit
          >
            <MeasurementResults
              volumeAirMeter={volumeAirMeter_extra_3}
              permissibleError={permissibleError_extra_3}
              isRelativeErrorInRange={isRelativeErrorExtra3InRange}
              q_min_limit_negative={q_min_limit_negative}
              q_min_limit_positive={q_min_limit_positive}
              visibleText={isRelativeError1InRange}
            />
          </Collapse>
        </div>
      </Collapse>

      <Collapse in={!!averageErrorQMin} timeout={500} unmountOnExit>
        <AverageValue
          averageError={averageErrorQMin}
          isRelativeErrorInRange={isRelativeAverageErrorQMin}
          q_limit_negative={q_min_limit_negative}
          q_limit_positive={q_min_limit_positive}
        />
      </Collapse>

      <Typography variant='subtitle1'>
        Значение Qt:
        <span style={{ color: '#0084E2', marginLeft: '4px' }}>
          {q_t_formula} = {q_t} м³/ч
        </span>
      </Typography>

      <MeasurementInputs
        index={isRelativeError1InRange ? 2 : 4}
        start={start_2}
        measurementConsumption={measurementConsumption_2}
        finish={finish_2}
        volumeEtalon={volumeEtalon_2}
        fieldStart='start_2'
        fieldMeasurementConsumption='measurementConsumption_2'
        fieldFinish='finish_2'
        fieldVolumeEtalon='volumeEtalon_2'
      />

      <Collapse in={!!start_2 && !!finish_2 && !!volumeEtalon_2} timeout={500} unmountOnExit>
        <MeasurementResults
          volumeAirMeter={volumeAirMeter_2}
          permissibleError={permissibleError_2}
          isRelativeErrorInRange={isRelativeError2InRange}
          q_min_limit_negative={q_max_limit_negative}
          q_min_limit_positive={q_max_limit_negative}
          visibleText={isRelativeError2InRange}
        />
      </Collapse>

      <Collapse in={!isRelativeError2InRange} timeout={500} unmountOnExit>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <MeasurementInputs
            index={isRelativeError1InRange && !isRelativeError2InRange ? 3 : 5}
            start={start_extra_4}
            measurementConsumption={measurementConsumption_extra_4}
            finish={finish_extra_4}
            volumeEtalon={volumeEtalon_extra_4}
            fieldStart='start_extra_4'
            fieldMeasurementConsumption='measurementConsumption_extra_4'
            fieldFinish='finish_extra_4'
            fieldVolumeEtalon='volumeEtalon_extra_4'
          />

          <Collapse
            in={!!start_extra_4 && !!finish_extra_4 && !!volumeEtalon_extra_4}
            timeout={500}
            unmountOnExit
          >
            <MeasurementResults
              volumeAirMeter={volumeAirMeter_extra_4}
              permissibleError={permissibleError_extra_4}
              isRelativeErrorInRange={isRelativeErrorExtra4InRange}
              q_min_limit_negative={q_max_limit_negative}
              q_min_limit_positive={q_max_limit_negative}
              visibleText={isRelativeError2InRange}
            />
          </Collapse>

          <MeasurementInputs
            index={isRelativeError1InRange && !isRelativeError2InRange ? 4 : 6}
            start={start_extra_5}
            measurementConsumption={measurementConsumption_extra_5}
            finish={finish_extra_5}
            volumeEtalon={volumeEtalon_extra_5}
            fieldStart='start_extra_5'
            fieldMeasurementConsumption='measurementConsumption_extra_5'
            fieldFinish='finish_extra_5'
            fieldVolumeEtalon='volumeEtalon_extra_5'
          />

          <Collapse
            in={!!start_extra_5 && !!finish_extra_5 && !!volumeEtalon_extra_5}
            timeout={500}
            unmountOnExit
          >
            <MeasurementResults
              volumeAirMeter={volumeAirMeter_extra_5}
              permissibleError={permissibleError_extra_5}
              isRelativeErrorInRange={isRelativeErrorExtra5InRange}
              q_min_limit_negative={q_max_limit_negative}
              q_min_limit_positive={q_max_limit_positive}
              visibleText={isRelativeError2InRange}
            />
          </Collapse>
        </div>
      </Collapse>

      <Collapse in={!!averageErrorQT} timeout={500} unmountOnExit>
        <AverageValue
          averageError={averageErrorQT}
          isRelativeErrorInRange={isRelativeAverageErrorQT}
          q_limit_negative={q_max_limit_negative}
          q_limit_positive={q_max_limit_positive}
        />
      </Collapse>

      <Typography variant='subtitle1'>
        Значение Qmax:
        <span style={{ color: '#0084E2', marginLeft: '4px' }}>{q_max || '0.00'} м³/ч</span>
      </Typography>

      <MeasurementInputs
        index={getIndexForFirstBlock(isRelativeError1InRange, isRelativeError2InRange)}
        start={start_3}
        measurementConsumption={measurementConsumption_3}
        finish={finish_3}
        volumeEtalon={volumeEtalon_3}
        fieldStart='start_3'
        fieldMeasurementConsumption='measurementConsumption_3'
        fieldFinish='finish_3'
        fieldVolumeEtalon='volumeEtalon_3'
      />

      <Collapse in={!!start_3 && !!finish_3 && !!volumeEtalon_3} timeout={500} unmountOnExit>
        <MeasurementResults
          volumeAirMeter={volumeAirMeter_3}
          permissibleError={permissibleError_3}
          isRelativeErrorInRange={isRelativeError3InRange}
          q_min_limit_negative={q_max_limit_negative}
          q_min_limit_positive={q_max_limit_negative}
          visibleText={isRelativeError3InRange}
        />
      </Collapse>

      <Collapse in={!isRelativeError3InRange} timeout={500} unmountOnExit>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <MeasurementInputs
            index={getIndexForSecondBlock(
              isRelativeError1InRange,
              isRelativeError2InRange,
              isRelativeError3InRange,
            )}
            start={start_extra_6}
            measurementConsumption={measurementConsumption_extra_6}
            finish={finish_extra_6}
            volumeEtalon={volumeEtalon_extra_6}
            fieldStart='start_extra_6'
            fieldMeasurementConsumption='measurementConsumption_extra_6'
            fieldFinish='finish_extra_6'
            fieldVolumeEtalon='volumeEtalon_extra_6'
          />

          <Collapse
            in={!!start_extra_6 && !!finish_extra_6 && !!volumeEtalon_extra_6}
            timeout={500}
            unmountOnExit
          >
            <MeasurementResults
              volumeAirMeter={volumeAirMeter_extra_6}
              permissibleError={permissibleError_extra_6}
              isRelativeErrorInRange={isRelativeErrorExtra6InRange}
              q_min_limit_negative={q_max_limit_negative}
              q_min_limit_positive={q_max_limit_negative}
              visibleText={isRelativeError3InRange}
            />
          </Collapse>

          <MeasurementInputs
            index={getIndexForThirdBlock(
              isRelativeError1InRange,
              isRelativeError2InRange,
              isRelativeError3InRange,
            )}
            start={start_extra_7}
            measurementConsumption={measurementConsumption_extra_7}
            finish={finish_extra_7}
            volumeEtalon={volumeEtalon_extra_7}
            fieldStart='start_extra_7'
            fieldMeasurementConsumption='measurementConsumption_extra_7'
            fieldFinish='finish_extra_7'
            fieldVolumeEtalon='volumeEtalon_extra_7'
          />

          <Collapse
            in={!!start_extra_7 && !!finish_extra_7 && !!volumeEtalon_extra_7}
            timeout={500}
            unmountOnExit
          >
            <MeasurementResults
              volumeAirMeter={volumeAirMeter_extra_7}
              permissibleError={permissibleError_extra_7}
              isRelativeErrorInRange={isRelativeErrorExtra7InRange}
              q_min_limit_negative={q_max_limit_negative}
              q_min_limit_positive={q_max_limit_negative}
              visibleText={isRelativeError3InRange}
            />
          </Collapse>
        </div>
      </Collapse>

      <Collapse in={!!averageErrorQMax} timeout={500} unmountOnExit>
        <AverageValue
          averageError={averageErrorQMax}
          isRelativeErrorInRange={isRelativeAverageErrorQMax}
          q_limit_negative={q_max_limit_negative}
          q_limit_positive={q_max_limit_positive}
        />
      </Collapse>
    </div>
  )
}

export default Indications
