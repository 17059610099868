// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react/jsx-no-target-blank */
// ----------------------------------------------------------------

import React, { useEffect, useState, useRef, FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Skeleton, useMediaQuery, Fab } from '@mui/material'
import SelectorComponent from '../../components/LocalElements/Selector/reactSelect/Selector.jsx'
import { settingsFileViewer } from '../../../store/slices/app/comps/modal.js'
import { setMeasurementId } from '../../../store/slices/app/views/meters.js'
import BackButton from '../../../ui/back-button/index.tsx'
import theme from '../../../utils/theme.ts'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

import D from '../../../store/localStorage/dispatcher.js'
import R from '../../../services/app/client-server/request.service.js'

import GeneralInformation from '../../components/Meters/MeterShow/GeneralInformation.tsx'
import DescriptionDevice from '../../components/Meters/MeterShow/DescriptionDevice.tsx'
import ApprovedTypes from '../../components/Meters/MeterShow/ApprovedTypes.tsx'
import PermissibleError from '../../components/Meters/MeterShow/PermissibleError.tsx'
import Characteristics from '../../components/Meters/MeterShow/Characteristics.tsx'
import Mpi from '../../components/Meters/MeterShow/Mpi.tsx'

import { IMeterData, IModification } from '../../types.models.views.ts'
import { Title } from '../../components/Meters/MeterShow/styles.ts'

type Option = {
  label: string
  value: any
}

type SelectorProps = {
  placeholder?: string
  isClearable?: boolean
  isCreatable?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  onChange: (value: any) => void
  value: any
  options: Option[]
  noOptionsMessage?: () => string
  defaultValue?: Option | null
  isDisabled?: boolean
  onInputChange?: (inputValue: string) => void
  label?: {
    topBg: string
    bottomBg: string
    text: string
  }
  isRequired?: boolean
}

const Selector = SelectorComponent as FC<SelectorProps>

const MeterShow = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { meters_id } = useParams()
  const headliner = useRef<HTMLElement | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const measurement_id = useSelector((state: any) => state.meterReducer.measurement_id)
  const [data, setData] = useState<IMeterData | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const modifications = data?.modifications || []
  const [modification, setModification] = useState<IModification | null>(null)
  const [scrolling, setScrolling] = useState<boolean>(false)

  useEffect(() => {
    if (measurement_id) {
      D.set('measurement_id', measurement_id)
    }
  }, [measurement_id])

  useEffect(() => {
    if (modifications.length > 0) {
      setModification(modifications[0])
    }
  }, [modifications])

  useEffect(() => {
    const savedMeasurementId = D.get('measurement_id')
    if (savedMeasurementId) {
      dispatch(setMeasurementId(parseInt(savedMeasurementId, 10)))
    }
  }, [])

  useEffect(() => {
    if (measurement_id) {
      ;(async () => {
        setIsLoading(true)
        const { data } = await R.getMeterItem(`${measurement_id}/${meters_id}`)
        setData(data?.data)
        setIsLoading(false)
      })()
    }
  }, [measurement_id, meters_id])

  useEffect(() => {
    if (!isLoading && headliner.current) {
      headliner.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrolling, isLoading])

  useEffect(() => {
    return () => {
      dispatch(settingsFileViewer({ value: 'open', label: false }))
    }
  }, [])

  return (
    <main className='main' style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {isMobile ? (
        <Fab
          color='primary'
          aria-label='add'
          onClick={() => navigate('/metriva/meters')}
          style={{
            position: 'absolute',
            bottom: '80px',
          }}
        >
          <ArrowBackIosNewIcon style={{ marginRight: '3px' }} />
        </Fab>
      ) : (
        <BackButton margin='0' to='/metriva/meters' label='Назад к списку приборов' />
      )}

      {isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Title>
            <Skeleton
              sx={{ borderRadius: '16px', marginRight: isMobile ? '0px' : '14px' }}
              variant='text'
              width={isMobile ? '100%' : '70%'}
              height={55}
            />
            <Skeleton
              sx={{ borderRadius: '16px' }}
              variant='rectangular'
              width={isMobile ? '100%' : '30%'}
              height={55}
            />
          </Title>

          <Skeleton variant='rectangular' height={360} sx={{ borderRadius: '16px' }} />
          <Skeleton variant='rectangular' height={60} sx={{ borderRadius: '16px' }} />
          <Skeleton variant='rectangular' height={110} sx={{ borderRadius: '16px' }} />
          <Skeleton variant='rectangular' height={130} sx={{ borderRadius: '16px' }} />
          <Skeleton variant='rectangular' height={60} sx={{ borderRadius: '16px' }} />
          <Skeleton variant='rectangular' height={110} sx={{ borderRadius: '16px' }} />
        </div>
      ) : (
        <React.Fragment>
          <Title>
            <Typography
              variant={isMobile ? 'h6' : 'h2'}
              ref={headliner}
              sx={{ width: isMobile ? '100%' : '70%' }}
            >
              {data?.manufacturer_name}
            </Typography>

            <div style={{ width: isMobile ? '100%' : '400px' }}>
              <Selector
                isDisabled={false}
                placeholder='Выберите модификацию прибора'
                value={
                  modification
                    ? {
                        value: modifications.findIndex(m => m.name === modification.name),
                        label: modification.name,
                      }
                    : null
                }
                options={modifications.map((variant, index) => ({
                  value: index,
                  label: variant.name,
                }))}
                onChange={selected => {
                  if (selected) {
                    setModification(modifications[selected.value])
                  } else {
                    setModification(modifications[0])
                  }
                }}
                isClearable={true}
                isSearchable={true}
              />
            </div>
          </Title>

          {data && (
            <>
              <GeneralInformation data={data} />
              <Mpi data={data} modification={modification} />
              <Characteristics modification={modification} qt={data.q_t_formula} />
              <PermissibleError data={data} modification={modification} />
              <ApprovedTypes data={data} conversion_factor={modification?.conversion_factor} />
              <DescriptionDevice data={data} />
            </>
          )}

          {isMobile && (
            <span
              style={{
                opacity: 0.55,
                width: '100%',
                textAlign: 'center',
                margin: '15px auto',
              }}
              onClick={() => setScrolling(prev => !prev)}
            >
              Вернуться наверх
            </span>
          )}
        </React.Fragment>
      )}
    </main>
  )
}

export default MeterShow
