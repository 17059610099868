import dayjs from 'dayjs'

export const createGasMeasurement = {
  errors: {},

  customerName: 'Физическое лицо',
  customerNameType: 'Физическое лицо',
  customerAddress: null,
  customerFullName: '',
  customerPhoneNumber: '',
  agreementNumber: '',

  meterFifNum: '',
  meterViewStatus: '',
  selectedMeter: [],
  modifications: [],

  selectedModification: null,

  selectedAdditionals: null,

  modification: '',
  valueAdditional: null,
  valueAnotherMod: '',
  is_custom_modification: false,

  meterFifNumber: '',
  meterNameSi: '',

  meterFactoryNumber: '',
  meterYear: '',

  diameter: '',
  standartSize: '',
  q_min: '',
  q_t: '',
  q_max: '',
  q_nom: '',
  q_t_formula: '',
  mpi: '',

  q_max_limit_negative: '',
  q_max_limit_positive: '',
  q_min_limit_negative: '',
  q_min_limit_positive: '',

  temperature: '',
  humidity: '',
  pressure: '',
  voltageDeviation: '',
  frequencyDeviation: '',
  airTempDifference: '',
  airTempSpeed: '',

  start_1: null,
  finish_1: null,
  start_extra_2: null,
  finish_extra_2: null,
  start_extra_3: null,
  finish_extra_3: null,
  start_2: null,
  finish_2: null,
  start_extra_4: null,
  finish_extra_4: null,
  start_extra_5: null,
  finish_extra_5: null,
  start_3: null,
  finish_3: null,
  start_extra_6: null,
  finish_extra_6: null,
  start_extra_7: null,
  finish_extra_7: null,

  volumeEtalon_1: null,
  volumeEtalon_extra_2: null,
  volumeEtalon_extra_3: null,
  volumeEtalon_2: null,
  volumeEtalon_extra_4: null,
  volumeEtalon_extra_5: null,
  volumeEtalon_3: null,
  volumeEtalon_extra_6: null,
  volumeEtalon_extra_7: null,

  measurementConsumption_1: null,
  measurementConsumption_extra_2: null,
  measurementConsumption_extra_3: null,
  measurementConsumption_2: null,
  measurementConsumption_extra_4: null,
  measurementConsumption_extra_5: null,
  measurementConsumption_3: null,
  measurementConsumption_extra_6: null,
  measurementConsumption_extra_7: null,

  volumeAirMeter_1: null,
  volumeAirMeter_extra_2: null,
  volumeAirMeter_extra_3: null,
  volumeAirMeter_2: null,
  volumeAirMeter_extra_4: null,
  volumeAirMeter_extra_5: null,
  volumeAirMeter_3: null,
  volumeAirMeter_extra_6: null,
  volumeAirMeter_extra_7: null,

  permissibleError_1: null,
  permissibleError_extra_2: null,
  permissibleError_extra_3: null,
  permissibleError_2: null,
  permissibleError_extra_4: null,
  permissibleError_extra_5: null,
  permissibleError_3: null,
  permissibleError_extra_6: null,
  permissibleError_extra_7: null,

  permissibleErrorTotal_QMin: null,
  permissibleErrorTotal_QT: null,
  permissibleErrorTotal_QMax: null,

  isMechanicalAccepted: true,
  isTightnessAccepted: true,

  isTotalAccepted: false,
  isMarkPassport: false,
  isMarkSi: false,

  nextVerificationDate: '',

  correction: '',
  pressureLoss: '',

  indicationPass: false,

  indications: [],
  conditions: [],
}
