import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { changeProperty } from './state.js'

const useNextVerificationDate = ({
  defaultData,
  meterType,
  dateVerification,
  mpiColdAdditionals,
  mpiHotAdditionals,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (defaultData && meterType && dateVerification && dayjs(dateVerification).isValid()) {
      let nextVerificationYears

      if (meterType === 'hot') {
        nextVerificationYears = mpiHotAdditionals
          ? parseFloat(mpiHotAdditionals)
          : parseFloat(defaultData.mpi_hot)
      } else if (meterType === 'cold') {
        nextVerificationYears = mpiColdAdditionals
          ? parseFloat(mpiColdAdditionals)
          : parseFloat(defaultData.mpi_cold)
      }

      if (nextVerificationYears) {
        const nextVerificationDateCalculated = dayjs(dateVerification)
          .add(nextVerificationYears, 'month')
          .subtract(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss')

        dispatch(
          changeProperty({
            value: 'nextVerificationDateAutoGenerate',
            label: nextVerificationDateCalculated,
          }),
        )
      }
    } else {
      dispatch(
        changeProperty({
          value: 'nextVerificationDateAutoGenerate',
          label: '',
        }),
      )
    }
  }, [defaultData, meterType, dateVerification, mpiColdAdditionals, mpiHotAdditionals, dispatch])
}

export default useNextVerificationDate
