import React from 'react'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon.jsx'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon.jsx'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker.jsx'
import { Typography } from '@mui/material'
import theme from '../../../../../utils/theme.ts'
import dayjs from 'dayjs'

const VerificationDate = ({
  dateVerification,
  handleDateChange,
  availableDates,
  isDisabled = false,
}) => {
  const formattedDateVerification = dateVerification
    ? dayjs(dateVerification).format('YYYY-MM-DD')
    : ''

  return (
    <>
      <span
        style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', position: 'relative' }}
      >
        <span style={{ fontSize: '15px' }}>Дата проведения поверки</span>
        {dateVerification !== '' ? (
          <span
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              position: 'absolute',
              left: '202px',
            }}
          >
            <CheckCircleIcon sx={{ color: '#63E6BE' }} />
          </span>
        ) : (
          <span
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              position: 'absolute',
              left: '202px',
            }}
          >
            <VisibilityToggleIcon sx={{ color: '#FFD43B' }} />
          </span>
        )}
      </span>

      {formattedDateVerification &&
        availableDates.length > 0 &&
        !availableDates.includes(formattedDateVerification) && (
          <Typography variant='h6' sx={{ color: theme.palette.background.error }}>
            От указанной даты прошло более 40 рабочих дней
          </Typography>
        )}

      <DatePicker
        selectedDate={dateVerification}
        dateChange={handleDateChange}
        availableDates={availableDates}
        isDisabled={isDisabled}
      />
    </>
  )
}

export default VerificationDate
