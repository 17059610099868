// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import Table from '../../components/LocalElements/Table/Table'
import Button from '../../../ui/button/index.tsx'
import Tooltip from '../../../ui/tooltip/index.tsx'
import { CircularProgress } from '@mui/material'
import ArchiveIcon from '../../../img/icon/ArchiveIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import RestoreIcon from '../../../img/icon/RestoreIcon'

import { setOpen, setTitle, setChildren, setBA } from '../../../store/slices/app/comps/modal'
import { setName } from '../../../store/slices/app/controlers/pageName'
import {
  clearCreateData,
  setSearchQuery as setSearchQueryCompanies,
  setViewVariantMain,
  setViewVariant,
  setSelectedCompanies,
} from '../../../store/slices/app/views/companies'

const Company = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector(state => state.companiesReducer.companiesList)
  const selectedCompanies = useSelector(state => state.companiesReducer.selectedCompanies)
  const companiesSearchQuery = useSelector(state => state.companiesReducer.searchQuery)
  const viewVariant = useSelector(state => state.companiesReducer.viewVariantMain)

  const changeSearchQuery = param => {
    dispatch(setSearchQueryCompanies(param))
  }

  const handleCompanyClick = () => {
    dispatch(clearCreateData())
    navigate('/metriva/companies/create')
  }

  const handleRowClickCompany = params => {
    navigate(`/metriva/companies/${params.row.id}`)
  }

  const handleNavigateTariff = companyId => {
    dispatch(setViewVariant(1))
    navigate(`/metriva/companies/${companyId}`)
  }

  const getStatusLabel = tariff => {
    switch (tariff) {
      case 'active':
        return 'Активный'
      case 'trial':
        return 'Пробный'
      case 'frozen':
        return 'Замороженный'
      case 'limited':
        return 'Ограниченный'
      case 'deleted':
        return 'Удаленный'
      default:
        return tariff
    }
  }

  const changeViewVariant = param => dispatch(setViewVariantMain(param))

  useEffect(() => {
    dispatch(setName('Компании в системе'))
  }, [])

  const DinamicActions = () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button
        onClick={() => {}}
        label='Архивировать компании'
        color='text'
        startIcon={<ArchiveIcon />}
      />
    </div>
  )

  const DinamicActionsArchive = () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button color='text' startIcon={<RestoreIcon />} onClick={() => {}} label='Восстановить' />
      <span
        style={{
          width: '1px',
          height: '100%',
          opacity: 0.44,
          background: 'linear-gradient(to bottom, transparent, gray, transparent)',
        }}
      />
      <Button
        color='text'
        startIcon={<DeleteIcon />}
        onClick={() => {}}
        label='Полностью удалить'
      />
    </div>
  )

  return (
    <main className='main'>
      <BasicTabs
        viewVariant={viewVariant}
        callback={changeViewVariant}
        tabs={[{ label: 'Действующие клиенты' }, { label: 'Архивные клиенты' }]}
      />

      {data && data.length > 0 && viewVariant === 0 && (
        <React.Fragment>
          <Table
            colData={[
              {
                field: 'registrationCompany',
                headerName: 'Регистрация',
                disableFilter: true,
              },
              { field: 'name', headerName: 'Наименование' },
              { field: 'inn', headerName: 'ИНН', disableFilter: true },
              {
                field: 'lanBilling',
                headerName: 'LanBilling',
                renderCell: params => {
                  const style = {
                    color: params.value === 'Подключен' ? 'green' : 'red',
                  }
                  return <div style={style}>{params.value}</div>
                },
              },
              { field: 'status', headerName: 'Статус тарифа' },
              { field: 'tariff', headerName: 'Тариф', disableFilter: true },
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Действия',
                getActions: item => {
                  const localItem = item

                  return [
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '15px',
                      }}
                    >
                      <span
                        onClick={() => handleNavigateTariff(localItem.id)}
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          gap: '16px',
                        }}
                      >
                        <p
                          className='icon'
                          style={{ fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}
                        >
                          Настроить тариф
                        </p>
                      </span>
                    </div>,
                  ]
                },
              },
            ]}
            rowData={data
              .filter(item => item?.status !== 'deleted')
              .map(item => ({
                id: item?.id,
                registrationCompany: moment(item?.created_at.full).format('DD-MM-YYYY'),
                name: item?.name,
                inn: item?.inn,
                status: getStatusLabel(item?.status),
                tariff:
                  item?.subscription_id === 2
                    ? 'Полный доступ'
                    : item?.subscription_id === 1
                      ? 'Пробный период'
                      : 'Индивидуальный',
                lanBilling: item?.billing?.is_synchronized ? 'Подключен' : 'Не подключен',
              }))}
            rowCallback={handleRowClickCompany}
            showToolbar={true}
            noCreating={false}
            noRowsText={<CircularProgress />}
            actions={{
              static: null,
              dinamic:
                selectedCompanies && selectedCompanies.length > 0 ? <DinamicActions /> : null,
            }}
            addButtonCallback={handleCompanyClick}
            searchParams={{ query: companiesSearchQuery, builder: changeSearchQuery }}
            rowSelectionCallback={array => {
              dispatch(setSelectedCompanies(array))
            }}
            hasCheckboxSelection={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      )}
      {data && data.length > 0 && viewVariant === 1 && (
        <React.Fragment>
          <Table
            colData={[
              {
                field: 'registrationCompany',
                headerName: 'Приостановление обслуживания',
              },
              { field: 'name', headerName: 'Наименование' },
              { field: 'inn', headerName: 'ИНН' },
              {
                field: 'lanBilling',
                headerName: 'LanBilling',
                renderCell: params => {
                  const style = {
                    color: params.value === 'Подключен' ? 'green' : 'red',
                  }
                  return <div style={style}>{params.value}</div>
                },
              },
              { field: 'status', headerName: 'Статус тарифа' },
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Действия',
                getActions: item => {
                  return [
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '15px',
                      }}
                    >
                      <Tooltip title='Восстановить компанию'>
                        <span style={{ width: '30%' }}>
                          <RestoreIcon />
                        </span>
                      </Tooltip>

                      <Tooltip title='Удалить компанию'>
                        <span
                          style={{ width: '30%' }}
                          onClick={() => {
                            dispatch(setOpen(true))
                            dispatch(setTitle('Вы действительно хотите удалить компанию?'))
                            dispatch(
                              setChildren('После удаления все данные будут стерты из системы.'),
                            )
                            dispatch(setBA('Удалить'))
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </Tooltip>
                    </div>,
                  ]
                },
              },
            ]}
            rowData={data
              .filter(item => item?.status === 'deleted')
              .map(item => ({
                id: item?.id,
                registrationCompany: moment(item?.created_at.full).format('DD-MM-YYYY'),
                name: item?.name,
                inn: item?.inn,
                status: getStatusLabel(item?.status),
                lanBilling: item?.billing?.is_synchronized ? 'Подключен' : 'Не подключен',
              }))}
            rowCallback={handleRowClickCompany}
            showToolbar={true}
            noCreating={true}
            noRowsText={<CircularProgress />}
            actions={{
              static: null,
              dinamic:
                selectedCompanies && selectedCompanies.length > 0 ? (
                  <DinamicActionsArchive />
                ) : null,
            }}
            addButtonCallback={handleCompanyClick}
            rowSelectionCallback={array => {
              dispatch(setSelectedCompanies(array))
            }}
            hasCheckboxSelection={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </main>
  )
}

export default Company
