// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import WaterMi1592Edit from '../../components/Methodologies/water/Mi-1592-2015/desktop/Edit'
import GasGost8324 from '../../components/Methodologies/gas/Gost8324-2002/Edit.jsx'

import VerificationDate from '../../components/Methodologies/views/ProtocolVerificationDate/VerificationDate.jsx'
import Cases from '../../components/Methodologies/views/ProtocolCases/ProtocolCases.jsx'
import ScopeMethod from '../../components/Methodologies/views/ScopeMethod/ScopeMethod.jsx'
import BackButton from '../../../ui/back-button/index.tsx'

import R from '../../../services/app/client-server/request.service'
import {
  changeCase,
  resetCase,
  resetMeasurementId,
  resetCompanyId,
  resetMethodId,
  setDateVerification,
} from '../../../store/slices/app/views/protocols'

const Edit = () => {
  const { protocol_id } = useParams()
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [availableDates, setAvailableDates] = useState([])
  const [isDateDisabled, setIsDateDisabled] = useState(false)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getProtocol(protocol_id)

      setData(data?.data)

      if (!data.data?.originalCase) {
        setIsDateDisabled(true)
      } else {
        setIsDateDisabled(false)
        setAvailableDates(data.data.originalCase.available_dates || [])
      }

      dispatch(setDateVerification(data?.data?.verification_date))

      dispatch(
        changeCase({
          value: 'pointId',
          label: data?.data?.point?.id,
        }),
      )
    })()
  }, [protocol_id, dispatch])

  const handleDateChange = date => {
    if (date) {
      dispatch(setDateVerification(date.format('YYYY-MM-DD HH:mm:ss')))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetMeasurementId())
      dispatch(resetCompanyId())
      dispatch(resetMethodId())
      dispatch(setDateVerification(''))
    }
  }, [])

  return (
    <main className={'main'}>
      <BackButton to='/metriva/protocols' label='Назад к списку протоколов' />

      <div
        className='width32Percent'
        style={{ display: 'flex', flexFlow: 'column', gap: '9px', width: '100%' }}
      >
        <VerificationDate
          dateVerification={dateVerification}
          handleDateChange={handleDateChange}
          availableDates={availableDates}
          isDisabled={isDateDisabled}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', margin: '14px 0' }}>
        <Cases data={data} />

        <ScopeMethod data={data} />
      </div>

      {(pointId === 1 || pointId === 3) && <WaterMi1592Edit />}
      {pointId === 2 && <GasGost8324 />}
    </main>
  )
}

export default Edit
