import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Cookies from 'js-cookie'
import api from '../../services/app/client-server/axios-config.service'

import { setAuthToken } from '../../store/slices/app/controlers/auth'

import { IconButton } from '@mui/material'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

const CustomNavbarActions = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const surname = useSelector(state => state.headerReducer.surname)
  const name = useSelector(state => state.headerReducer.name)

  const dispatch = useDispatch()

  const translateRole = useMemo(() => {
    let roleLocal = ''

    if (userRole === 'admin') {
      roleLocal = 'администратор'
    } else if (userRole === 'super') {
      roleLocal = 'суперадмин'
    } else if (userRole === 'metrologist') {
      roleLocal = 'поверитель'
    } else if (userRole === 'brigadier') {
      roleLocal = 'руководитель'
    }

    return roleLocal
  }, [userRole])

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleEditPassword = () => {}

  const handleLogout = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/auth/logout`
      const response = await api.post(apiUrl)

      if (response.status === 200) {
        toggleMenu()

        Cookies.remove('accessToken')
        localStorage.clear()

        dispatch(setAuthToken(''))
      } else {
        console.error('Ошибка при выходе из системы')
      }
    } catch (error) {
      console.error('Произошла ошибка:', error)
    }
  }

  const toggleMenu = React.useCallback(
    event => {
      setMenuAnchorEl(isMenuOpen ? null : event.currentTarget)
      setIsMenuOpen(previousIsMenuOpen => !previousIsMenuOpen)
    },
    [isMenuOpen],
  )

  useEffect(() => {
    false && console.log(userRole)
  }, [userRole])

  return (
    <React.Fragment>
      <div className='profile_user' style={{ gap: '8px' }}>
        <span>{translateRole}</span>
        <h3>{`${surname} ${name}`}</h3>
        <IconButton type='button' aria-label='settings' onClick={toggleMenu}>
          <AccountBoxIcon sx={{ transform: 'scale(1.3)' }} />
        </IconButton>
      </div>
      <Popover
        open={isMenuOpen}
        anchorEl={menuAnchorEl}
        onClose={toggleMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus
      >
        <div style={{ padding: '8px' }}>
          <MenuItem
            onClick={handleEditPassword}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '14px',
              height: '44px',
              borderRadius: '4px',
            }}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8ZM14 16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16C10 14.8954 10.8954 14 12 14C13.1046 14 14 14.8954 14 16Z'
                fill='#899298'
              />
            </svg>
            <span style={{ marginLeft: '10px', fontSize: '14px' }}>Сменить пароль</span>
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '14px',
              height: '44px',
              borderRadius: '4px',
            }}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447ZM16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H9.81066L11.5303 14.4697C11.8232 14.7626 11.8232 15.2374 11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303L7.46967 12.5303C7.32902 12.3897 7.25 12.1989 7.25 12C7.25 11.8011 7.32902 11.6103 7.46967 11.4697L10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967C11.8232 8.76256 11.8232 9.23744 11.5303 9.53033L9.81066 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12Z'
                fill='#899298'
              />
            </svg>
            <span style={{ marginLeft: '10px', fontSize: '14px' }}>Выйти из системы</span>
          </MenuItem>
        </div>
      </Popover>
    </React.Fragment>
  )
}

export default CustomNavbarActions
