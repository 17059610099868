// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RadioGroup, FormControlLabel, Radio, Button, Tooltip } from '@mui/material'
import R from '../../../services/app/client-server/request.service'
import DataUser from '../../components/Users/DataUser'
import SelectedRole from '../../components/Users/SelectedRole'
import SelectedRegions from '../../components/Users/SelectedRegions'
import SelectedCases from '../../components/Users/SelectedCases'
import SelectedUsers from '../../components/Users/SelectedUsers'
import Feedback from '../../components/LocalElements/Feedback/Feedback'
import { setShow } from '../../../store/slices/app/comps/feedback'
import { changeData, resetCreateData } from '../../../store/slices/app/views/users'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import { refreshUsers, refreshHeader } from '../../../store/slices/app/controlers/updater'
import BackButton from '../../../ui/back-button/index.tsx'

const EditUsers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { users_id } = useParams()

  const show = useSelector(state => state.feedbackReducer.show)
  const userRole = useSelector(state => state.headerReducer.role)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const companyId = useSelector(state => state.usersReducer.createData.companyId)
  const regionIds = useSelector(state => state.usersReducer.createData.regionIds)
  const rolesId = useSelector(state => state.usersReducer.createData.rolesId)
  const casesIds = useSelector(state => state.usersReducer.createData.casesIds)
  const userId = useSelector(state => state.usersReducer.createData.userId)
  const usersId = useSelector(state => state.usersReducer.createData.usersId)
  const isTrusted = useSelector(state => state.usersReducer.createData.isTrusted)
  const name = useSelector(state => state.usersReducer.createData.name)
  const surname = useSelector(state => state.usersReducer.createData.surname)
  const patr = useSelector(state => state.usersReducer.createData.patr)
  const login = useSelector(state => state.usersReducer.createData.login)
  const password = useSelector(state => state.usersReducer.createData.password)
  const email = useSelector(state => state.usersReducer.createData.email)

  const handleTrustedChange = event => {
    const value = event.target.value === 'true'
    dispatch(
      changeData({
        value: 'isTrusted',
        label: value,
      }),
    )
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: usersData } = await R.getUsersItem(users_id)
        const data = usersData.data

        dispatch(
          changeData({
            value: 'surname',
            label: data.surname,
          }),
        )
        dispatch(
          changeData({
            value: 'name',
            label: data.name,
          }),
        )
        dispatch(
          changeData({
            value: 'patr',
            label: data.patr,
          }),
        )
        dispatch(
          changeData({
            value: 'email',
            label: data.email,
          }),
        )
        dispatch(
          changeData({
            value: 'login',
            label: data.login,
          }),
        )

        const initialRegionIds = data?.regions?.map(region => region.id)
        const initialUsersIds = data?.metrologists?.map(metrologists => metrologists.id)
        const initialCases = data?.cases?.map(caseItem => caseItem.id)

        dispatch(
          changeData({
            value: 'companyId',
            label: data.company.id,
          }),
        )
        dispatch(
          changeData({
            value: 'regionIds',
            label: initialRegionIds,
          }),
        )
        dispatch(
          changeData({
            value: 'casesIds',
            label: initialCases,
          }),
        )
        dispatch(
          changeData({
            value: 'usersId',
            label: initialUsersIds,
          }),
        )
        dispatch(
          changeData({
            value: 'userId',
            label: data.brigadier_id,
          }),
        )
        dispatch(
          changeData({
            value: 'rolesId',
            label: data.role,
          }),
        )
        dispatch(
          changeData({
            value: 'isTrusted',
            label: data.is_trusted,
          }),
        )
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [users_id, dispatch])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      surname: surname,
      patr: patr,
      login: login,
      role: rolesId,
      email: email,
      region_ids: regionIds,
      case_ids: casesIds,
      metrologist_ids: usersId,
      brigadier_id: userId,
    }

    if (userRole === 'super' && companyId) {
      data.company_id = companyId
    }

    if (rolesId === 'metrologist') {
      data.is_trusted = isTrusted
    }

    if (password.trim() !== '') {
      data.password = password
    }

    const { status } = await R.addUserPut(users_id, data)

    if (status === 200) {
      dispatch(refreshUsers())
      dispatch(refreshHeader())
      dispatch(setMessage('Пользователь успешно отредактирован'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      navigate('/metriva/users')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Пользователь не отредактирован - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className={'main'}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <BackButton to='/metriva/users' label='Назад к списку пользователей' />

          <div className='box'>
            <h2 style={{ marginBottom: '25px' }}>Данные учетной записи пользователя</h2>
            <DataUser />

            <h2 style={{ marginBottom: '14px' }}>Рабочие параметры</h2>

            <div className='flexContainerWithGap' style={{ marginBottom: '0px' }}>
              <SelectedRegions />
              <SelectedRole />
            </div>

            {(rolesId === 'brigadier' || rolesId === 'metrologist') && (
              <div className='flexContainerWithGap' style={{ marginTop: '20px' }}>
                {rolesId === 'metrologist' && (
                  <div className='width24Point5Percent'>
                    <span
                      style={{
                        marginBottom: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Статус "Опытный"
                      <Tooltip
                        title='Данные необходимы для передачи в Росаккредитацию ФСА'
                        placement='top'
                        style={{ marginLeft: '7px' }}
                      >
                        <i
                          className='fas fa-question-circle question'
                          style={{ marginLeft: '7px', cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </span>
                    <RadioGroup
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      value={isTrusted}
                      onChange={handleTrustedChange}
                      sx={{
                        height: '50px',
                        alignItems: 'end',
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={'Есть'}
                        sx={{
                          '& .MuiTypography-root': {
                            fontFamily: "'Wix Madefor Display', sans-serif !important",
                            fontSize: '15px !important',
                            fontWeight: 400,
                            color: '#132532',
                          },
                        }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={'Нет'}
                        sx={{
                          '& .MuiTypography-root': {
                            fontFamily: "'Wix Madefor Display', sans-serif !important",
                            fontSize: '15px !important',
                            fontWeight: 400,
                            color: '#132532',
                          },
                        }}
                      />
                    </RadioGroup>
                  </div>
                )}
                {(rolesId === 'brigadier' || rolesId === 'metrologist') && (
                  <div className='width50Percent'>
                    <SelectedUsers />
                  </div>
                )}
                {(rolesId === 'brigadier' || rolesId === 'metrologist') && (
                  <div className='width50Percent'>
                    <SelectedCases />
                  </div>
                )}
              </div>
            )}
          </div>

          <div style={{ marginTop: '14px' }}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                margin: 0,
                borderRadius: '12px',
                width: '100%',
              }}
            >
              Редактировать пользователя
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default EditUsers
