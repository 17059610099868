const ArrowLeftIcon = () => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.818359' y='0.954468' width='24' height='24' rx='12' fill='#EAEDF3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8379 8.60091C12.0332 8.79618 12.0332 9.11276 11.8379 9.30802L8.69148 12.4545H18.151C18.4272 12.4545 18.651 12.6783 18.651 12.9545C18.651 13.2306 18.4272 13.4545 18.151 13.4545H8.69148L11.8379 16.6009C12.0332 16.7962 12.0332 17.1128 11.8379 17.308C11.6427 17.5033 11.3261 17.5033 11.1308 17.308L7.13082 13.308C6.93556 13.1128 6.93556 12.7962 7.13082 12.6009L11.1308 8.60091C11.3261 8.40565 11.6427 8.40565 11.8379 8.60091Z'
        fill='#1C274C'
      />
    </svg>
  )
}

export default ArrowLeftIcon
