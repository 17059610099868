// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import EditPassword from '../app/pages/Auth/EditPassword'

import NewProtocol from '../app/pages/Protocols/NewProtocol'
import JournalsSettings from '../app/pages/Journals/JournalsSettings'

import NotForMobile from '../app/pages/NotForMobile'

import useWindowDimensions from '../app/hooks/useWindowDimensions'

import RouteProtocols from './Route.protocols.tsx'
import RouteUsers from './Route.users.tsx'
import RouteMeters from './Route.meters.tsx'
import RouteCases from './Route.cases.tsx'
import RouteCompanies from './Route.companies.tsx'
import RouteCompany from './Route.company.tsx'
import RouteJournals from './Route.journals.tsx'
import RouteEtalon from './Route.etalon.tsx'
import RouteSettings from './Route.settings.tsx'

const RoutesComponent = () => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route path='edit_password' element={<EditPassword />} />

      <Route path='protocols/*' element={<RouteProtocols />} />

      <Route path='protocol' element={width > 1050 ? <NewProtocol /> : <NewProtocol />} />

      <Route path='users/*' element={<RouteUsers />} />

      <Route path='meters/*' element={<RouteMeters />} />

      <Route path='cases/*' element={<RouteCases />} />

      <Route path='companies/*' element={<RouteCompanies />} />

      <Route path='company/*' element={<RouteCompany />} />

      <Route path='journals/*' element={<RouteJournals />} />

      <Route path='etalon/*' element={<RouteEtalon />} />

      <Route path='settings/*' element={<RouteSettings />} />
    </Routes>
  )
}

export default RoutesComponent
