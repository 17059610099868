import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import {
  ContainerDescDev,
  TableHeader,
  TableRow,
  CharColumn,
  CharLabelRow,
  CharValueBlock,
  CharValueCell,
} from './styles.ts'

import { IModification } from '../../../types.models.views.ts'

interface CharacteristicsProps {
  modification: IModification | null
  qt?: string
}

type CharItem =
  | {
      id: string
      label: string[]
      subLabel: string[]
      value: (string | number)[]
    }
  | {
      id: string
      label: string[]
      value: string | number
    }

const Characteristics: FC<CharacteristicsProps> = ({ modification, qt }) => {
  const measurement_id = useSelector((state: any) => state.meterReducer.measurement_id)
  const isType1 = measurement_id === 1

  const characteristics: CharItem[] = isType1
    ? [
        {
          id: 'qmin',
          label: ['Qmin', ''],
          subLabel: ['A - вертикальная', 'B - горизонтальная'],
          value: [modification?.q_min_vertical ?? '-', modification?.q_min_horizontal ?? '-'],
        },
        {
          id: 'qt',
          label: ['Qt', ''],
          subLabel: ['A - вертикальная', 'B - горизонтальная'],
          value: [modification?.q_t_vertical ?? '-', modification?.q_t_horizontal ?? '-'],
        },
        {
          id: 'qmax',
          label: ['Qmax', ''],
          subLabel: ['A - вертикальная', 'B - горизонтальная'],
          value: [modification?.q_max_vertical ?? '-', modification?.q_max_horizontal ?? '-'],
        },
      ]
    : [
        {
          id: 'qmin',
          label: ['Qmin', ''],
          value: modification?.q_min ?? '-',
        },
        {
          id: 'qt',
          label: ['Qt', ''],
          value: qt ?? '-',
        },
        {
          id: 'qmax',
          label: ['Qmax', ''],
          value: modification?.q_max ?? '-',
        },
        {
          id: 'qnom',
          label: ['Qnom', ''],
          value: modification?.q_nom ?? '-',
        },
      ]

  return (
    <ContainerDescDev>
      <TableHeader>
        {characteristics.map(item => (
          <CharColumn key={item.id}>
            {item.label.map((line, i) => (
              <Typography key={i} variant='subtitle1' align='center'>
                {line}
              </Typography>
            ))}
            {'subLabel' in item && (
              <CharLabelRow>
                {item.subLabel.map((text, idx) => (
                  <CharValueCell key={idx}>
                    <Typography variant='subtitle2'>
                      <i style={{ fontStyle: 'normal', fontWeight: 600 }}>{text}</i>
                    </Typography>
                  </CharValueCell>
                ))}
              </CharLabelRow>
            )}
          </CharColumn>
        ))}
      </TableHeader>

      <TableRow>
        {characteristics.map(item => (
          <CharValueBlock key={item.id}>
            {Array.isArray(item.value) ? (
              item.value.map((val, idx) => (
                <CharValueCell key={idx}>
                  <Typography variant='subtitle2'>{val}</Typography>
                </CharValueCell>
              ))
            ) : (
              <CharValueCell>
                <Typography variant='subtitle2'>{item.value}</Typography>
              </CharValueCell>
            )}
          </CharValueBlock>
        ))}
      </TableRow>
    </ContainerDescDev>
  )
}

export default Characteristics
