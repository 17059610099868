// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import dayjs from 'dayjs'

import api from '../../../../../services/app/client-server/axios-config.service'

import { getAcceptanceChecks } from '../Gost8324-2002/helpers/helpers.js'
import formatPhoneNumber from '../../../../../utils/formatPhoneNumber.js'

import MeterData from '../../views/MeterData/MeterGas/MeterGas.jsx'
import Customer from '../../views/Customer/Customer'
import Accepted from '../../views/Accepted/Accepted'
import Conditions from './ProtocolComponents/Condition'
import Indications from './ProtocolComponents/Indications.jsx'
import DescriptionType from './ProtocolComponents/DescriptionType'
import TotalAccepted from '../../views/TotalAccepted/TotalAccepted'
import VerificationMark from '../../views/VerificationMark/VerificationMark.jsx'
import ProtocolDate from '../../views/ProtocolDate/ProtocolDate'
import ImageUpload from '../../views/ImagesUploading/ImagesUploading.jsx'
import DataTransfer from '../../views/DataTransfer/DataTransfer.jsx'

import { changeProperty, setDefaultState } from './state.js'
import { resetCase, resetMeasurementId } from '../../../../../store/slices/app/views/protocols.js'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'
import {
  refreshReceiveds,
  refreshConditions,
  refreshIssueCase,
  refreshPersonalMetrolog,
  refreshTickets,
  refreshProtocols,
} from '../../../../../store/slices/app/controlers/updater.js'

import css from '../../styles.css'
const { PageContainer, BoxContainer } = css

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const capitalizeFirstLetter = input => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const token = useSelector(state => state.authReducer.authData.token)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const customerName = useSelector(state => state.gost83242002Reducer.customerName)
  const customerNameType = useSelector(state => state.gost83242002Reducer.customerNameType)
  const customerAddress = useSelector(state => state.gost83242002Reducer.customerAddress)
  const customerFullName = useSelector(state => state.gost83242002Reducer.customerFullName)
  const customerPhoneNumber = useSelector(state => state.gost83242002Reducer.customerPhoneNumber)
  const agreementNumber = useSelector(state => state.gost83242002Reducer.agreementNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterFifNum = useSelector(state => state.gost83242002Reducer.meterFifNum)
  const modifications = useSelector(state => state.gost83242002Reducer.modifications)
  const selectedMod = useSelector(state => state.gost83242002Reducer.selectedMod)
  const selectedAdditionals = useSelector(state => state.gost83242002Reducer.selectedAdditionals)
  const modification = useSelector(state => state.gost83242002Reducer.modification)
  const valueAdditional = useSelector(state => state.gost83242002Reducer.valueAdditional)
  const valueAnotherMod = useSelector(state => state.gost83242002Reducer.valueAnotherMod)
  const is_custom_modification = useSelector(
    state => state.gost83242002Reducer.is_custom_modification,
  )
  const mpi = useSelector(state => state.gost83242002Reducer.mpi)
  const meterFactoryNumber = useSelector(state => state.gost83242002Reducer.meterFactoryNumber)
  const meterYear = useSelector(state => state.gost83242002Reducer.meterYear)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const standartSize = useSelector(state => state.gost83242002Reducer.standartSize)
  const q_min = useSelector(state => state.gost83242002Reducer.q_min)
  const q_t = useSelector(state => state.gost83242002Reducer.q_t)
  const q_max = useSelector(state => state.gost83242002Reducer.q_max)
  const q_nom = useSelector(state => state.gost83242002Reducer.q_nom)
  const q_t_formula = useSelector(state => state.gost83242002Reducer.q_t_formula)
  const q_max_limit_negative = useSelector(state => state.gost83242002Reducer.q_max_limit_negative)
  const q_max_limit_positive = useSelector(state => state.gost83242002Reducer.q_max_limit_positive)
  const q_min_limit_negative = useSelector(state => state.gost83242002Reducer.q_min_limit_negative)
  const q_min_limit_positive = useSelector(state => state.gost83242002Reducer.q_min_limit_positive)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMechanicalAccepted = useSelector(state => state.gost83242002Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector(state => state.gost83242002Reducer.isTightnessAccepted)
  const indicationPass = useSelector(state => state.gost83242002Reducer.indicationPass)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMarkPassport = useSelector(state => state.gost83242002Reducer.isMarkPassport)
  const isMarkSi = useSelector(state => state.gost83242002Reducer.isMarkSi)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const nextVerificationDate = useSelector(state => state.gost83242002Reducer.nextVerificationDate)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isTotalAccepted = useSelector(state => state.gost83242002Reducer.isTotalAccepted)

  const correction = useSelector(state => state.gost83242002Reducer.correction)
  const pressureLoss = useSelector(state => state.gost83242002Reducer.pressureLoss)

  const conditions = useSelector(state => state.gost83242002Reducer.conditions)
  const indications = useSelector(state => state.gost83242002Reducer.indications)

  const doChecked = useSelector(state => state.gost83242002Reducer.doChecked)

  const acceptanceChecks = getAcceptanceChecks(isMechanicalAccepted, isTightnessAccepted, dispatch)

  const changeName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerName', label: newValue }))
  }

  const changeNameType = event => {
    dispatch(changeProperty({ value: 'customerNameType', label: event.target.value }))
    dispatch(changeProperty({ value: 'customerName', label: event.target.value }))
  }

  const changeAddress = selectedOption => {
    dispatch(
      changeProperty({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const changeFullName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerFullName', label: newValue }))
  }

  const changePhone = param => {
    const formattedPhone = formatPhoneNumber(param)
    dispatch(changeProperty({ value: 'customerPhoneNumber', label: formattedPhone }))
  }

  const handleAgreementChange = param => {
    dispatch(changeProperty({ value: 'agreementNumber', label: param }))
  }

  const handleMarkPassport = event => {
    dispatch(
      changeProperty({
        value: 'isMarkPassport',
        label: event.target.checked,
      }),
    )
  }

  const handleMarkSi = event => {
    dispatch(
      changeProperty({
        value: 'isMarkSi',
        label: event.target.checked,
      }),
    )
  }

  const handleNextDateChange = date => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'nextVerificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const nextDateAutoGenerate = useMemo(() => {
    if (dateVerification && mpi) {
      return dayjs(dateVerification)
        .add(mpi, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss')
    }
    return null
  }, [dateVerification, mpi])

  const handleDoChecked = event => {
    dispatch(
      changeProperty({
        value: 'doChecked',
        label: event.target.checked,
      }),
    )
  }

  useEffect(() => {
    const totalAccepted = isMechanicalAccepted && isTightnessAccepted && indicationPass

    dispatch(changeProperty({ value: 'isTotalAccepted', label: totalAccepted }))
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass, dispatch])

  const handleSave = async () => {
    try {
      setIsDisabledBtn(true)
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const data = {
        case_id: caseId,
        verification_date: dateVerification,
        next_verification_date: nextDateAutoGenerate || nextVerificationDate,
        mark_passport: isMarkPassport,
        mark_si: isMarkSi,
        meterable_fif_number: meterFifNum,
        customer_address: customerAddress,
        customer_fullname: customerFullName,
        customer_arshin_name: customerName,
        customer_phone: customerPhoneNumber,
        agreement_number: agreementNumber,
        total_accepted: isTotalAccepted,
        point_id: pointId,

        mechanical_accepted: isMechanicalAccepted,
        tightness_accepted: isTightnessAccepted,

        indications: indications,
        conditions: conditions,

        details: {
          meter_factory_number: meterFactoryNumber,
          meter_factory_year: meterYear,
          modification: modification === 'another' ? valueAnotherMod : modification,
          standart_size: standartSize,
          is_custom_modification,
          q_t: q_t,
          q_min: q_min,
          q_max: q_max,
          q_nom: q_nom ?? null,
          q_t_formula: q_t_formula,
          mpi: mpi,
          q_min_limit_positive: q_min_limit_positive,
          q_min_limit_negative: q_min_limit_negative,
          q_max_limit_positive: q_max_limit_positive,
          q_max_limit_negative: q_max_limit_negative,
          q_max_pressure_loss: pressureLoss,
          correction: correction,
        },
      }

      const request = {
        url: apiUrl,
        method: 'POST',
        payload: data,
        headers: headers,
      }

      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        if (!navigator.onLine) {
          navigator.serviceWorker.controller.postMessage({
            form_data: request,
          })
          navigator.serviceWorker.ready.then(function (registration) {
            return registration.sync.register('sendFormData')
          })
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(
            setMessage(
              'Протокол успешно создан. Данные будут отправлены на сервер, когда интернет появится.',
            ),
          )
          navigate('/metriva/protocols')
        } else {
          await api.post(apiUrl, data, { headers })
          dispatch(setMessage('Протокол успешно создан'))
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(refreshProtocols())
          dispatch(resetCase())
          dispatch(setDefaultState())
          dispatch(resetMeasurementId())
          navigate('/metriva/protocols')

          dispatch(refreshConditions())
          dispatch(refreshPersonalMetrolog())
          dispatch(refreshReceiveds())
          dispatch(refreshTickets())
          dispatch(refreshIssueCase())
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const serverErrors = error.response.data.errors
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage('Ошибка валидации. Проверьте, что вы заполнили все поля!'))
        setIsDisabledBtn(false)
        dispatch(changeProperty({ value: 'errors', label: serverErrors || {} }))
      }
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setDefaultState())
    }
  }, [])

  return (
    <PageContainer>
      <BoxContainer margin={'14px 0px 14px'}>
        <Customer
          valueName={customerName}
          changeName={changeName}
          valueNameType={customerNameType}
          changeNameType={changeNameType}
          valueAddress={customerAddress}
          changeAddress={changeAddress}
          valueFullName={customerFullName}
          changeFullName={changeFullName}
          valuePhone={customerPhoneNumber}
          changePhone={changePhone}
          changeAgreement={handleAgreementChange}
        />
      </BoxContainer>

      <BoxContainer>
        <MeterData
          meterFactoryNumber={meterFactoryNumber}
          meterYear={meterYear}
          meterFifNum={meterFifNum}
          modifications={modifications}
          selectedMod={selectedMod}
          selectedAdditionals={selectedAdditionals}
          modification={modification}
          valueAdditional={valueAdditional}
          valueAnotherMod={valueAnotherMod}
          is_custom_modification={is_custom_modification}
          changeMeter={changeProperty}
        />
      </BoxContainer>

      <BoxContainer>
        <DescriptionType />
      </BoxContainer>

      {acceptanceChecks.map((check, index) => {
        return (
          <BoxContainer key={index}>
            <Accepted
              title={check.title}
              description={check.description}
              label={check.label}
              value={check.value}
              onChange={check.onChange}
              notMatch={true}
            />
          </BoxContainer>
        )
      })}

      <BoxContainer>
        <Conditions />
      </BoxContainer>

      <BoxContainer>
        <Indications />
      </BoxContainer>

      <BoxContainer
        style={{
          backgroundColor: 'transparent',
          padding: '0px',
          border: 'none',
        }}
      >
        <TotalAccepted value={isTotalAccepted} />
      </BoxContainer>

      <BoxContainer>
        <VerificationMark
          isTotalAccepted={isTotalAccepted}
          valuePassport={isMarkPassport}
          onChangePassport={handleMarkPassport}
          valueSi={isMarkSi}
          onChangeSi={handleMarkSi}
        />
      </BoxContainer>

      <BoxContainer>
        <ProtocolDate
          currentDate={dateVerification}
          nextDate={nextVerificationDate}
          nextDateAutoGenerate={nextDateAutoGenerate}
          changeNextDate={handleNextDateChange}
        />
      </BoxContainer>

      {!isTrusted ? (
        <BoxContainer margin={'0px 0px 14px'}>
          <ImageUpload />
        </BoxContainer>
      ) : (
        <BoxContainer>
          <DataTransfer doChecked={doChecked} handleDoChecked={handleDoChecked} />
        </BoxContainer>
      )}

      <Button
        variant='contained'
        onClick={handleSave}
        disabled={isDisabledBtn}
        sx={{ mt: 1, mr: 1 }}
        style={{
          textTransform: 'none',
          fontSize: '15px',
          fontFamily: '"Wix Madefor Display", sans-serif',
          padding: '11px 20px 11px',
          margin: 0,
          borderRadius: '12px',
          width: '100%',
        }}
      >
        Сохранить протокол
      </Button>
    </PageContainer>
  )
}

export default Add
