export const formatPhoneNumber = input => {
  const phoneNumber = input.replace(/\D/g, '')
  let formattedPhoneNumber = ''

  if (phoneNumber.length >= 1) {
    formattedPhoneNumber = `+7`
  }
  if (phoneNumber.length > 1) {
    formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
  }
  if (phoneNumber.length > 4) {
    formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
  }
  if (phoneNumber.length > 7) {
    formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
  }
  if (phoneNumber.length > 9) {
    formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
  }

  return formattedPhoneNumber
}

export default formatPhoneNumber
