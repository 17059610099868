// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import OptionsInputAsync from '../../../LocalElements/Selector/reactSelect/OptionsInput.async.tsx'
import { Typography } from '@mui/material'
import Input from '../../../../../ui/input/index.tsx'
import RadioButton from '../../../../../ui/radio-button/index.tsx'
import { Wrapper, Container, LabelFrame33, OwnedSi, LabelFrame70 } from '../../styles.ts'
import { useDispatch } from 'react-redux'
import { setName } from '../../../../../store/slices/app/controlers/pageName.js'
import { toggleEnableStep } from '../../../../../store/slices/app/controlers/stepper.js'
import R from '../../../../../services/app/client-server/request.service'
import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'

const Customer = props => {
  const {
    valueName,
    changeName,
    valueNameType,
    changeNameType,
    valueFullName,
    changeFullName,
    valueAddress,
    changeAddress,
    valuePhone,
    changePhone,
    valueAgreement,
    changeAgreement,
  } = props

  const [tariffIds, setTariffIds] = useState([])
  const [regionValue, setRegionValue] = useState([])

  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchUserRegions = async () => {
      const { data } = await R.getUserRegions()
      setRegionValue(data.regions.map(region => region.id))

      const tariffs = data.tariffs.map(tariff => tariff.id)
      setTariffIds(tariffs.length === 0 ? null : tariffs)
    }
    fetchUserRegions()
  }, [])

  useEffect(() => {
    dispatch(setName('Информация о заказчике'))
  }, [dispatch])

  const fetchAddresses = useCallback(
    async param => {
      return R.addresses(
        param,
        regionValue.map(regionId => ({ kladr_id: regionId })),
      )
    },
    [regionValue],
  )

  const addressLabel = useMemo(
    () => ({
      text: 'Адрес проведения поверки',
      topBg: 'white',
      bottomBg: 'white',
    }),
    [],
  )

  const enableNextStep = useMemo(() => {
    if (valueName && valueNameType && valueAddress) return true
    else return false
  }, [valueName, valueNameType, valueAddress])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  return (
    <Wrapper style={width > 1050 ? { gap: '24px' } : { gap: '15px' }}>
      {width > 1050 && <Typography variant='h2'>Информация о заказчике</Typography>}
      <Container style={width > 1050 ? {} : { gap: '15px' }}>
        <OwnedSi>
          {width > 1050 && (
            <Input
              label={'Владелец СИ в системе Аршин'}
              type={'text'}
              placeholder={'Выберите тип лица или введите ФИО'}
              value={valueName}
              actions={{ change: changeName }}
            />
          )}
          {width <= 1050 && (
            <span style={{ display: 'block', marginBottom: '0px' }}>
              Владелец СИ в системе Аршиин
            </span>
          )}
          <RadioButton
            value={valueNameType}
            onChange={changeNameType}
            trueLabel={'Физическое лицо'}
            falseLabel={'Юридическое лицо'}
            trueValue={'Физическое лицо'}
            falseValue={'Юридическое лицо'}
          />
          {width <= 1050 && (
            <Input
              label={width > 1050 ? 'Владелец СИ в системе Аршин' : null}
              type={'text'}
              placeholder={'Выберите тип лица или введите ФИО'}
              value={valueName}
              actions={{ change: changeName }}
            />
          )}
        </OwnedSi>
        <LabelFrame70>
          {width <= 1050 && (
            <span style={{ display: 'block', marginBottom: '9px' }}>Адрес проведения поверки</span>
          )}
          <OptionsInputAsync
            callback={changeAddress}
            optionKey={'suggestions'}
            options={fetchAddresses}
            value={valueAddress}
            label={width > 1050 ? addressLabel : null}
            isRequired={true}
          />
        </LabelFrame70>
      </Container>

      {tariffIds && tariffIds.includes(6) && (
        <Container style={width > 1050 ? {} : { gap: '15px' }}>
          <LabelFrame33>
            {width <= 1050 && (
              <span style={{ display: 'block', marginBottom: '9px' }}>ФИО заказчика</span>
            )}
            <Input
              type={'text'}
              placeholder={'Введите ФИО'}
              label={width > 1050 ? 'ФИО заказчика (необязательно)' : null}
              notRequired={true}
              value={valueFullName}
              actions={{ change: changeFullName }}
            />
          </LabelFrame33>
          <LabelFrame33>
            {width <= 1050 && (
              <span style={{ display: 'block', marginBottom: '9px' }}>Телефон заказчика</span>
            )}
            <Input
              type={'text'}
              placeholder={'+7 (XXX) XXX-XX-XX'}
              label={width > 1050 ? 'Телефон заказчика (необязательно)' : null}
              notRequired={true}
              value={valuePhone}
              actions={{ change: changePhone }}
            />
          </LabelFrame33>
          <LabelFrame33>
            {width <= 1050 && (
              <span style={{ display: 'block', marginBottom: '9px' }}>Номер договора</span>
            )}
            <Input
              label={width > 1050 ? 'Номер договора (необязательно)' : null}
              placeholder={'Пример ввода - 00000-00'}
              type={'text'}
              notRequired={true}
              value={valueAgreement}
              actions={{ change: changeAgreement }}
            />
          </LabelFrame33>
        </Container>
      )}
    </Wrapper>
  )
}

export default Customer
