// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Box,
  CircularProgress,
  circularProgressClasses,
  Popover,
  Typography,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import useGeneratePDataOptical from '../../../../../../hooks/useGeneratePDataOpticalMi1592'
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions'
import { setShow } from '../../../../../../../store/slices/app/comps/feedback'
import Input from '../../../../../../../ui/input/index.tsx'
import AccordionComponent from '../../../../../LocalElements/Accordion/Accordion.jsx'

import R from '../../../../../../../services/app/client-server/request.service'
import { changeProperty } from '../../state'
import { setName } from '../../../../../../../store/slices/app/controlers/pageName.js'
import { toggleEnableStep } from '../../../../../../../store/slices/app/controlers/stepper.js'
import css from '../../style.css'

const {
  Container,
  IndicationBox,
  IndicationBoxLastElement,
  CountIndicationBox,
  QActualBox,
  InputQActualElement,
  ParagraphIndications,
  ConversionFactor,
} = css

const OpticalSensor = () => {
  const { generateValuesStartEnd, generateQactual, defaultValues } = useGeneratePDataOptical()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [expanded_1, setExpanded_1] = useState(true)
  const [expanded_2, setExpanded_2] = useState(false)
  const [expanded_3, setExpanded_3] = useState(false)

  const [allExpanded, setAllExpanded] = useState(false)

  const [isInputFocused, setIsInputFocused] = useState(false)
  const [tariffIds, setTariffIds] = useState([])
  const [startEndDataUpdate, setStartEndDataUpdate] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const id = open ? 'validate-check-popover' : undefined

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_actual = useSelector(state => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)
  const conversion_factor = useSelector(state => state.mi15922015Reducer.conversion_factor)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_ActualChange = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_actual', label: value }))
  }

  const handleConversionFactorChange = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(
      changeProperty({
        value: 'conversion_factor',
        label: value,
      }),
    )
  }

  const q_i_1 = useSelector(state => state.mi15922015Reducer.q_i_1)
  const q_i_2 = useSelector(state => state.mi15922015Reducer.q_i_2)
  const q_i_3 = useSelector(state => state.mi15922015Reducer.q_i_3)

  const v_y_i_1 = useSelector(state => state.mi15922015Reducer.v_y_i_1)
  const v_y_i_2 = useSelector(state => state.mi15922015Reducer.v_y_i_2)
  const v_y_i_3 = useSelector(state => state.mi15922015Reducer.v_y_i_3)

  const impulses_1 = useSelector(state => state.mi15922015Reducer.impulses_1)
  const impulses_2 = useSelector(state => state.mi15922015Reducer.impulses_2)
  const impulses_3 = useSelector(state => state.mi15922015Reducer.impulses_3)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const enableNextStep = useMemo(() => {
    if (
      q_i_1 &&
      q_i_2 &&
      q_i_3 &&
      v_y_i_1 &&
      v_y_i_2 &&
      v_y_i_3 &&
      impulses_1 &&
      impulses_2 &&
      impulses_3
    ) {
      return true
    } else {
      return false
    }
  }, [q_i_1, q_i_2, q_i_3, v_y_i_1, v_y_i_2, v_y_i_3, impulses_1, impulses_2, impulses_3])

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleQ_i_1Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_i_1', label: value }))
  }
  const handleQ_i_2Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_i_2', label: value }))
  }
  const handleQ_i_3Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_i_3', label: value }))
  }

  const handleImpulses_1Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'impulses_1', label: value }))
  }
  const handleImpulses_2Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'impulses_2', label: value }))
  }
  const handleImpulses_3Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'impulses_3', label: value }))
  }

  const handleV_y_i_1Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'v_y_i_1', label: value }))
  }
  const handleV_y_i_2Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'v_y_i_2', label: value }))
  }
  const handleV_y_i_3Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'v_y_i_3', label: value }))
  }

  const v_u_i_1 = parseFloat(conversion_factor) * parseFloat(impulses_1)
  const v_u_i_2 = parseFloat(conversion_factor) * parseFloat(impulses_2)
  const v_u_i_3 = parseFloat(conversion_factor) * parseFloat(impulses_3)

  const permissible_error_1 = (((parseFloat(v_u_i_1) - v_y_i_1) / v_y_i_1) * 100).toFixed(2)
  const permissible_error_2 = (((parseFloat(v_u_i_2) - v_y_i_2) / v_y_i_2) * 100).toFixed(2)
  const permissible_error_3 = (((parseFloat(v_u_i_3) - v_y_i_3) / v_y_i_3) * 100).toFixed(2)

  useEffect(() => {
    false && console.group()
    false && console.log('q_i_1 - ', q_i_1)
    false && console.log('impulse_1 - ', impulses_1)
    false && console.log('v_y_i_1 - ', v_y_i_1)
    false &&
      console.log(
        `v_u_i_1 - conversion_factor * impulses_1 = ${conversion_factor} * ${impulses_1} =`,
        conversion_factor * impulses_1,
      )
    false &&
      console.log(
        `относительная погрешность - ( v_u_i_1 - v_y_i_1 ) / v_y_i_1 * 100 = ( ${v_u_i_1} - ${v_y_i_1} ) / ${v_y_i_1} * 100 =`,
        ((v_u_i_1 - v_y_i_1) / v_y_i_1) * 100,
      )
    false && console.groupEnd()
  }, [q_i_1, impulses_1, v_y_i_1, conversion_factor, v_u_i_1])

  // ----------------------------------------------------------------
  // vui = conversion_factor * impulses
  // permissionError = ( vyi - vui ) / vui * 100
  // ----------------------------------------------------------------

  const indicationPass_1 =
    permissible_error_1 >= -parseFloat(q_min_limit) &&
    permissible_error_1 <= parseFloat(q_min_limit)

  const indicationPass_2 =
    permissible_error_2 >= -parseFloat(q_max_limit) &&
    permissible_error_2 <= parseFloat(q_max_limit)

  const indicationPass_3 =
    permissible_error_3 >= -parseFloat(q_max_limit) &&
    permissible_error_3 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indicationsPass',
        label: [indicationPass_1, indicationPass_2, indicationPass_3],
      }),
    )
  }, [indicationPass_1, indicationPass_2, indicationPass_3, dispatch])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indications',
        label: [
          {
            conversion_factor: conversion_factor,
            q_i: q_i_1,
            impulses: impulses_1,
            v_y_i: v_y_i_1,
            v_u_i: v_u_i_1,
            permissible_error: permissible_error_1,
          },
          {
            conversion_factor: conversion_factor,
            q_i: q_i_2,
            impulses: impulses_2,
            v_y_i: v_y_i_2,
            v_u_i: v_u_i_2,
            permissible_error: permissible_error_2,
          },
          {
            conversion_factor: conversion_factor,
            q_i: q_i_3,
            impulses: impulses_3,
            v_y_i: v_y_i_3,
            v_u_i: v_u_i_3,
            permissible_error: permissible_error_3,
          },
        ],
      }),
    )
  }, [
    conversion_factor,
    q_i_1,
    impulses_1,
    v_y_i_1,
    v_u_i_1,
    permissible_error_1,
    q_i_2,
    impulses_2,
    v_y_i_2,
    v_u_i_2,
    permissible_error_2,
    q_i_3,
    impulses_3,
    v_y_i_3,
    v_u_i_3,
    permissible_error_3,
    dispatch,
  ])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  useEffect(() => {
    dispatch(setName('Определение относительной погрешности'))
  }, [dispatch])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  useEffect(() => {
    if (allExpanded === false) {
      if (q_i_1 && impulses_1 && v_y_i_1) {
        setExpanded_1(false)
        setExpanded_2(true)
        setExpanded_3(false)
      }

      if (q_i_2 && impulses_2 && v_y_i_2) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(true)
      }
    }
  }, [
    q_i_1,
    impulses_1,
    v_y_i_1,
    q_i_2,
    impulses_2,
    v_y_i_2,
    q_i_3,
    impulses_3,
    v_y_i_3,
    allExpanded,
  ])

  useEffect(() => {
    if (allExpanded === true) {
      setExpanded_1(true)
      setExpanded_2(true)
      setExpanded_3(true)
    } else {
      setExpanded_1(true)
      setExpanded_2(false)
      setExpanded_3(false)
    }
  }, [allExpanded])

  return (
    <React.Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#132532',
            borderRadius: '12px',
            boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)',
            padding: '10px 40px 13px',
            marginTop: '-10px',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography
          sx={{
            p: 0,
            width: '100%',
            textAlign: 'center',
            lineHeight: '23px',
            fontSize: '15px',
            color: 'white',
          }}
        >
          Заполните коэффициент преобразования
        </Typography>
      </Popover>
      <div className='flexContainerWithGap' style={{ marginTop: '0px', marginBottom: '2px' }}>
        {tariffIds && tariffIds.includes(5) && (
          <div className='width50Percent'>
            <Container style={{ gap: '14px', flexFlow: 'row' }}>
              <div
                className='width50Percent'
                aria-describedby={id}
                onClick={event => {
                  !(q_min && q_t && q_min_limit && conversion_factor) && handleClickPopover(event)
                }}
              >
                {!!!startEndDataUpdate && (
                  <Button
                    disabled={q_min && q_t && q_min_limit && conversion_factor ? false : true}
                    onMouseDown={() => {
                      generateQactual()
                    }}
                    onClick={() => {
                      setStartEndDataUpdate(true)
                      dispatch(setShow(true))
                      setAllExpanded(true)

                      setTimeout(() => {
                        generateValuesStartEnd()
                        setStartEndDataUpdate(false)
                        dispatch(setShow(false))
                      }, 1100)
                    }}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '16px 24px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Тестовая среда
                  </Button>
                )}
                {startEndDataUpdate && (
                  <Button
                    disabled={true}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '16px 24px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <CircularProgress
                        variant='determinate'
                        sx={{
                          color: theme =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant='indeterminate'
                        disableShrink
                        sx={{
                          color: theme => (theme.palette.mode === 'light' ? 'grey' : 'grey'),
                          animationDuration: '550ms',
                          position: 'absolute',
                          opacity: 0.6,
                          left: 0,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                        }}
                        size={30}
                        thickness={4}
                      />
                    </Box>
                  </Button>
                )}
              </div>

              <div className='width50Percent'>
                <Button
                  disabled={q_min && q_t && q_min_limit && conversion_factor ? false : true}
                  onClick={() => {
                    setStartEndDataUpdate(true)
                    setAllExpanded(false)

                    setTimeout(() => {
                      defaultValues()
                      setStartEndDataUpdate(false)
                    }, 1100)
                  }}
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '16px 24px',
                    borderRadius: '12px',
                    marginRight: '0px',
                    width: '100%',
                  }}
                >
                  Сбросить все
                </Button>
              </div>
            </Container>
            <ParagraphIndications
              style={width <= 1050 ? { textAlign: 'center', width: '100%' } : {}}
            >
              Генерация данных приведена как пример в помощь поверителю. При создании протокола все
              данные необходимо скорректировать
            </ParagraphIndications>
          </div>
        )}
      </div>

      <ConversionFactor margin={'0 0 22px'}>
        <span style={{ marginBottom: '9px', display: 'block' }}>Коэффициент преобразования</span>
        <Input
          isDisabled={startEndDataUpdate}
          type={'text'}
          placeholder={'0.00000814'}
          value={conversion_factor}
          focused={isInputFocused}
          actions={{
            change: handleConversionFactorChange,
          }}
        />
      </ConversionFactor>

      <Typography variant='h2' style={{ marginBottom: '14px' }}>
        {q_min ? `Значение Qmin : ${q_min}, м³/ч` : 'Введите Qmin'}
      </Typography>

      <AccordionComponent
        isExpanded={expanded_1}
        title={`Первый замер`}
        titleSize={'17px'}
        titleMt={'4px'}
        iconSize={'large'}
        backgroundColor={'white'}
        padding={'0px 0px'}
        plSum={'8px'}
        prSum={'8px'}
        plDet={'8px'}
        prDet={'8px'}
        btlr={'12px'}
        btrr={'12px'}
        bblr={'12px'}
        bbrr={'12px'}
        dataType={'custom'}
        data={
          <Container>
            <IndicationBox>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'0.6830'}
                label={'Расход во время измерения, м³/ч'}
                value={q_i_1}
                actions={{
                  change: handleQ_i_1Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'2000'}
                label={'Количество импульсов, имп.'}
                value={impulses_1}
                actions={{
                  change: handleImpulses_1Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={false}
                type={'text'}
                placeholder={'0.024665'}
                label={'Объём воды, м³'}
                value={v_u_i_1 ? v_u_i_1.toFixed(4) : 'Автозаполнение'}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'0.015233'}
                label={'Объем, по показаниям эт., м³'}
                value={v_y_i_1}
                actions={{
                  change: handleV_y_i_1Change,
                }}
              />
            </IndicationBox>
          </Container>
        }
      />

      <React.Fragment>
        {q_i_1 && impulses_1 && v_y_i_1 && (
          <div
            className={`indications_box-gas_bottom ${
              indicationPass_1
                ? 'indications_box-gas_bottom_true'
                : 'indications_box-gas_bottom_false'
            }`}
            style={{ margin: '10px 0px 0px', width: '100%' }}
          >
            <p
              className={`indications_box-gas_bottom ${
                indicationPass_1 ? 'text_true' : 'text_false'
              }`}
              style={{ margin: '0 2px' }}
            >
              {width > 1050 ? 'Относительная погрешность счетчика:' : 'Относительная погрешность:'}
              <span style={{ margin: '0 4px' }}>{permissible_error_1} %</span>
            </p>
            <p
              className={`indications_box-gas_bottom ${
                indicationPass_1 ? 'text_true' : 'text_false'
              }`}
              style={{ margin: '0 2px' }}
            >
              {width > 1050 ? 'Пределы допускаемой погрешности счётчика:' : 'Пределы погрешности:'}
              <span style={{ margin: '0 4px' }}>±{q_min_limit} %</span>
            </p>
          </div>
        )}
      </React.Fragment>

      <React.Fragment>
        <Typography variant='h2' style={{ marginBottom: '14px', marginTop: '16px' }}>
          {q_t ? `Значение 1.1 * Qt : ${(1.1 * q_t).toFixed(3)}, м³/ч` : 'Введите Qt'}
        </Typography>

        <AccordionComponent
          isExpanded={expanded_2}
          title={`Второй замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          btlr={'12px'}
          btrr={'12px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  type={'text'}
                  placeholder={'0.6830'}
                  label={'Расход во время измерения, м³/ч'}
                  value={q_i_2}
                  actions={{
                    change: handleQ_i_2Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  type={'text'}
                  placeholder={'2000'}
                  label={'Количество импульсов, имп.'}
                  value={impulses_2}
                  actions={{
                    change: handleImpulses_2Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={false}
                  type={'text'}
                  placeholder={'0.024665'}
                  label={'Объём воды, м³'}
                  value={v_u_i_2 ? v_u_i_2.toFixed(4) : 'Автозаполнение'}
                />
              </IndicationBox>

              <IndicationBox margin={'0'}>
                <Input
                  isDisabled={startEndDataUpdate}
                  type={'text'}
                  placeholder={'0.016467'}
                  label={'Объем, по показаниям эт., м³'}
                  value={v_y_i_2}
                  actions={{
                    change: handleV_y_i_2Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />

        <React.Fragment>
          {q_i_2 && impulses_2 && v_y_i_2 && (
            <div className='testimony__box-items-bottom'>
              <div
                className={`indications_box-gas_bottom ${
                  indicationPass_2
                    ? 'indications_box-gas_bottom_true'
                    : 'indications_box-gas_bottom_false'
                }`}
                style={{ margin: '10px 0px 0px', width: '100%' }}
              >
                <p
                  className={`indications_box-gas_bottom ${
                    indicationPass_2 ? 'text_true' : 'text_false'
                  }`}
                  style={{ margin: '0 2px' }}
                >
                  {width > 1050
                    ? 'Относительная погрешность счетчика:'
                    : 'Относительная погрешность:'}
                  <span style={{ margin: '0 4px' }}>{permissible_error_2} %</span>
                </p>
                <p
                  className={`indications_box-gas_bottom ${
                    indicationPass_2 ? 'text_true' : 'text_false'
                  }`}
                  style={{ margin: '0 2px' }}
                >
                  {width > 1050
                    ? 'Пределы допускаемой погрешности счётчика:'
                    : 'Пределы погрешности:'}
                  <span style={{ margin: '0 4px' }}>±{q_max_limit} %</span>
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>

      <React.Fragment>
        <Typography variant='h2' style={{ marginBottom: '14px', marginTop: '16px' }}>
          {'Значение Qmax факт.'}
        </Typography>
        <QActualBox style={{ margin: 0, padding: 0, marginBottom: '16px' }}>
          <InputQActualElement style={{ margin: 0 }}>
            <Input
              isDisabled={startEndDataUpdate}
              type={'text'}
              placeholder={'Qmax фактический'}
              value={q_actual}
              actions={{
                change: handleQ_ActualChange,
              }}
              className={'backgroundColorWhite'}
            />
          </InputQActualElement>
        </QActualBox>

        <AccordionComponent
          isExpanded={expanded_3}
          title={`Третий замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          btlr={'12px'}
          btrr={'12px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  type={'text'}
                  placeholder={'0.6830'}
                  label={'Расход во время измерения, м³/ч'}
                  value={q_i_3}
                  actions={{
                    change: handleQ_i_3Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  type={'text'}
                  placeholder={'2000'}
                  label={'Количество импульсов, имп.'}
                  value={impulses_3}
                  actions={{
                    change: handleImpulses_3Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={false}
                  type={'text'}
                  placeholder={'0.024665'}
                  label={'Объём воды, м³'}
                  value={v_u_i_3 ? v_u_i_3.toFixed(4) : 'Автозаполнение'}
                />
              </IndicationBox>

              <IndicationBoxLastElement>
                <Input
                  isDisabled={startEndDataUpdate}
                  type={'text'}
                  placeholder={'0.024765'}
                  label={'Объем, по показаниям эт., м³'}
                  value={v_y_i_3}
                  actions={{
                    change: handleV_y_i_3Change,
                  }}
                />
              </IndicationBoxLastElement>
            </Container>
          }
        />

        <React.Fragment>
          {q_i_3 && impulses_3 && v_y_i_3 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_3
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px 0px', width: '100%' }}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                {width > 1050
                  ? 'Относительная погрешность счетчика:'
                  : 'Относительная погрешность:'}
                <span style={{ margin: '0 4px' }}>{permissible_error_3} %</span>
              </p>

              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                {width > 1050
                  ? 'Пределы допускаемой погрешности счётчика:'
                  : 'Пределы погрешности:'}
                <span style={{ margin: '0 4px' }}>±{q_max_limit} %</span>
              </p>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  )
}

export default OpticalSensor
