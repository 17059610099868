import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  images: [],
}

const mi15922015ReducerImages = createSlice({
  name: 'mi15922015ReducerImages',
  initialState,
  reducers: {
    setImages: (state, action) => {
      state.images = action.payload
    },
    addImages: (state, action) => {
      state.images.push(...action.payload)
    },
    removeImage: (state, action) => {
      state.images = state.images.filter(img => img.id !== action.payload)
    },
  },
})

export const { setImages, addImages, removeImage } = mi15922015ReducerImages.actions
export default mi15922015ReducerImages.reducer
