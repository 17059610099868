// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import formatPhoneNumber from '../../../../../../utils/formatPhoneNumber.js'
import { getProtocolData } from '../getProtocolData.js'
import { getAcceptanceChecks } from '../acceptanceChecks'
import useNextVerificationDate from '../getNextVerificationDate.js'
import { setShow } from '../../../../../../store/slices/app/comps/feedback.js'
import SkeletonProtocol from '../../../../LocalElements/Skeleton/SkeletonProtocol.jsx'

import MeterData from '../../../views/MeterData/MeterWater/MeterData.jsx'
import DescriptionType from '../components/DescriptionType.jsx'
import Customer from '../../../views/Customer/Customer.jsx'
import Accepted from '../../../views/Accepted/Accepted.jsx'
import Conditions from '../components/ConditionsEdit.jsx'
import Indications from '../components/IndicationsEdit.jsx'
import OpticalSensor from '../components/OpticalSensorEdit.jsx'
import TotalAccepted from '../../../views/TotalAccepted/TotalAccepted.jsx'
import VerificationMark from '../../../views/VerificationMark/VerificationMark.jsx'
import ProtocolDate from '../../../views/ProtocolDate/ProtocolDate.jsx'
import ImageUpload from '../../../views/ImagesUploading/ImagesUploading.jsx'
import DataTransfer from '../../../views/DataTransfer/DataTransfer.jsx'

import Button from '@mui/material/Button'
import R from '../../../../../../services/app/client-server/request.service.js'

import { changeProperty, setDefaultState } from '../state.js'
import { changeCase } from '../../../../../../store/slices/app/views/protocols.js'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../../store/slices/app/comps/snackbar.js'
import {
  refreshProtocols,
  refreshConditions,
  refreshIssueCase,
  refreshPersonalMetrolog,
  refreshReceiveds,
  refreshTickets,
} from '../../../../../../store/slices/app/controlers/updater.js'

import { setMeasurementId } from '../../../../../../store/slices/app/views/protocols.js'

import css from '../../../styles.css.js'
const { PageContainer, BoxContainer } = css

const Edit = () => {
  const { protocol_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [data, setData] = useState([])
  const [details, setDetails] = useState('')

  const feedback = useSelector(state => state.feedbackReducer.show)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const verificationType = useSelector(state => state.protocolReducer.case.verificationType)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterId = useSelector(state => state.mi15922015Reducer.meterId)
  const meterViewStatus = useSelector(state => state.mi15922015Reducer.meterViewStatus)
  const modifications = useSelector(state => state.mi15922015Reducer.modifications)
  const selectedMod = useSelector(state => state.mi15922015Reducer.selectedMod)
  const selectedAdditionals = useSelector(state => state.mi15922015Reducer.selectedAdditionals)
  const defaultData = useSelector(state => state.mi15922015Reducer.defaultData)
  const mpiColdAdditionals = useSelector(state => state.mi15922015Reducer.mpiColdAdditionals)
  const mpiHotAdditionals = useSelector(state => state.mi15922015Reducer.mpiHotAdditionals)
  const valueMod = useSelector(state => state.mi15922015Reducer.valueMod)
  const valueAdditional = useSelector(state => state.mi15922015Reducer.valueAdditional)
  const valueAnotherMod = useSelector(state => state.mi15922015Reducer.valueAnotherMod)
  const is_custom_modification = useSelector(
    state => state.mi15922015Reducer.is_custom_modification,
  )
  const meterFactoryNumber = useSelector(state => state.mi15922015Reducer.meterFactoryNumber)
  const meterYear = useSelector(state => state.mi15922015Reducer.meterYear)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const customerName = useSelector(state => state.mi15922015Reducer.customerName)
  const customerNameType = useSelector(state => state.mi15922015Reducer.customerNameType)
  const customerAddress = useSelector(state => state.mi15922015Reducer.customerAddress)
  const customerFullName = useSelector(state => state.mi15922015Reducer.customerFullName)
  const customerPhoneNumber = useSelector(state => state.mi15922015Reducer.customerPhoneNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const diameter = useSelector(state => state.mi15922015Reducer.diameter)
  const meterLocation = useSelector(state => state.mi15922015Reducer.meterLocation)
  const meterType = useSelector(state => state.mi15922015Reducer.meterType)
  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_max = useSelector(state => state.mi15922015Reducer.q_max)
  const q_actual = useSelector(state => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMechanicalAccepted = useSelector(state => state.mi15922015Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector(state => state.mi15922015Reducer.isTightnessAccepted)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMarkPassport = useSelector(state => state.mi15922015Reducer.isMarkPassport)
  const isMarkSi = useSelector(state => state.mi15922015Reducer.isMarkSi)
  const nextVerificationDate = useSelector(state => state.mi15922015Reducer.nextVerificationDate)
  const nextVerificationDateAutoGenerate = useSelector(
    state => state.mi15922015Reducer.nextVerificationDateAutoGenerate,
  )
  const agreementNumber = useSelector(state => state.mi15922015Reducer.agreementNumber)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const comment = useSelector(state => state.mi15922015Reducer.comment)
  const file = useSelector(state => state.mi15922015Reducer.file)
  const doChecked = useSelector(state => state.mi15922015Reducer.doChecked)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isTotalAccepted = useSelector(state => state.mi15922015Reducer.isTotalAccepted)
  const indicationPass = useSelector(state => state.mi15922015Reducer.indicationsPass)
  const indications = useSelector(state => state.mi15922015Reducer.indications)
  const conditions = useSelector(state => state.mi15922015Reducer.conditionsValuesEdit)

  const acceptanceChecks = getAcceptanceChecks(isMechanicalAccepted, isTightnessAccepted, dispatch)

  useNextVerificationDate({
    defaultData,
    meterType,
    dateVerification,
    mpiColdAdditionals,
    mpiHotAdditionals,
  })

  const capitalizeFirstLetter = input => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const changeName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerName', label: newValue }))
  }

  const changeNameType = event => {
    dispatch(changeProperty({ value: 'customerNameType', label: event.target.value }))
    dispatch(changeProperty({ value: 'customerName', label: event.target.value }))
  }

  const changeAddress = selectedOption => {
    dispatch(
      changeProperty({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const changeFullName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerFullName', label: newValue }))
  }

  const changePhone = param => {
    const formattedPhone = formatPhoneNumber(param)
    dispatch(changeProperty({ value: 'customerPhoneNumber', label: formattedPhone }))
  }

  const handleMarkPassport = event => {
    dispatch(
      changeProperty({
        value: 'isMarkPassport',
        label: event.target.checked,
      }),
    )
  }

  const handleMarkSi = event => {
    dispatch(
      changeProperty({
        value: 'isMarkSi',
        label: event.target.checked,
      }),
    )
  }

  const handleNextDateChange = date => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'nextVerificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const handleDoChecked = event => {
    dispatch(
      changeProperty({
        value: 'doChecked',
        label: event.target.checked,
      }),
    )
  }

  const handleAgreementChange = param => {
    dispatch(changeProperty({ value: 'agreementNumber', label: param }))
  }

  useEffect(() => {
    const totalAccepted =
      isMechanicalAccepted && isTightnessAccepted && !indicationPass.includes(false)

    dispatch(changeProperty({ value: 'isTotalAccepted', label: totalAccepted }))
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))

      const { data } = await R.getProtocol(protocol_id, () => dispatch(setShow(false)))

      setData(data.data)

      setDetails(data.data?.details?.id)

      dispatch(setMeasurementId(data.data?.measurement?.id))

      dispatch(
        changeCase({
          value: 'verificationType',
          label: data.data?.verificationType?.id,
        }),
      )
      dispatch(
        changeCase({
          value: 'opticalSensor',
          label: data.data?.point?.contains_optical_sensor,
        }),
      )
      dispatch(
        changeProperty({
          value: 'customerName',
          label: data.data?.customer_arshin_name,
        }),
      )

      dispatch(
        changeProperty({
          value: 'customerAddress',
          label: data.data?.customer_address,
        }),
      )

      dispatch(
        changeProperty({
          value: 'customerFullName',
          label: data.data?.customer_fullname || null,
        }),
      )
      dispatch(
        changeProperty({
          value: 'customerPhoneNumber',
          label: data.data?.customer_phone || null,
        }),
      )
      dispatch(
        changeProperty({
          value: 'isMechanicalAccepted',
          label: data.data?.mechanical_accepted,
        }),
      )
      dispatch(
        changeProperty({
          value: 'isTightnessAccepted',
          label: data.data?.tightness_accepted,
        }),
      )
      dispatch(
        changeProperty({
          value: 'isMarkPassport',
          label: data.data?.mark_passport,
        }),
      )
      dispatch(
        changeProperty({
          value: 'isMarkSi',
          label: data.data?.mark_si,
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterId',
          label: data.data?.meterable_fif_number,
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterViewStatus',
          label: data.data?.details?.meter_view_status,
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterLocation',
          label: data.data.details?.orientation,
        }),
      )
      dispatch(
        changeProperty({
          value: 'meterType',
          label: data.data.details?.meter_type,
        }),
      )
      dispatch(
        changeProperty({
          value: 'diameter',
          label: data.data.details?.diameter,
        }),
      )

      dispatch(
        changeProperty({
          value: 'q_min',
          label: data.data.details?.q_min,
        }),
      )
      dispatch(
        changeProperty({
          value: 'q_t',
          label: data.data.details?.q_t,
        }),
      )
      dispatch(
        changeProperty({
          value: 'q_max',
          label: data.data.details?.q_max,
        }),
      )
      dispatch(
        changeProperty({
          value: 'q_min_limit',
          label: data.data.details?.q_min_limit,
        }),
      )
      dispatch(
        changeProperty({
          value: 'q_max_limit',
          label: data.data.details?.q_max_limit,
        }),
      )

      dispatch(
        changeProperty({
          value: 'additionalOption',
          label: data.data?.details?.additional_option,
        }),
      )
      dispatch(
        changeProperty({
          value: 'meterYear',
          label: String(data.data.details.meter_factory_year),
        }),
      )
      dispatch(
        changeProperty({
          value: 'meterFactoryNumber',
          label: data.data.details.meter_factory_number,
        }),
      )
      dispatch(
        changeProperty({
          value: 'q_actual',
          label: data.data.details.q_actual,
        }),
      )
      dispatch(
        changeProperty({
          value: 'agreementNumber',
          label: data.data.agreement_number,
        }),
      )

      dispatch(
        changeProperty({
          value: 'conditionsValuesEdit',
          label: data.data.protocolCondition.map(item => ({
            id: item.id,
            atmospheric_pressure: item.atmospheric_pressure,
            relative_humidity: item.relative_humidity,
            temperature_environment: item.temperature_environment,
            water_temp: item.water_temp,
          })),
        }),
      )

      dispatch(
        changeProperty({
          value: 'opticalSensorValuesEdit',
          label: data.data.protocolIndication.map(item => ({
            id: item.id,
            conversion_factor: item.conversion_factor,
            q_i: item.q_i,
            impulses: item.impulses,
            v_y_i: item.v_y_i,
          })),
        }),
      )
      dispatch(
        changeProperty({
          value: 'indicationsValuesEdit',
          label: data.data.protocolIndication.map(item => ({
            id: item.id,
            start_1: item.start_1,
            start_2: item.start_2,
            start_3: item.start_3,
            finish_1: item.finish_1,
            finish_2: item.finish_2,
            finish_3: item.finish_3,
            q_i_1: item.q_i_1,
            q_i_2: item.q_i_2,
            q_i_3: item.q_i_3,
            v_y_i_1: item.v_y_i_1,
            v_y_i_2: item.v_y_i_2,
            v_y_i_3: item.v_y_i_3,
          })),
        }),
      )

      dispatch(
        changeProperty({
          value: 'nextVerificationDate',
          label: data.data.next_verification_date,
        }),
      )
      dispatch(
        changeProperty({
          value: 'comment',
          label: data.data?.comment_files,
        }),
      )
      dispatch(
        changeProperty({
          value: 'fileBase64',
          label: data.data?.files.map((item, index) => ({
            id: item.id,
            serial: index,
          })),
        }),
      )
      dispatch(
        changeProperty({
          value: 'imgBase64',
          label: data.data?.files.map(item => ({
            path: item.path,
          })),
        }),
      )

      dispatch(
        changeProperty({
          value: 'valueMod',
          label: data.data?.details?.modification,
        }),
      )
      dispatch(
        changeProperty({
          value: 'valueAdditional',
          label: data.data?.details?.additional_option,
        }),
      )
      dispatch(
        changeProperty({
          value: 'valueAnotherMod',
          label: data.data?.details?.modification,
        }),
      )
      dispatch(
        changeProperty({
          value: 'is_custom_modification',
          label: data.data?.details?.is_custom_modification,
        }),
      )

      dispatch(setShow(false))
    })()
  }, [protocol_id, dispatch])

  useEffect(() => {
    if (meterViewStatus !== 'default') {
      dispatch(changeProperty({ value: 'valueMod', label: data?.details?.modification }))
      dispatch(changeProperty({ value: 'diameter', label: data.details?.diameter }))
      dispatch(changeProperty({ value: 'meterLocation', label: data.details?.orientation }))
      dispatch(changeProperty({ value: 'meterType', label: data.details?.meter_type }))

      dispatch(changeProperty({ value: 'q_t', label: parseFloat(data.details?.q_t).toFixed(2) }))
      dispatch(
        changeProperty({ value: 'q_min', label: parseFloat(data.details?.q_min).toFixed(2) }),
      )
      dispatch(
        changeProperty({ value: 'q_max', label: parseFloat(data.details?.q_max).toFixed(2) }),
      )
      dispatch(changeProperty({ value: 'q_min_limit', label: data.details?.q_min_limit }))
      dispatch(changeProperty({ value: 'q_max_limit', label: data.details?.q_max_limit }))
    }
  }, [meterViewStatus, data, dispatch])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = getProtocolData({
      caseId,
      verificationType,
      pointId,
      customerName,
      customerAddress,
      customerFullName,
      customerPhoneNumber,
      isMechanicalAccepted,
      isTightnessAccepted,
      isMarkPassport,
      isMarkSi,
      isTotalAccepted,
      indications,
      conditions,
      meterId,
      meterViewStatus,
      valueMod,
      valueAnotherMod,
      valueAdditional,
      meterFactoryNumber,
      meterYear,
      is_custom_modification,
      diameter,
      meterLocation,
      meterType,
      q_min,
      q_t,
      q_max,
      q_actual,
      q_min_limit,
      q_max_limit,
      defaultData,
      dateVerification,
      nextVerificationDate,
      nextVerificationDateAutoGenerate,
      agreementNumber,
      comment,
      file,
      doChecked,
      details,
    })

    const { status } = await R.editProtocol(protocol_id, data)

    if (status === 200) {
      dispatch(setMessage('Протокол успешно отредактирован'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))

      dispatch(refreshProtocols())
      dispatch(refreshConditions())
      dispatch(refreshIssueCase())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      navigate('/metriva/protocols')
    } else if (status === 422) {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Протокол не отредактирован - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Произошла ошибка обращения к API'))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setDefaultState())
    }
  }, [])

  return (
    <PageContainer>
      {feedback ? (
        <SkeletonProtocol />
      ) : (
        <React.Fragment>
          <React.Fragment>
            <BoxContainer margin={'0 0 14px'}>
              <Customer
                valueName={customerName}
                changeName={changeName}
                valueNameType={customerNameType}
                changeNameType={changeNameType}
                valueAddress={customerAddress}
                changeAddress={changeAddress}
                valueFullName={customerFullName}
                changeFullName={changeFullName}
                valuePhone={customerPhoneNumber}
                changePhone={changePhone}
                changeAgreement={handleAgreementChange}
                valueAgreement={agreementNumber}
              />
            </BoxContainer>

            <BoxContainer>
              <MeterData
                meterFactoryNumber={meterFactoryNumber}
                meterYear={meterYear}
                meterId={meterId}
                modifications={modifications}
                selectedMod={selectedMod}
                selectedAdditionals={selectedAdditionals}
                valueMod={valueMod}
                valueAdditional={valueAdditional}
                valueAnotherMod={valueAnotherMod}
                is_custom_modification={is_custom_modification}
                changeMeter={changeProperty}
                defaultData={defaultData}
                edit={true}
                conditions={conditions}
                meterViewStatus={meterViewStatus}
              />
            </BoxContainer>

            <BoxContainer>
              <DescriptionType edit={true} meterViewStatus={meterViewStatus} />
            </BoxContainer>

            {acceptanceChecks.map((check, index) => (
              <BoxContainer key={index}>
                <Accepted
                  title={check.title}
                  description={check.description}
                  label={check.label}
                  value={check.value}
                  onChange={check.onChange}
                  notMatch={true}
                />
              </BoxContainer>
            ))}

            <BoxContainer>
              <Conditions />
            </BoxContainer>

            <BoxContainer>{pointId === 3 ? <OpticalSensor /> : <Indications />}</BoxContainer>

            <BoxContainer
              style={{
                backgroundColor: 'transparent',
                padding: '0px',
                border: 'none',
              }}
            >
              <TotalAccepted value={isTotalAccepted} />
            </BoxContainer>

            <BoxContainer>
              <VerificationMark
                isTotalAccepted={isTotalAccepted}
                valuePassport={isMarkPassport}
                onChangePassport={handleMarkPassport}
                valueSi={isMarkSi}
                onChangeSi={handleMarkSi}
              />
            </BoxContainer>

            <BoxContainer>
              <ProtocolDate
                currentDate={dateVerification}
                nextDate={nextVerificationDate}
                nextDateAutoGenerate={nextVerificationDateAutoGenerate}
                changeNextDate={handleNextDateChange}
              />
            </BoxContainer>

            <BoxContainer margin={'0px 0px 14px'}>
              <ImageUpload />
            </BoxContainer>

            {isTrusted && (
              <BoxContainer>
                <DataTransfer doChecked={doChecked} handleDoChecked={handleDoChecked} />
              </BoxContainer>
            )}
          </React.Fragment>

          <Button
            onClick={handleSave}
            variant='contained'
            disabled={isDisabledBtn}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
            }}
          >
            Редактировать протокол
          </Button>
        </React.Fragment>
      )}
    </PageContainer>
  )
}

export default Edit
