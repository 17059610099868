import React, { useState, useEffect, FC } from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import SelectorComponent from '../../../components/LocalElements/Selector/reactSelect/Selector.jsx'
import { Container, InfoBlock } from './styles.ts'
import theme from '../../../../utils/theme.ts'
import { IMeterData, IModification } from '../../../types.models.views.ts'

interface MpiProps {
  data: IMeterData
  modification: IModification | null
}

type AdditionalColdHot = { mpiCold: number; mpiHot: number }
type AdditionalMpi = { mpi: number }

type AdditionalOption = {
  value: AdditionalColdHot | AdditionalMpi
  label: string
}

type SelectorOption = {
  label: string
  value: any
}

interface SelectorProps {
  placeholder?: string
  isClearable?: boolean
  isCreatable?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  onChange: (value: any) => void
  value: any
  options: SelectorOption[]
  noOptionsMessage?: () => string
  defaultValue?: SelectorOption | null
  isDisabled?: boolean
  onInputChange?: (inputValue: string) => void
  label?: {
    topBg: string
    bottomBg: string
    text: string
  }
  isRequired?: boolean
}

const Selector = SelectorComponent as FC<SelectorProps>

const Mpi: FC<MpiProps> = ({ data, modification }) => {
  const measurement_id = useSelector((state: any) => state.meterReducer.measurement_id)
  const [additionalValue, setAdditionalValue] = useState<AdditionalOption | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const hasAdditionals = !!(modification && modification.additionals.length)

  useEffect(() => {
    setAdditionalValue(null)
  }, [modification])

  const formatMpi = (value: number | string | null | undefined): string => {
    const months = parseInt(String(value), 10)
    if (isNaN(months) || months <= 0) return 'Отсутствует'

    const years = Math.floor(months / 12)
    const restMonths = months % 12

    const yearStr = years > 0 ? `${years} ${years === 1 ? 'год' : years < 5 ? 'года' : 'лет'}` : ''
    const monthStr =
      restMonths > 0
        ? `${restMonths} ${restMonths === 1 ? 'месяц' : restMonths < 5 ? 'месяца' : 'месяцев'}`
        : ''

    return [yearStr, monthStr].filter(Boolean).join(' ')
  }

  const getMpiValueWater = (
    additional: number | null | undefined,
    original: number | undefined,
  ) => {
    if (additional !== undefined) {
      return additional === null ? 'Отсутствует' : formatMpi(additional)
    }
    return formatMpi(original)
  }

  const mpiList =
    measurement_id === 1
      ? [
          {
            id: 'cold',
            label: 'МПИ - холодная вода :',
            value:
              additionalValue && 'mpiCold' in additionalValue.value
                ? getMpiValueWater(additionalValue.value.mpiCold, data?.mpi_cold)
                : getMpiValueWater(undefined, data?.mpi_cold),
          },
          {
            id: 'hot',
            label: 'МПИ - горячая вода :',
            value:
              additionalValue && 'mpiHot' in additionalValue.value
                ? getMpiValueWater(additionalValue.value.mpiHot, data?.mpi_hot)
                : getMpiValueWater(undefined, data?.mpi_hot),
          },
        ]
      : [
          {
            id: 'mpi',
            label: 'Межповерочный интервал :',
            value:
              additionalValue && 'mpi' in additionalValue.value
                ? formatMpi(additionalValue.value.mpi)
                : formatMpi(data?.mpi),
          },
        ]

  const selectorOptions: AdditionalOption[] =
    modification?.additionals.map(additional => ({
      value:
        measurement_id === 1
          ? {
              mpiCold: additional.mpi_cold,
              mpiHot: additional.mpi_hot,
            }
          : {
              mpi: additional.mpi,
            },
      label: additional.text,
    })) || []

  return (
    <Container>
      <InfoBlock>
        {mpiList.map(item => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }} key={item.id}>
            <Typography variant='subtitle2'>{item.label}</Typography>
            <Typography variant='subtitle1'>{item.value}</Typography>
          </div>
        ))}
      </InfoBlock>

      <div style={{ width: isMobile ? '100%' : '400px' }}>
        <Selector
          placeholder={hasAdditionals ? 'Есть дополнительные условия' : 'Дополнит. условий нет'}
          options={selectorOptions}
          value={additionalValue}
          onChange={(inputValue: AdditionalOption | null) => {
            setAdditionalValue(inputValue)
          }}
          isDisabled={!hasAdditionals}
          isClearable={true}
          isSearchable={true}
          isRequired={false}
        />
      </div>
    </Container>
  )
}

export default Mpi
