import React from 'react'
import { Typography } from '@mui/material'

export const MeasurementResults = ({
  volumeAirMeter,
  permissibleError,
  isRelativeErrorInRange,
  q_min_limit_negative,
  q_min_limit_positive,
  visibleText,
}) => {
  return (
    <div
      style={{
        background: isRelativeErrorInRange ? '#E5F3FC' : '#FFEEEF',
        padding: '20px',
        borderRadius: '16px',
      }}
    >
      <Typography variant='subtitle1'>
        Объём воздуха по показаниям счётчика:
        <span style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', marginLeft: '4px' }}>
          {volumeAirMeter} м³/ч
        </span>
      </Typography>
      <Typography variant='subtitle1'>
        Относительная погрешность счётчика:
        <span style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', marginLeft: '4px' }}>
          {permissibleError} %
        </span>
      </Typography>
      {visibleText && (
        <Typography variant='subtitle1'>
          Пределы допускаемой погрешности счётчика от
          <span style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', margin: '0 4px' }}>
            {q_min_limit_negative}%
          </span>
          до
          <span
            style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', marginLeft: '4px' }}
          >
            {q_min_limit_positive}%
          </span>
        </Typography>
      )}
    </div>
  )
}
