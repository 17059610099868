import React from 'react'
import { Typography } from '@mui/material'

export const AverageValue = ({
  averageError,
  isRelativeErrorInRange,
  q_limit_negative,
  q_limit_positive,
}) => {
  return (
    <div
      style={{
        background: isRelativeErrorInRange ? '#E5F3FC' : '#EAEDF3',
        padding: '20px',
        borderRadius: '16px',
      }}
    >
      <Typography variant='subtitle1'>
        Среднее арифметическое значение относительной погрешности счётчика:
        <span style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', marginLeft: '4px' }}>
          {averageError} %
        </span>
      </Typography>

      <Typography variant='subtitle1'>
        Пределы допускаемой погрешности счётчика от
        <span style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', margin: '0 4px' }}>
          {q_limit_negative}%
        </span>
        до
        <span style={{ color: isRelativeErrorInRange ? '#0084E2' : '#FF5963', marginLeft: '4px' }}>
          {q_limit_positive}%
        </span>
      </Typography>
    </div>
  )
}
