import styled from 'styled-components'

interface IContentRowProps {
  readonly axios?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around'
  readonly gap?: number
  readonly mb?: number
}

const css = {
  Container: styled.section`
    display: flex;
    flex-flow: column;
    position: relative;
    border-radius: 12px;
    border: 1px solid #d7ddea;
    min-height: 100px;
    width: 100%;
    padding: 22px;
    margin-top: 12px;
    gap: 12px;
  `,
  ContentRow: styled.div<IContentRowProps>`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: ${props => (props.axios ? props.axios : 'flex-start')};
    gap: ${props => (props.gap ? props.gap + 'px' : '0px')};
    margin-bottom: ${props => (props.mb ? props.mb + 'px' : '0px')};
    position: relative;
    width: 100%;
    line-height: 22px;
  `,
  MobileHeader: styled.section`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fc;
    padding: 0px 12px;
    height: 62px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    width: 100%;
  `,
  MobileHeaderIcon: styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 44px;
    height: 44px;
  `,
  MobileHeaderTitle: styled.h1`
    display: block;
    position: relative;
    font-size: 18px;
    text-align: center;
  `,
  Etalons: {
    TabsContainer: styled.div`
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 0px;
      padding: 0px 20px;
      margin-top: 14px;
    `,
    TabsContainerButton: styled.span<{ active: boolean }>`
      display: block;
      position: relative;
      width: 20%;
      height: 48px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      border: 1px solid #d7ddea;
      background-color: ${props => (props.active ? '#0084E2' : '#EAEDF3')};
      color: ${props => (props.active ? 'white' : '')};
      border-color: ${props => (props.active ? '#0084E2' : '#d7ddea')};
      box-shadow: ${props => (props.active ? '0 -3px 1px #023f6a4a, 0 1px 2px #023f6a4a' : 'none')};
      transition: all 400ms;
      border-bottom: none;

      cursor: pointer;
      text-align: center;
      line-height: 46px;
      letter-spacing: 0.5px;
      font-size: 15px;

      &:hover {
        width: 20%;
      }
    `,
    TabsContainerWorkSpace: styled.div`
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-radius: 12px;
      border: 1px solid #0084e2;
      background-color: white;
      width: 100%;
      min-height: 40px;
    `,
    TabsContainerWorkSpaceAddButton: styled.span`
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
    `,
  },
}

export default css
