// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Checkbox } from '@mui/material'
import { Wrapper, CheckBoxFrame } from '../../styles.ts'

import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'
import { setName } from '../../../../../store/slices/app/controlers/pageName.js'
import { toggleEnableStep } from '../../../../../store/slices/app/controlers/stepper.js'

const DataTransfer = props => {
  const { doChecked, handleDoChecked } = props
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setName('Передача данных в федеральные системы'))
    dispatch(toggleEnableStep(false))
  }, [])

  return (
    <Wrapper>
      <Typography variant='h2'>Передача данных в федеральные системы</Typography>
      <CheckBoxFrame>
        {width <= 1050 && (
          <label for='doChecked' style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}>
            Одобрить передачу данных из протокола во ФГИС Аршин и ФСА
          </label>
        )}
        <Checkbox
          sx={width > 1050 ? {} : { transform: 'scale(140%)' }}
          checked={doChecked}
          onChange={handleDoChecked}
          id='doChecked'
        />
        {width > 1050 && (
          <label
            htmlFor='doChecked'
            style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}
          >
            Одобрить передачу данных из протокола во ФГИС Аршин и ФСА
          </label>
        )}
      </CheckBoxFrame>
    </Wrapper>
  )
}

export default DataTransfer
