// ----------------------------------------------------------------
/* eslint-disable react/jsx-no-target-blank */
// ----------------------------------------------------------------
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { ContainerDescDev, Cell } from './styles.ts'
import { IMeterData } from '../../../types.models.views.ts'

interface ApprovedTypesProps {
  data: IMeterData
  conversion_factor?: string
}

const ApprovedTypes: FC<ApprovedTypesProps> = ({ data, conversion_factor }) => {
  const measurement_id = useSelector((state: any) => state.meterReducer.measurement_id)

  return (
    <ContainerDescDev>
      {measurement_id === 1 && (
        <Cell>
          <Typography variant='subtitle2'>Коэффициент преобразования :</Typography>
          <Typography variant='subtitle1'>{conversion_factor || '-'}</Typography>
        </Cell>
      )}
      <Cell>
        <Typography variant='subtitle2'>Утвержденные типы средств измерений :</Typography>
        <Typography variant='subtitle1'>
          <a
            href={data?.type_link}
            target='_blank'
            style={{
              textDecoration: 'none',
              color: 'inherit',
              borderBottom: '1px solid #000',
              fontWeight: 'bold',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {data?.type_link}
          </a>
        </Typography>
      </Cell>
    </ContainerDescDev>
  )
}

export default ApprovedTypes
