import { changeProperty } from '../state.js'

export const getAcceptanceChecks = (isMechanicalAccepted, isTightnessAccepted, dispatch) => [
  {
    title: 'Внешний осмотр',
    description: `Счётчик представлен на поверку со следующими документами (паспортом на счетчик или свидетельством о предыдущей поверке, руководством по эксплуатации, протоколом испытаний на герметичность, протоколом испытаний на сопротивление изоляции). Установлено наличие товарного знака предприятия-изготовителя, а также надписей с указанием типа, порядкового номера, года изготовления, наибольшего избыточного давления, максимального и минимального значений расхода.`,
    label: 'Соответствие пункту 9.1. Методики поверки ГОСТ 8.324-2002',
    value: isMechanicalAccepted,
    onChange: event => {
      const newValue = event.target.value === 'true'
      dispatch(changeProperty({ value: 'isMechanicalAccepted', label: newValue }))
    },
  },
  {
    title: 'Опробование',
    description: `Проведено пропускание через счётчик потока воздуха со значением расхода не менее 10% номинального. При этом счетчик работает устойчиво, без рывков, заеданий, посторонних шумов. Показания отсчётного устройства равномерно увеличиваются.`,
    label: 'Соответствие пункту 9.2. Методики поверки ГОСТ 8.324-2002',
    value: isTightnessAccepted,
    onChange: event => {
      const newValue = event.target.value === 'true'
      dispatch(changeProperty({ value: 'isTightnessAccepted', label: newValue }))
    },
  },
]

export const calculateAverageError = (
  permissibleError,
  permissibleError_extra_2,
  permissibleError_extra_3,
) => {
  if (
    [permissibleError, permissibleError_extra_2, permissibleError_extra_3].some(val => val === '')
  ) {
    return null
  }

  const values = [permissibleError, permissibleError_extra_2, permissibleError_extra_3].map(
    parseFloat,
  )

  if (values.some(isNaN)) {
    return null
  }

  return (values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(2)
}

export const calculateDifference = (finish, start) => {
  if (!finish || isNaN(parseFloat(finish))) return { start: '', finish: '', difference: '' }
  if (!start || isNaN(parseFloat(start))) return { start: '', finish: '', difference: '' }

  const formattedFinish = parseFloat(finish).toFixed(3)
  const formattedStart = parseFloat(start).toFixed(3)

  let result = (parseFloat(formattedFinish) - parseFloat(formattedStart)).toFixed(3)

  result = parseFloat(result)

  return {
    start: formattedStart,
    finish: formattedFinish,
    difference: result,
  }
}

export const calculateQt = (formula, q_min, q_max, q_nom) => {
  if (!formula) return '0.000'

  try {
    const formattedFormula = formula
      .replace(/Qmin/g, parseFloat(q_min) || 0)
      .replace(/Qmax/g, parseFloat(q_max) || 0)
      .replace(/Qnom/g, parseFloat(q_nom) || 0)

    const result = eval(formattedFormula)

    return result.toFixed(2)
  } catch (error) {
    return '0.00'
  }
}

export const calculatePermissibleError = (volumeAirMeter, volumeEtalon, correction) => {
  if (volumeEtalon === null || volumeEtalon === undefined) return ''
  const verified = parseFloat(volumeAirMeter) || 0
  const reference = parseFloat(volumeEtalon) || 0
  const correctionValue = parseFloat(correction) || 0

  const permissibleError = ((verified - reference) / reference) * 100 - correctionValue

  return isNaN(permissibleError) ? '' : parseFloat(permissibleError.toFixed(2))
}

export const checkWithinLimits = (value, minLimit, maxLimit) => {
  if (!value || !minLimit || !maxLimit) return true

  const val = parseFloat(value)
  const min = parseFloat(minLimit)
  const max = parseFloat(maxLimit)

  return val >= min && val <= max
}

export const getMeasurementData = variables => {
  const {
    start_1,
    finish_1,
    volumeEtalon_1,
    volumeAirMeter_1,
    measurementConsumption_1,
    permissibleError_1,
    start_2,
    finish_2,
    volumeEtalon_2,
    volumeAirMeter_2,
    measurementConsumption_2,
    permissibleError_2,
    start_3,
    finish_3,
    volumeEtalon_3,
    volumeAirMeter_3,
    measurementConsumption_3,
    permissibleError_3,
    start_extra_2,
    finish_extra_2,
    volumeEtalon_extra_2,
    volumeAirMeter_extra_2,
    measurementConsumption_extra_2,
    permissibleError_extra_2,
    start_extra_3,
    finish_extra_3,
    volumeEtalon_extra_3,
    volumeAirMeter_extra_3,
    measurementConsumption_extra_3,
    permissibleError_extra_3,
    start_extra_4,
    finish_extra_4,
    volumeEtalon_extra_4,
    volumeAirMeter_extra_4,
    measurementConsumption_extra_4,
    permissibleError_extra_4,
    start_extra_5,
    finish_extra_5,
    volumeEtalon_extra_5,
    volumeAirMeter_extra_5,
    measurementConsumption_extra_5,
    permissibleError_extra_5,
    start_extra_6,
    finish_extra_6,
    volumeEtalon_extra_6,
    volumeAirMeter_extra_6,
    measurementConsumption_extra_6,
    permissibleError_extra_6,
    start_extra_7,
    finish_extra_7,
    volumeEtalon_extra_7,
    volumeAirMeter_extra_7,
    measurementConsumption_extra_7,
    permissibleError_extra_7,
    averageErrorQMin,
    averageErrorQT,
    averageErrorQMax,
  } = variables

  const data = [
    {
      start_1,
      finish_1,
      volume_etalon_1: volumeEtalon_1,
      volume_air_meter_1: volumeAirMeter_1,
      measurement_consumption_1: measurementConsumption_1,
      permissible_error_1: permissibleError_1,

      start_2: start_extra_2,
      finish_2: finish_extra_2,
      volume_etalon_2: volumeEtalon_extra_2,
      volume_air_meter_2: volumeAirMeter_extra_2,
      measurement_consumption_2: measurementConsumption_extra_2,
      permissible_error_2: permissibleError_extra_2,
      start_3: start_extra_3,
      finish_3: finish_extra_3,
      volume_etalon_3: volumeEtalon_extra_3,
      volume_air_meter_3: volumeAirMeter_extra_3,
      measurement_consumption_3: measurementConsumption_extra_3,
      permissible_error_3: permissibleError_extra_3,
      permissible_error_total: averageErrorQMin,
    },
    {
      start_1: start_2,
      finish_1: finish_2,
      volume_etalon_1: volumeEtalon_2,
      volume_air_meter_1: volumeAirMeter_2,
      measurement_consumption_1: measurementConsumption_2,
      permissible_error_1: permissibleError_2,
      start_2: start_extra_4,
      finish_2: finish_extra_4,
      volume_etalon_2: volumeEtalon_extra_4,
      volume_air_meter_2: volumeAirMeter_extra_4,
      measurement_consumption_2: measurementConsumption_extra_4,
      permissible_error_2: permissibleError_extra_4,
      start_3: start_extra_5,
      finish_3: finish_extra_5,
      volume_etalon_3: volumeEtalon_extra_5,
      volume_air_meter_3: volumeAirMeter_extra_5,
      measurement_consumption_3: measurementConsumption_extra_5,
      permissible_error_3: permissibleError_extra_5,
      permissible_error_total: averageErrorQT,
    },
    {
      start_1: start_3,
      finish_1: finish_3,
      volume_etalon_1: volumeEtalon_3,
      volume_air_meter_1: volumeAirMeter_3,
      measurement_consumption_1: measurementConsumption_3,
      permissible_error_1: permissibleError_3,
      start_2: start_extra_6,
      finish_2: finish_extra_6,
      volume_etalon_2: volumeEtalon_extra_6,
      volume_air_meter_2: volumeAirMeter_extra_6,
      measurement_consumption_2: measurementConsumption_extra_6,
      permissible_error_2: permissibleError_extra_6,
      start_3: start_extra_7,
      finish_3: finish_extra_7,
      volume_etalon_3: volumeEtalon_extra_7,
      volume_air_meter_3: volumeAirMeter_extra_7,
      measurement_consumption_3: measurementConsumption_extra_7,
      permissible_error_3: permissibleError_extra_7,
      permissible_error_total: averageErrorQMax,
    },
  ]

  return data
    .map(obj =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== '' && value !== null && value !== undefined,
        ),
      ),
    )
    .filter(obj => Object.keys(obj).length > 0)
}
