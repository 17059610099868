import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../../../ui/back-button/index.tsx'
import EditShowCompany from './EditShowCompanies'
import Tariffs from '../../../admin/comps/Tarrifs.tsx'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import R from '../../../services/app/client-server/request.service'
import {
  setViewVariant,
  setTariff,
  setCompanyId,
  setTariffs,
  setStatus,
} from '../../../store/slices/app/views/companies'

const ShowCompany = () => {
  const dispatch = useDispatch()

  const { company_id } = useParams()

  const [companyData, setCompanyData] = useState([])
  const viewVariant = useSelector(state => state.companiesReducer.viewVariant)

  useEffect(() => {
    ;(async () => {
      const { data: companiesItemData } = await R.getCompaniesItem(company_id)
      const data = companiesItemData.data
      setCompanyData(data)

      dispatch(setTariff(data.subscription_id))
      dispatch(setCompanyId(data.id))

      const initialTariffs = data.tariffs.map(item => ({
        tariffId: item.id,
      }))

      dispatch(setTariffs(initialTariffs))

      dispatch(setStatus(data.status))
    })()
  }, [company_id, dispatch])

  const changeViewVariant = param => dispatch(setViewVariant(param))

  return (
    <main className={'main'}>
      <BackButton to='/metriva/companies' label='Назад к списку компаний' />
      <React.Fragment>
        {/* <div className='path_to_edit' style={{ marginBottom: '0px' }}>
            <h4>
              {companyData && companyData.name ? companyData.name : 'Название компании не получено'}
            </h4>
          </div> */}

        <BasicTabs
          callback={changeViewVariant}
          viewVariant={viewVariant}
          tabs={[{ label: 'Общие данные' }, { label: 'Информация о тарифах' }]}
        />

        {viewVariant === 0 && <EditShowCompany companyData={companyData} />}
        {viewVariant === 1 && <Tariffs companyData={companyData} />}
      </React.Fragment>
    </main>
  )
}

export default ShowCompany
