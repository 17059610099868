// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Tooltip from '../../../ui/tooltip/index.tsx'
import Button from '../../../ui/button/index.tsx'
import FilterIcon from '../../../img/icon/FilterIcon'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import PdfIcon from '../../../img/icon/PdfIcon'
import { Typography, Fade, Collapse } from '@mui/material'

import R from '../../../services/app/client-server/request.service'
import Table from '../../components/LocalElements/Table/Table'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import Skeleton from '../../components/LocalElements/Skeleton/Skeleton'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  setDataEtalon,
  setSearchQuery as setSearchQueryEtalon,
  setSelectedEtalons,
} from '../../../store/slices/app/views/etalon'
import {
  refreshEtalons,
  refreshKitPassport,
  refreshKitVerifications,
} from '../../../store/slices/app/controlers/updater'
import { setName } from '../../../store/slices/app/controlers/pageName'

import { IModelEtalonsList } from '../../types'

const IndexEtalons = () => {
  const userRole = useSelector((state: any) => state.headerReducer.role)

  const { width } = useWindowDimensions()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state: any) => state.toggleReducer.isToggle)
  const isLoading = useSelector((state: any) => state.etalonReducer.isLoading)
  const dataEtalons = useSelector((state: any) => state.etalonReducer.dataEtalon)
  const selectedEtalons = useSelector((state: any) => state.etalonReducer.selectedEtalons)

  false && console.log(dataEtalons)

  const etalonSearchQuery = useSelector((state: any) => state.etalonReducer.searchQuery)
  const companyIdSuperAdmin = useSelector((state: any) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state: any) => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const changeSearchQuery = (param: any) => {
    dispatch(setSearchQueryEtalon(param))
  }

  const handleCreate = () => {
    navigate('/metriva/etalon/create')
  }

  const handleEdit = (param: { id: any }) => {
    navigate(`/metriva/etalon/${param.id}/edit`)
  }

  const handleDelete = async (etalonId: any) => {
    const { status } = await R.deleteEtalon(etalonId)
    if (status === 200) {
      dispatch(refreshEtalons(''))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Средство поверки было удалено'))
      dispatch(setOpen(false))
      dispatch(resetState())
      dispatch(refreshKitPassport(''))
      dispatch(refreshKitVerifications(''))
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  const handlePdf = async () => {
    const data = {
      ids: selectedEtalons,
    }

    try {
      const { status, data: fileData } = await R.KitPassportsPdf(companyId, data)

      if (status === 200) {
        let blob: Blob | MediaSource
        let fileName: string
        const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')

        if (selectedEtalons.length === 1) {
          blob = new Blob([fileData], { type: 'application/pdf' })
          fileName = `Pasport_sp_${selectedEtalons[0]}.pdf`
        } else {
          blob = new Blob([fileData], { type: 'application/zip' })
          fileName = `Pasporta_sp_${currentDate}.zip`
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage('PDF успешно скачан(ы).'))
        dispatch(setType('success'))
        dispatch(setOpenAlert(true))
      } else {
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage(fileData.message))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  let colData = [
    {
      field: 'title',
      headerName: 'Наименование и тип',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'manufacturerNumber',
      headerName: 'Заводской номер',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'inventoryNumber',
      headerName: 'Инвентарный номер',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'status',
      headerName: 'Текущий статус',
      type: 'string',
      renderCell: (params: {
        row: {
          status:
            | 'Хранение (консервация)'
            | 'in_storage'
            | 'В эксплуатации'
            | 'in_use'
            | 'Поверка'
            | 'verification'
            | 'Аттестация'
            | 'attestation'
            | 'Калибровка'
            | 'calibration'
            | 'Ремонт'
            | 'repair'
            | 'Списано'
            | 'decommissioned'
        }
      }) => {
        const { status } = params.row

        if (status === 'in_use' || status === 'В эксплуатации') {
          return <p style={{ color: '#00A37C', fontSize: '0.875rem' }}>В эксплуатации</p>
        } else if (status === 'in_storage' || status === 'Хранение (консервация)') {
          return <p style={{ color: 'gray', fontSize: '0.875rem' }}>Хранение (консервация)</p>
        } else if (status === 'verification' || status === 'Поверка') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Поверка</p>
        } else if (status === 'attestation' || status === 'Аттестация') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Аттестация</p>
        } else if (status === 'calibration' || status === 'Калибровка') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Калибровка</p>
        } else if (status === 'repair' || status === 'Ремонт') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Ремонт</p>
        } else if (status === 'decommissioned' || status === 'Списано') {
          return <p style={{ color: '#E74953', fontSize: '0.875rem' }}>Списано</p>
        }
      },
    },
    {
      field: 'group',
      headerName: 'Группа СП',
      type: 'string',
    },
    {
      field: 'isSyncAvailable',
      headerName: 'Отправка данных о СП в Аршин',
      type: 'string',
    },
    {
      field: 'listedInSets',
      headerName: 'СП числится в комплектах',
      type: 'arrayString',
    },
    {
      field: 'lastCheckDate',
      headerName: 'Дата последней поверки/аттестации',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'endCheckDate',
      headerName: 'Дата истечения срока поверки/аттестации',
      type: 'string',
      disableFilter: true,
      renderCell: (params: {
        row: {
          endCheckDate: any
          group: string
        }
      }) => {
        const { endCheckDate, group } = params.row

        if (!endCheckDate) {
          return <span style={{ fontSize: '0.875rem' }}>—</span>
        }

        const today = moment()
        const checkDate = moment(endCheckDate, 'DD-MM-YYYY')

        if (checkDate.isBefore(today, 'day')) {
          return (
            <React.Fragment>
              <Tooltip
                title={
                  ~group.indexOf('СИ')
                    ? 'Срок действия поверки истек'
                    : 'Срок действия аттестации истек'
                }
                disableHoverListener={undefined}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#E74953', fontSize: '0.875rem', marginRight: '10px' }}>
                    {endCheckDate}
                  </span>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M15.6172 7.79688C15.6172 12.077 12.1472 15.5469 7.86719 15.5469C3.58715 15.5469 0.117188 12.077 0.117188 7.79688C0.117188 3.51671 3.58715 0.046875 7.86719 0.046875C12.1472 0.046875 15.6172 3.51671 15.6172 7.79688ZM9.20339 11.3378C9.20339 11.6941 9.07395 11.9971 8.8161 12.2464C8.55721 12.4958 8.24091 12.6205 7.86719 12.6205C7.68555 12.6205 7.51748 12.5912 7.36402 12.5324C7.20013 12.4702 7.05189 12.3748 6.91827 12.2464C6.81179 12.1429 6.72724 12.0303 6.6646 11.9085C6.57587 11.7366 6.53098 11.5463 6.53098 11.3378C6.53098 10.9726 6.66043 10.6653 6.91827 10.4158C7.17716 10.1664 7.49347 10.0417 7.86719 10.0417C8.24091 10.0417 8.55721 10.1664 8.8161 10.4158C8.95077 10.5458 9.04994 10.6915 9.11466 10.8529C9.17416 11.0013 9.20339 11.163 9.20339 11.3378ZM7.10618 8.22449C7.15942 8.60747 7.20848 8.94596 7.25232 9.23995H8.48205L8.56243 8.69829L8.62924 8.22449C8.68248 7.84137 8.73572 7.44495 8.78896 7.03521C8.8516 6.62548 8.90484 6.23792 8.94972 5.87268C8.98417 5.62723 9.01445 5.40671 9.03741 5.21111L9.06977 4.92403C9.10527 4.66566 9.12301 4.50085 9.12301 4.42961C9.12301 4.09112 8.99879 3.81057 8.74929 3.58782C8.50919 3.36508 8.21481 3.25377 7.86719 3.25377C7.51957 3.25377 7.22205 3.36508 6.97256 3.58782C6.73141 3.81057 6.61136 4.09112 6.61136 4.42961L6.61345 4.49994C6.61867 4.58841 6.6312 4.72973 6.65103 4.92403C6.68757 5.1824 6.72724 5.49857 6.77212 5.87268C6.82536 6.23792 6.8786 6.62548 6.93184 7.03521C6.99448 7.44495 7.05189 7.84137 7.10618 8.22449Z'
                      fill='#E74953'
                    />
                  </svg>
                </div>
              </Tooltip>
            </React.Fragment>
          )
        }

        if (checkDate.diff(today, 'days') <= 30) {
          return (
            <React.Fragment>
              <Tooltip
                title={
                  ~group.indexOf('СИ')
                    ? 'Срок действия поверки подходит к концу'
                    : 'Срок действия аттестации подходит к концу'
                }
                disableHoverListener={undefined}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#E57004', fontSize: '0.875rem', marginRight: '10px' }}>
                    {endCheckDate}
                  </span>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M15.6172 7.79688C15.6172 12.077 12.1472 15.5469 7.86719 15.5469C3.58715 15.5469 0.117188 12.077 0.117188 7.79688C0.117188 3.51671 3.58715 0.046875 7.86719 0.046875C12.1472 0.046875 15.6172 3.51671 15.6172 7.79688ZM9.20339 11.3378C9.20339 11.6941 9.07395 11.9971 8.8161 12.2464C8.55721 12.4958 8.24091 12.6205 7.86719 12.6205C7.68555 12.6205 7.51748 12.5912 7.36402 12.5324C7.20013 12.4702 7.05189 12.3748 6.91827 12.2464C6.81179 12.1429 6.72724 12.0303 6.6646 11.9085C6.57587 11.7366 6.53098 11.5463 6.53098 11.3378C6.53098 10.9726 6.66043 10.6653 6.91827 10.4158C7.17716 10.1664 7.49347 10.0417 7.86719 10.0417C8.24091 10.0417 8.55721 10.1664 8.8161 10.4158C8.95077 10.5458 9.04994 10.6915 9.11466 10.8529C9.17416 11.0013 9.20339 11.163 9.20339 11.3378ZM7.10618 8.22449C7.15942 8.60747 7.20848 8.94596 7.25232 9.23995H8.48205L8.56243 8.69829L8.62924 8.22449C8.68248 7.84137 8.73572 7.44495 8.78896 7.03521C8.8516 6.62548 8.90484 6.23792 8.94972 5.87268C8.98417 5.62723 9.01445 5.40671 9.03741 5.21111L9.06977 4.92403C9.10527 4.66566 9.12301 4.50085 9.12301 4.42961C9.12301 4.09112 8.99879 3.81057 8.74929 3.58782C8.50919 3.36508 8.21481 3.25377 7.86719 3.25377C7.51957 3.25377 7.22205 3.36508 6.97256 3.58782C6.73141 3.81057 6.61136 4.09112 6.61136 4.42961L6.61345 4.49994C6.61867 4.58841 6.6312 4.72973 6.65103 4.92403C6.68757 5.1824 6.72724 5.49857 6.77212 5.87268C6.82536 6.23792 6.8786 6.62548 6.93184 7.03521C6.99448 7.44495 7.05189 7.84137 7.10618 8.22449Z'
                      fill='#E57004'
                    />
                  </svg>
                </div>
              </Tooltip>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            <span style={{ fontSize: '0.875rem', marginRight: '10px' }}>{endCheckDate}</span>
          </React.Fragment>
        )
      },
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: (item: any) => {
        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Редактировать средство поверки' disableHoverListener={undefined}>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(item)}>
                <PencilIcon onClick={undefined} />
              </div>
            </Tooltip>
            {item.row.listedInSets === '-' && (
              <Tooltip title='Удалить средство поверки' disableHoverListener={undefined}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(setTitle('Вы действительно хотите удалить СП?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(item.id)))
                  }}
                >
                  <DeleteIcon onClick={() => {}} />
                </div>
              </Tooltip>
            )}
            {item.row.listedInSets !== '-' && (
              <Tooltip
                title='Удаление недоступно, средство поверки является частью комплекта СП'
                disableHoverListener={undefined}
              >
                <div style={{ cursor: 'pointer', opacity: 0.6 }} onClick={() => {}}>
                  <DeleteIcon onClick={() => {}} />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ]

  useEffect(() => {
    dispatch(setName('База эталонов'))
  }, [])

  const DinamicActions = (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button
        color='text'
        startIcon={<PdfIcon onClick={undefined} />}
        endIcon={undefined}
        label='Скачать регистрационную карточку (паспорт) выбранных СП'
        onClick={handlePdf}
        styles={{
          padding: 0,
          color: 'inherit',
          minWidth: '50px',
          paddingLeft: '16px',
          paddingRight: '14px',
          marginLeft: '-10px',
          fontSize: '.88rem',
        }}
      />
    </div>
  )

  return (
    <main className={'main'}>
      {userRole === 'super' ? (
        <div
          style={{
            width: width > 1050 ? '100%' : '100%',
            marginBottom: '0px',
          }}
        >
          <FilterCompany />
        </div>
      ) : (
        <span style={{ width: '100%', display: 'block', marginBottom: '-12px' }}></span>
      )}

      <Collapse in={!companyId} timeout={500} unmountOnExit>
        <div className='page_null'>
          <Fade in={!companyId} timeout={500}>
            <Typography variant='body1'>Для просмотра данных выберите компанию</Typography>
          </Fade>
        </div>
      </Collapse>

      <Collapse in={!!companyId} timeout={500} unmountOnExit>
        <Fade in={!!companyId} timeout={500}>
          <div>
            {/* <div style={{ margin: '10px 0 12px' }}>
              <BasicTabs
                viewVariant={0}
                callback={() => {}}
                tabs={[{ label: 'Список средств поверки' }]}
                containnerCSS={undefined}
              />
            </div> */}

            {isLoading ? (
              <Skeleton />
            ) : (
              <Table
                colData={colData}
                rowData={dataEtalons.map((item: IModelEtalonsList) => ({
                  id: item.id,
                  title: item.name_type,
                  manufacturerNumber: item.manufacturer_number ?? '-',
                  inventoryNumber: item.inventory_number ?? '-',
                  isSyncAvailable: item.is_sync_available ? 'Доступна' : 'Недоступна',
                  group: item.kit_group_name,
                  status:
                    item.status === 'in_use'
                      ? 'В эксплуатации'
                      : item.status === 'in_storage'
                        ? 'Хранение (консервация)'
                        : item.status === 'verification'
                          ? 'Поверка'
                          : item.status === 'attestation'
                            ? 'Аттестация'
                            : item.status === 'calibration'
                              ? 'Калибровка'
                              : item.status === 'repair'
                                ? 'Ремонт'
                                : item.status === 'decommissioned'
                                  ? 'Списано'
                                  : 'Неизвестный статус',
                  lastCheckDate: moment(item.last_date).format('DD-MM-YYYY'),
                  endCheckDate: moment(item.expired_date).format('DD-MM-YYYY'),
                  listedInSets:
                    item.cases && item.cases.length > 0
                      ? item.cases.map(itemCase => itemCase.inner_name).join(' , ')
                      : '-',
                }))}
                rowCallback={handleEdit}
                addButtonCallback={handleCreate}
                searchParams={{ query: etalonSearchQuery, builder: changeSearchQuery }}
                showToolbar={true}
                actions={{
                  static: null,
                  dinamic: selectedEtalons && selectedEtalons.length > 0 ? DinamicActions : null,
                }}
                hasCheckboxSelection={true}
                rowSelectionCallback={(selectedIds: any[]) => {
                  const selectedTitles = selectedIds
                    .map((id: any) => {
                      const row = dataEtalons.find((row: { id: any }) => row.id === id)
                      return row ? row.passport_pdf_id : null
                    })
                    .filter(Boolean)

                  dispatch(setSelectedEtalons(selectedTitles))
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            )}
          </div>
        </Fade>
      </Collapse>
    </main>
  )
}

export default IndexEtalons
