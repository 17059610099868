import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { ContainerDescDev, TableHeader, TableRow, TableCell } from './styles.ts'

import { IMeterData, IModification } from '../../../types.models.views.ts'

interface PermissibleErrorProps {
  data: IMeterData
  modification: IModification | null
}

const PermissibleError: FC<PermissibleErrorProps> = ({ data, modification }) => {
  const measurement_id = useSelector((state: any) => state.meterReducer.measurement_id)

  const columns =
    measurement_id === 1
      ? [
          {
            id: 'qminCold',
            label: ['Погрешность от Qmin', 'до Qt, % ХВС'],
            value: modification?.q_min_limit_cold ?? '-',
          },
          {
            id: 'qmaxCold',
            label: ['Погрешность от Qt', 'до Qmax, % ХВС'],
            value: modification?.q_min_limit_hot ?? '-',
          },
          {
            id: 'qminHot',
            label: ['Погрешность от Qmin', 'до Qt, % ГВС'],
            value: modification?.q_max_limit_cold ?? '-',
          },
          {
            id: 'qmaxHot',
            label: ['Погрешность от Qt', 'до Qmax, % ГВС'],
            value: modification?.q_max_limit_hot ?? '-',
          },
        ]
      : [
          {
            id: 'qminMinus',
            label: ['Погрешность от Qmin', 'до Qt (-), %'],
            value: parseFloat(data?.q_min_limit_negative ?? '') || '-',
          },
          {
            id: 'qminPlus',
            label: ['Погрешность от Qmin', 'до Qt (+), %'],
            value: parseFloat(data?.q_min_limit_positive ?? '') || '-',
          },
          {
            id: 'qtMinus',
            label: ['Погрешность от Qt', 'до Qmax (-), %'],
            value: parseFloat(data?.q_max_limit_negative ?? '') || '-',
          },
          {
            id: 'qtPlus',
            label: ['Погрешность от Qt', 'до Qmax (+), %'],
            value: parseFloat(data?.q_max_limit_positive ?? '') || '-',
          },
        ]

  return (
    <ContainerDescDev>
      <TableHeader>
        {columns.map(col => (
          <TableCell key={col.id}>
            <Typography variant='subtitle1' align='center'>
              {col.label[0]}
              <br />
              {col.label[1]}
            </Typography>
          </TableCell>
        ))}
      </TableHeader>

      <TableRow>
        {columns.map(col => (
          <TableCell key={col.id}>
            <Typography variant='subtitle2'>{col.value}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </ContainerDescDev>
  )
}

export default PermissibleError
