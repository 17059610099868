// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import Feedback from '../../../LocalElements/Feedback/Feedback'
import R from '../../../../../services/app/client-server/request.service.js'
import { changeKitPassport, resetKitPassport } from '../../../../../store/slices/app/views/journals'
import {
  changeEtalonVerifications,
  changeEtalonAttestations,
  changeEtalonMaintenances,
  changeEtalonCalibrations,
  changeEtalonRepairs,
  resetCreateData,
  changeEtalon,
} from '../../../../../store/slices/app/views/etalon'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'
import { refreshKitPassport } from '../../../../../store/slices/app/controlers/updater'
import { setShow } from '../../../../../store/slices/app/comps/feedback'
import { Wrapper, Content, ContentFrame, LabelFrame } from '../../style.js'
import Input from '../../../../../ui/input/index.tsx'
import Button from '../../../../../ui/button/index.tsx'
import AccuracyClassSelect from '../../../LocalElements/AccuracyClass/AccuracyClassSelect'
import TabsContainer from '../../../../components/Etalon/TabsContainer.tsx'
import Year from '../../../LocalElements/SelectYear/Year'
import BackButton from '../../../../../ui/back-button/index.tsx'
import BottomSideContainer from '../../../LocalElements/BottomSideContainer/BottomSideContainer.jsx'
import usePrompt from '../../../../hooks/usePrompt.ts'

const Edit = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { journal_id } = useParams()

  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const [errors, setErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [getPrompt, setGetPrompt] = useState(true)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const title = useSelector(state => state.bottomContainerReducer.title)

  const responsiblePassportName = useSelector(
    state => state.journalReducer.kitPassports.responsiblePassportName,
  )
  const kitGroup = useSelector(state => state.journalReducer.kitPassports.kitGroup)
  const accountingNumber = useSelector(state => state.journalReducer.kitPassports.accountingNumber)
  const name = useSelector(state => state.journalReducer.kitPassports.name)
  const type = useSelector(state => state.journalReducer.kitPassports.type)
  const registerNumber = useSelector(state => state.journalReducer.kitPassports.registerNumber)
  const manufacturerName = useSelector(state => state.journalReducer.kitPassports.manufacturerName)
  const releaseYear = useSelector(state => state.journalReducer.kitPassports.releaseYear)
  const manufacturerNumber = useSelector(
    state => state.journalReducer.kitPassports.manufacturerNumber,
  )
  const measRange = useSelector(state => state.journalReducer.kitPassports.measRange)
  const reprRange = useSelector(state => state.journalReducer.kitPassports.reprRange)
  const error = useSelector(state => state.journalReducer.kitPassports.error)
  const kitAccuracyClassId = useSelector(
    state => state.journalReducer.kitPassports.kitAccuracyClassId,
  )
  const deviations = useSelector(state => state.journalReducer.kitPassports.deviations)
  const softwareInfo = useSelector(state => state.journalReducer.kitPassports.softwareInfo)
  const componentInfo = useSelector(state => state.journalReducer.kitPassports.componentInfo)
  const receiptDate = useSelector(state => state.journalReducer.kitPassports.receiptDate)
  const commissionDate = useSelector(state => state.journalReducer.kitPassports.commissionDate)
  const inventoryNumber = useSelector(state => state.journalReducer.kitPassports.inventoryNumber)
  const location = useSelector(state => state.journalReducer.kitPassports.location)

  const kitVerifications = useSelector(state => state.etalonReducer.createData.kitVerifications)
  const kitAttestations = useSelector(state => state.etalonReducer.createData.kitAttestations)
  const kitMaintenances = useSelector(state => state.etalonReducer.createData.kitMaintenances)
  const kitCalibrations = useSelector(state => state.etalonReducer.createData.kitCalibrations)
  const kitRepairs = useSelector(state => state.etalonReducer.createData.kitRepairs)

  const intervalVerifications = useSelector(
    state => state.etalonReducer.createData.interval_verifications,
  )
  const intervalAttestations = useSelector(
    state => state.etalonReducer.createData.interval_attestations,
  )
  const intervalMaintenances = useSelector(
    state => state.etalonReducer.createData.interval_maintenances,
  )

  usePrompt(
    'Вы уверены, что хотите продолжить действие? Все введенные или измененные данные не будут сохранены',
    getPrompt,
  )

  const handlePassportNameChange = param => {
    dispatch(changeKitPassport({ value: 'responsiblePassportName', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, responsible_passport_name: '' }))
  }

  const handleNameChange = param => {
    dispatch(changeKitPassport({ value: 'name', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, name: '' }))
  }

  const handleTypeChange = param => {
    dispatch(changeKitPassport({ value: 'type', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, type: '' }))
  }

  const handleRegisterNumberChange = param => {
    dispatch(changeKitPassport({ value: 'registerNumber', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, register_number: '' }))
  }

  const handleManufacturerNameChange = param => {
    dispatch(changeKitPassport({ value: 'manufacturerName', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, manufacturer_name: '' }))
  }

  const handleReleaseYearChange = param => {
    dispatch(changeKitPassport({ value: 'releaseYear', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, release_year: '' }))
  }

  const handleManufacturerNumberChange = param => {
    dispatch(changeKitPassport({ value: 'manufacturerNumber', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, manufacturer_number: '' }))
  }

  const handleMeasRangeChange = param => {
    dispatch(changeKitPassport({ value: 'measRange', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, meas_range: '' }))
  }

  const handleReprRangeChange = param => {
    dispatch(changeKitPassport({ value: 'reprRange', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, repr_range: '' }))
  }

  const handleErrorChange = param => {
    dispatch(changeKitPassport({ value: 'error', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, error: '' }))
  }

  const handleKitAccuracyClassIdChange = param => {
    dispatch(changeKitPassport({ value: 'kitAccuracyClassId', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, kit_accuracy_class_id: '' }))
  }

  const handleDeviationsChange = param => {
    dispatch(changeKitPassport({ value: 'deviations', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, deviations: '' }))
  }

  const handleSoftwareInfoChange = param => {
    dispatch(changeKitPassport({ value: 'softwareInfo', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, software_info: '' }))
  }

  const handleComponentInfoChange = param => {
    dispatch(changeKitPassport({ value: 'componentInfo', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, component_info: '' }))
  }

  const handleInventoryNumberChange = param => {
    dispatch(changeKitPassport({ value: 'inventoryNumber', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, inventory_number: '' }))
  }

  const handleLocationChange = param => {
    dispatch(changeKitPassport({ value: 'location', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, location: '' }))
  }

  const handleReceiptDateChange = date => {
    if (date) {
      dispatch(
        changeKitPassport({
          value: 'receiptDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeKitPassport({ value: 'receiptDate', label: '' }))
    }
  }

  const handleCommissionDateChange = date => {
    if (date) {
      dispatch(
        changeKitPassport({
          value: 'commissionDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeKitPassport({ value: 'commissionDate', label: '' }))
    }
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: kitPassportData } = await R.getKitPassportsItem(journal_id, companyId)
        const data = kitPassportData.data

        dispatch(
          changeKitPassport({
            value: 'responsiblePassportName',
            label: data?.responsible_passport_name,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'kitGroup',
            label: data?.kit_group,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'formedDate',
            label: data?.formed_date,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'accountingNumber',
            label: data?.accounting_number,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'name',
            label: data?.name,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'type',
            label: data?.type,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'registerNumber',
            label: data?.register_number,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'manufacturerName',
            label: data?.manufacturer_name,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'releaseYear',
            label: String(data?.release_year),
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'manufacturerNumber',
            label: data?.manufacturer_number,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'measRange',
            label: data?.meas_range,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'reprRange',
            label: data?.repr_range,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'error',
            label: data?.error,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'kitAccuracyClassId',
            label: data?.kit_accuracy_class_id,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'deviations',
            label: data?.deviations,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'softwareInfo',
            label: data?.software_info,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'componentInfo',
            label: data?.component_info,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'receiptDate',
            label: data?.receipt_date,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'commissionDate',
            label: data?.commission_date,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'inventoryNumber',
            label: data?.inventory_number,
          }),
        )

        dispatch(
          changeKitPassport({
            value: 'location',
            label: data?.location,
          }),
        )

        dispatch(
          changeEtalon({ value: 'interval_verifications', label: data?.interval_verifications }),
        )

        dispatch(
          changeEtalon({ value: 'interval_attestations', label: data?.interval_attestations }),
        )

        dispatch(
          changeEtalon({ value: 'interval_maintenances', label: data?.interval_maintenances }),
        )

        const initialKitVerifications = data.kitVerifications.map(item => item)
        initialKitVerifications.forEach(verification => {
          dispatch(changeEtalonVerifications(verification))
        })

        const initialKitAttestations = data.kitAttestations.map(item => item)
        initialKitAttestations.forEach(verification => {
          dispatch(changeEtalonAttestations(verification))
        })

        const initialKitMaintenances = data.kitMaintenances.map(item => item)
        initialKitMaintenances.forEach(verification => {
          dispatch(changeEtalonMaintenances(verification))
        })

        const initialKitCalibrations = data.kitCalibrations.map(item => item)
        initialKitCalibrations.forEach(verification => {
          dispatch(changeEtalonCalibrations(verification))
        })

        const initialKitRepairs = data.kitRepairs.map(item => item)
        initialKitRepairs.forEach(verification => {
          dispatch(changeEtalonRepairs(verification))
        })
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [journal_id, companyId, dispatch])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setGetPrompt(false)

    const data = {
      name: name,
      type: type,
      register_number: registerNumber,
      manufacturer_name: manufacturerName,
      release_year: Number(releaseYear),
      manufacturer_number: manufacturerNumber,
      meas_range: measRange,
      repr_range: reprRange,
      error: error,
      kit_accuracy_class_id: kitAccuracyClassId,
      deviations: deviations,
      software_info: softwareInfo,
      component_info: componentInfo,
      receipt_date: receiptDate,
      commission_date: commissionDate,
      inventory_number: inventoryNumber,
      location: location,
      kitVerifications: kitVerifications,
      kitAttestations: kitAttestations,
      kitMaintenances: kitMaintenances,
      kitCalibrations: kitCalibrations,
      kitRepairs: kitRepairs,
      responsible_passport_name: responsiblePassportName,
      interval_verifications: Number(intervalVerifications),
      interval_attestations: Number(intervalAttestations),
      interval_maintenances: Number(intervalMaintenances),
    }

    const { status, data: responseData } = await R.editKitPassports(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshKitPassport())
      dispatch(resetKitPassport())
      dispatch(resetCreateData())
      navigate('/metriva/journals')
    } else if (status === 422) {
      setErrors(responseData.errors || {})
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не отредактирована - ошибка валидации'))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(responseData.message))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetKitPassport())
      dispatch(resetCreateData())
    }
  }, [])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <BottomSideContainer blockTitle={title}>
            {<span>Информация отсутствует</span>}
          </BottomSideContainer>
          <BackButton
            style={{ marginBottom: '0px' }}
            to='/metriva/journals'
            label='Все записи документа'
          />

          <Typography variant='h2'>Регистрационная карточка № {accountingNumber ?? '-'}</Typography>

          <Content>
            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input label={'Группа СП'} type={'text'} isDisabled={true} value={kitGroup} />
              </LabelFrame>
              <LabelFrame sx={{ width: '32.8%' }}></LabelFrame>
              <LabelFrame sx={{ width: '32.8%' }}></LabelFrame>
            </ContentFrame>
          </Content>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Основные данные
            </Typography>

            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Наименование СП'}
                  placeholder={'Введите полное наименование'}
                  type={'text'}
                  value={name}
                  actions={{
                    change: handleNameChange,
                  }}
                  error={!!errors.name}
                />
                {errors.name && <div className='error'>{errors.name[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Тип'}
                  placeholder={'Укажите тип СП'}
                  type={'text'}
                  value={type}
                  error={!!errors.type}
                  actions={{
                    change: handleTypeChange,
                  }}
                />
                {errors.type && <div className='error'>{errors.type[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Регистрационный номер типа СИ'}
                  placeholder={'Пример ввода - 55964-13'}
                  type={'text'}
                  value={registerNumber}
                  error={!!errors.register_number}
                  actions={{
                    change: handleRegisterNumberChange,
                  }}
                />
                {errors.register_number && <div className='error'>{errors.register_number[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Изготовитель'}
                  placeholder={'Введите название изготовителя'}
                  type={'text'}
                  value={manufacturerName}
                  actions={{
                    change: handleManufacturerNameChange,
                  }}
                  error={!!errors.manufacturer_name}
                />
                {errors.manufacturer_name && (
                  <div className='error'>{errors.manufacturer_name[0]}</div>
                )}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Year releaseYear={releaseYear} setReleaseYear={handleReleaseYearChange} />
                {errors.release_year && <div className='error'>{errors.release_year[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Заводской №'}
                  placeholder={'Пример ввода - 3829JH39'}
                  type={'text'}
                  value={manufacturerNumber}
                  error={!!errors.manufacturer_number}
                  actions={{
                    change: handleManufacturerNumberChange,
                  }}
                />
                {errors.manufacturer_number && (
                  <div className='error'>{errors.manufacturer_number[0]}</div>
                )}
              </LabelFrame>
            </ContentFrame>

            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Метрологические точностные характеристики
            </Typography>

            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Диапазон измерений'}
                  placeholder={'Введите данные о диапазоне измерений'}
                  type={'text'}
                  value={measRange}
                  actions={{
                    change: handleMeasRangeChange,
                  }}
                  error={!!errors.meas_range}
                  notRequired={reprRange ? true : false}
                />
                {errors.meas_range && <div className='error'>{errors.meas_range[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Диапазон воспроизведения'}
                  placeholder={'Введите данные о диапазоне воспроизведения'}
                  type={'text'}
                  value={reprRange}
                  error={!!errors.repr_range}
                  actions={{
                    change: handleReprRangeChange,
                  }}
                  notRequired={measRange ? true : false}
                />
                {errors.repr_range && <div className='error'>{errors.repr_range[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <AccuracyClassSelect
                  kitAccuracyClassId={kitAccuracyClassId}
                  setKitAccuracyClassId={handleKitAccuracyClassIdChange}
                />
                {errors.kit_accuracy_class_id && (
                  <div className='error'>{errors.kit_accuracy_class_id[0]}</div>
                )}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Погрешность'}
                  placeholder={'Введите данные о погрешности'}
                  type={'text'}
                  value={error}
                  error={!!errors.error}
                  actions={{
                    change: handleErrorChange,
                  }}
                  notRequired={kitGroup === 1 ? false : true}
                />
                {errors.error && <div className='error'>{errors.error[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Отклонения'}
                  placeholder={'Укажите отклонения'}
                  type={'text'}
                  value={deviations}
                  error={!!errors.deviations}
                  actions={{
                    change: handleDeviationsChange,
                  }}
                  notRequired={true}
                />
                {errors.deviations && <div className='error'>{errors.deviations[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Сведения о ПО
            </Typography>

            <LabelFrame sx={{ width: '100%' }}>
              <Input
                label={'Сведения о программном обеспечении, включая встроенное (при наличии)'}
                placeholder={'Введите сведения о ПО '}
                type={'text'}
                value={softwareInfo}
                error={!!errors.software_info}
                actions={{
                  change: handleSoftwareInfoChange,
                }}
              />
              {errors.software_info && <div className='error'>{errors.software_info[0]}</div>}
            </LabelFrame>

            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Комплектность
            </Typography>

            <LabelFrame sx={{ width: '100%' }}>
              <Input
                label={'Сведения о комплектующем оборудовании (при наличии)'}
                placeholder={'Введите сведения о комплектующих'}
                type={'text'}
                value={componentInfo}
                error={!!errors.component_info}
                actions={{
                  change: handleComponentInfoChange,
                }}
              />
              {errors.component_info && <div className='error'>{errors.component_info[0]}</div>}
            </LabelFrame>
          </Content>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Сведения по учету
            </Typography>

            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={receiptDate}
                  dateChange={handleReceiptDateChange}
                  label={'Дата поступления СП/составления карточки'}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={commissionDate}
                  dateChange={handleCommissionDateChange}
                  label='Дата ввода в эксплуатацию'
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  type={'text'}
                  placeholder={'Введите полное наименование'}
                  label={'Инвентарный номер'}
                  value={inventoryNumber}
                  error={!!errors.inventory_number}
                  actions={{
                    change: handleInventoryNumberChange,
                  }}
                />
                {errors.inventory_number && (
                  <div className='error'>{errors.inventory_number[0]}</div>
                )}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Место нахождения'}
                  type={'text'}
                  placeholder={'Введите место нахождения поверки'}
                  value={location}
                  error={!!errors.location}
                  actions={{
                    change: handleLocationChange,
                  }}
                />
                {errors.location && <div className='error'>{errors.location[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Ответственный за ведение рег. карточки (паспорта)'}
                  type={'text'}
                  placeholder={'Введите ФИО ответственного'}
                  value={responsiblePassportName}
                  error={!!errors.responsible_passport_name}
                  actions={{
                    change: handlePassportNameChange,
                  }}
                />
                {errors.responsible_passport_name && (
                  <div className='error'>{errors.responsible_passport_name[0]}</div>
                )}
              </LabelFrame>
            </ContentFrame>
          </Content>

          <ContentFrame style={{ gap: 0, flexFlow: 'column' }}>
            <TabsContainer mt='0' />
          </ContentFrame>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
