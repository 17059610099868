// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Input from '../../../../LocalElements/Input/InputMUI'
import { changeProperty } from '../state'

import css from '../style.css'

const { ConditionsContainer, ConditionsBox } = css

const Conditions = () => {
  const dispatch = useDispatch()
  const conditionsValuesEdit = useSelector(state => state.mi15922015Reducer.conditionsValuesEdit)

  const [airTempStart, setAirTempStart] = useState('')
  const [airTempFinish, setAirTempFinish] = useState('')
  const [humidityStart, setHumidityStart] = useState('')
  const [humidityFinish, setHumidityFinish] = useState('')
  const [pressureStart, setPressureStart] = useState('')
  const [pressureFinish, setPressureFinish] = useState('')
  const [waterTempStart, setWaterTempStart] = useState('')
  const [waterTempFinish, setWaterTempFinish] = useState('')

  const handleAirTempStartChange = param => {
    setAirTempStart(param.replace(',', '.'))
  }

  const handleAirTempFinishChange = param => {
    setAirTempFinish(param.replace(',', '.'))
  }

  const handleHumidityStartChange = param => {
    setHumidityStart(param.replace(',', '.'))
  }

  const handleHumidityFinishChange = param => {
    setHumidityFinish(param.replace(',', '.'))
  }

  const handlePressureStartChange = param => {
    setPressureStart(param.replace(',', '.'))
  }

  const handlePressureFinishChange = param => {
    setPressureFinish(param.replace(',', '.'))
  }

  const handleWaterTempStartChange = param => {
    const newValue = param.replace(',', '.')
    setWaterTempStart(newValue)
  }

  const handleWaterTempFinishChange = param => {
    setWaterTempFinish(param.replace(',', '.'))
  }

  useEffect(() => {
    if (conditionsValuesEdit.length > 0) {
      const initialValuesStart = conditionsValuesEdit[0]
      setAirTempStart(initialValuesStart.temperature_environment.toString())
      setHumidityStart(initialValuesStart.relative_humidity.toString())
      setPressureStart(initialValuesStart.atmospheric_pressure.toString())
      if (initialValuesStart && initialValuesStart.water_temp) {
        setWaterTempStart(initialValuesStart.water_temp.toString())
      } else {
        setWaterTempStart('')
      }

      const initialValuesFinish = conditionsValuesEdit[1]
      setAirTempFinish(initialValuesFinish.temperature_environment.toString())
      setHumidityFinish(initialValuesFinish.relative_humidity.toString())
      setPressureFinish(initialValuesFinish.atmospheric_pressure.toString())
      if (initialValuesFinish && initialValuesFinish.water_temp) {
        setWaterTempFinish(initialValuesFinish.water_temp.toString())
      } else {
        setWaterTempFinish('')
      }
    }
  }, [conditionsValuesEdit])

  useEffect(() => {
    if (conditionsValuesEdit.length > 0) {
      const values = [
        {
          id: conditionsValuesEdit[0].id,
          temperature_environment: airTempStart,
          relative_humidity: humidityStart,
          atmospheric_pressure: pressureStart,
          water_temp: waterTempStart,
        },
        {
          id: conditionsValuesEdit[1].id,
          temperature_environment: airTempFinish,
          relative_humidity: humidityFinish,
          atmospheric_pressure: pressureFinish,
          water_temp: waterTempFinish,
        },
      ]

      !!!isNaN(parseFloat(airTempStart)) &&
        dispatch(changeProperty({ value: 'conditions', label: values }))
    }
  }, [
    airTempStart,
    humidityStart,
    pressureStart,
    waterTempStart,
    airTempFinish,
    humidityFinish,
    pressureFinish,
    waterTempFinish,
    dispatch,
  ])

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '18px' }}>Соблюдение условий поверки</h2>

      <ConditionsContainer margin={'0 0 14px'}>
        <h3 style={{ marginBottom: '15px' }}>На начало поверки</h3>
        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура окружающей среды, °C
          </p>
          <Input
            type={'text'}
            value={airTempStart}
            actions={{ change: handleAirTempStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру среды'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Относительная влажность, %
          </p>
          <Input
            type={'text'}
            value={humidityStart}
            actions={{ change: handleHumidityStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните влажность'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Атмосферное давление, кПа
          </p>
          <Input
            type={'text'}
            value={pressureStart}
            actions={{ change: handlePressureStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните атмосферное давление'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 0px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура воды, °С
          </p>
          <Input
            type={'text'}
            value={waterTempStart}
            actions={{ change: handleWaterTempStartChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру воды'}
          />
        </ConditionsBox>
      </ConditionsContainer>

      <ConditionsContainer>
        <h3 style={{ marginBottom: '15px' }}>По окончанию поверки</h3>

        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура окружающей среды, °C
          </p>
          <Input
            type={'text'}
            value={airTempFinish}
            actions={{ change: handleAirTempFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру среды'}
          />
        </ConditionsBox>
        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Относительная влажность, %
          </p>
          <Input
            type={'text'}
            value={humidityFinish}
            actions={{ change: handleHumidityFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните влажность'}
          />
        </ConditionsBox>
        <ConditionsBox margin={'0 0 10px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Атмосферное давление, кПа
          </p>
          <Input
            type={'text'}
            value={pressureFinish}
            actions={{ change: handlePressureFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните атмосферное давление'}
          />
        </ConditionsBox>

        <ConditionsBox margin={'0 0 0px'}>
          <p className='width32Percent' style={{ fontWeight: 500 }}>
            Температура воды, °С
          </p>
          <Input
            type={'text'}
            value={waterTempFinish}
            actions={{ change: handleWaterTempFinishChange }}
            className={'backgroundColorWhite'}
            placeholder={'Заполните температуру воды'}
          />
        </ConditionsBox>
      </ConditionsContainer>
    </React.Fragment>
  )
}

export default Conditions
