import { useDispatch, useSelector } from 'react-redux'
import { changeProperty } from '../components/Methodologies/gas/Gost8324-2002/state.js'

const useGenerateProtocolData = () => {
  const dispatch = useDispatch()

  const getRandomFactor = () => (Math.random() * (2 - 1) + 1) / 1000
  const getRandomBetween = (min, max) => Math.random() * (max - min) + min
  const getSignedRandom = () => 2 * Math.random() - 1

  const correction = parseFloat(useSelector(state => state.gost83242002Reducer.correction)) || 0
  const start_1 = parseFloat(useSelector(state => state.gost83242002Reducer.start_1)) || null

  const q_min = parseFloat(useSelector(state => state.gost83242002Reducer.q_min)) || null
  const q_t = parseFloat(useSelector(state => state.gost83242002Reducer.q_t)) || null
  const q_max = parseFloat(useSelector(state => state.gost83242002Reducer.q_max)) || null

  const raw_q_max_limit_negative = useSelector(
    state => state.gost83242002Reducer.q_max_limit_negative,
  )
  const raw_q_max_limit_positive = useSelector(
    state => state.gost83242002Reducer.q_max_limit_positive,
  )
  const raw_q_min_limit_negative = useSelector(
    state => state.gost83242002Reducer.q_min_limit_negative,
  )
  const raw_q_min_limit_positive = useSelector(
    state => state.gost83242002Reducer.q_min_limit_positive,
  )

  const q_max_limit_negative = raw_q_max_limit_negative
    ? parseFloat(raw_q_max_limit_negative) + 1
    : null
  const q_max_limit_positive = raw_q_max_limit_positive
    ? parseFloat(raw_q_max_limit_positive) - 1
    : null
  const q_min_limit_negative = raw_q_min_limit_negative
    ? parseFloat(raw_q_min_limit_negative) + 1
    : null
  const q_min_limit_positive = raw_q_min_limit_positive
    ? parseFloat(raw_q_min_limit_positive) - 1
    : null

  const generateIndicationData = () => {
    let measurementConsumption_1 = null,
      measurementConsumption_2 = null,
      measurementConsumption_3 = null

    if (q_min !== null) {
      measurementConsumption_1 = parseFloat((q_min - getRandomFactor() * q_min).toFixed(4)).toFixed(
        4,
      )
      dispatch(
        changeProperty({ value: 'measurementConsumption_1', label: measurementConsumption_1 }),
      )
    }

    if (q_t !== null) {
      measurementConsumption_2 = parseFloat((q_t - getRandomFactor() * q_t).toFixed(4)).toFixed(4)
      dispatch(
        changeProperty({ value: 'measurementConsumption_2', label: measurementConsumption_2 }),
      )
    }

    if (q_max !== null) {
      measurementConsumption_3 = parseFloat((q_max - getRandomFactor() * q_max).toFixed(4)).toFixed(
        4,
      )
      dispatch(
        changeProperty({ value: 'measurementConsumption_3', label: measurementConsumption_3 }),
      )
    }

    let volumeAirMeter_1 = null,
      volumeAirMeter_2 = null,
      volumeAirMeter_3 = null

    if (measurementConsumption_1 !== null) {
      volumeAirMeter_1 = parseFloat(
        ((Math.round(((measurementConsumption_1 * 1000) / 3600) * 540) + 2) / 1000).toFixed(4),
      )
      dispatch(changeProperty({ value: 'volumeAirMeter_1', label: volumeAirMeter_1 }))
    }

    if (measurementConsumption_2 !== null) {
      volumeAirMeter_2 = parseFloat(
        ((Math.round(((measurementConsumption_2 * 1000) / 3600) * 300) + 2) / 1000).toFixed(4),
      )
      dispatch(changeProperty({ value: 'volumeAirMeter_2', label: volumeAirMeter_2 }))
    }

    if (measurementConsumption_3 !== null) {
      volumeAirMeter_3 = parseFloat(
        ((Math.round(((measurementConsumption_3 * 1000) / 3600) * 600) + 5) / 1000).toFixed(4),
      )
      dispatch(changeProperty({ value: 'volumeAirMeter_3', label: volumeAirMeter_3 }))
    }

    let permissibleError_1 = null,
      permissibleError = null

    if (q_min_limit_negative !== null && q_min_limit_positive !== null) {
      permissibleError_1 = parseFloat(
        (
          (getRandomBetween(q_min_limit_negative, q_min_limit_positive) + getRandomFactor()) *
            getSignedRandom() +
          correction
        ).toFixed(2),
      )
      dispatch(changeProperty({ value: 'permissibleError_1', label: permissibleError_1 }))
    }

    if (q_max_limit_negative !== null && q_max_limit_positive !== null) {
      permissibleError = parseFloat(
        (
          (getRandomBetween(q_max_limit_negative, q_max_limit_positive) + getRandomFactor()) *
            getSignedRandom() +
          correction
        ).toFixed(2),
      )
      dispatch(changeProperty({ value: 'permissibleError_2', label: permissibleError }))
      dispatch(changeProperty({ value: 'permissibleError_3', label: permissibleError }))
    }

    if (volumeAirMeter_1 !== null && permissibleError_1 !== null) {
      const volumeEtalon_1 = ((100 * volumeAirMeter_1) / (100 + permissibleError_1)).toFixed(6)
      dispatch(changeProperty({ value: 'volumeEtalon_1', label: volumeEtalon_1 }))
    }

    if (volumeAirMeter_2 !== null && permissibleError !== null) {
      const volumeEtalon_2 = ((100 * volumeAirMeter_2) / (100 + permissibleError)).toFixed(6)
      dispatch(changeProperty({ value: 'volumeEtalon_2', label: volumeEtalon_2 }))
    }

    if (volumeAirMeter_3 !== null && permissibleError !== null) {
      const volumeEtalon_3 = ((100 * volumeAirMeter_3) / (100 + permissibleError)).toFixed(6)
      dispatch(changeProperty({ value: 'volumeEtalon_3', label: volumeEtalon_3 }))
    }

    if (
      start_1 !== null &&
      volumeAirMeter_1 !== null &&
      volumeAirMeter_2 !== null &&
      volumeAirMeter_3 !== null
    ) {
      const finish_1 = parseFloat((start_1 + volumeAirMeter_1).toFixed(3))
      const start_2 = parseFloat((finish_1 + getRandomBetween(5, 7) / 1000).toFixed(3))
      const finish_2 = parseFloat((start_2 + volumeAirMeter_2).toFixed(3))
      const start_3 = parseFloat((finish_2 + getRandomBetween(8, 10) / 1000).toFixed(3))
      const finish_3 = parseFloat((start_3 + volumeAirMeter_3).toFixed(3))

      dispatch(changeProperty({ value: 'finish_1', label: finish_1 }))
      dispatch(changeProperty({ value: 'start_2', label: start_2 }))
      dispatch(changeProperty({ value: 'finish_2', label: finish_2 }))
      dispatch(changeProperty({ value: 'start_3', label: start_3 }))
      dispatch(changeProperty({ value: 'finish_3', label: finish_3 }))
    }
  }

  const defaultIndicationData = () => {
    dispatch(
      changeProperty({
        value: 'start_1',
        label: null,
      }),
    )

    dispatch(
      changeProperty({
        value: 'finish_1',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeEtalon_1',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'measurementConsumption_1',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_1',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'permissibleError_1',
        label: null,
      }),
    )

    dispatch(
      changeProperty({
        value: 'start_2',
        label: null,
      }),
    )

    dispatch(
      changeProperty({
        value: 'finish_2',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeEtalon_2',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'measurementConsumption_2',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_2',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'permissibleError_2',
        label: null,
      }),
    )

    dispatch(
      changeProperty({
        value: 'start_3',
        label: null,
      }),
    )

    dispatch(
      changeProperty({
        value: 'finish_3',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeEtalon_3',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'measurementConsumption_3',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'volumeAirMeter_3',
        label: null,
      }),
    )
    dispatch(
      changeProperty({
        value: 'permissibleError_3',
        label: null,
      }),
    )
  }

  return {
    generateIndicationData,
    defaultIndicationData,
  }
}

export default useGenerateProtocolData
