// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import R from '../../../../services/app/client-server/request.service.js'
import Table from '../../LocalElements/Table/Table'
import { refreshTickets, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Fab, useMediaQuery } from '@mui/material'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Users from '../../LocalElements/Filter/TicketsMasterArshin'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import PencilIcon from '../../../../img/icon/PencilIcon'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setSearchQuery,
  changeTickets,
  resetTickets,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import Tooltip from '../../../../ui/tooltip/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index.tsx'
import theme from '../../../../utils/theme.ts'
import { Container, CountLabel, Filter, IconFrame, Wrapper, FrameFilter } from '../style'

import { archiveTitle, archiveMessage, ModalContent } from '../archiveModal/archiveModal'

const TabTickets = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const { handleDownload } = useExcelCsvPdf()

  const data = useSelector(state => state.journalReducer.dataTickets)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const measurementId = useSelector(state => state.journalReducer.tickets.measurementId)
  const arshinName = useSelector(state => state.journalReducer.tickets.arshinName)
  const createdAtFrom = useSelector(state => state.journalReducer.tickets.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.tickets.createdAtTo)

  const isChecked = useSelector(state => state.journalReducer.tickets.isChecked)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.tickets.verificationDateFrom,
  )
  const verificationDateTo = useSelector(state => state.journalReducer.tickets.verificationDateTo)
  const completenessStatus = useSelector(state => state.journalReducer.tickets.completenessStatus)
  const createdByRole = useSelector(state => state.journalReducer.tickets.createdByRole)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const journalId = useSelector(state => state.journalReducer.journalId)
  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData,
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date

  const entryId = useSelector(state => state.journalReducer.entryId)
  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementId) filtersCount++
    if (arshinName) filtersCount++
    if (createdAtFrom) filtersCount++
    if (createdAtTo) filtersCount++
    if (isChecked) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++

    if (
      !measurementId &&
      !arshinName &&
      !createdAtFrom &&
      !createdAtTo &&
      !isChecked &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !completenessStatus &&
      !createdByRole
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementId,
    arshinName,
    createdAtFrom,
    createdAtTo,
    isChecked,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
  ])

  const handleResetFilter = () => {
    dispatch(resetTickets())
  }
  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }
  const changeMeasurementId = param => {
    dispatch(changeTickets({ value: 'measurementId', label: param }))
  }
  const changeUserId = param => {
    dispatch(changeTickets({ value: 'arshinName', label: param }))
  }
  const changeIsChecked = event => {
    dispatch(changeTickets({ value: 'isChecked', label: event.target.value }))
  }
  const changeCompletenessStatus = event => {
    dispatch(changeTickets({ value: 'completenessStatus', label: event.target.value }))
  }
  const changeCreatedByRole = event => {
    dispatch(changeTickets({ value: 'createdByRole', label: event.target.value }))
  }
  const handleCreatedAtFromChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'createdAtFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'createdAtFrom', label: '' }))
    }
  }
  const handleCreatedAtToChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'createdAtTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'createdAtTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changeTickets({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeTickets({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deleteTickets(companyId, journalId)
    if (status === 200) {
      dispatch(refreshTickets())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
      dispatch(resetState())
    }
  }

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
    },
    {
      field: 'contactDate',
      headerName: 'Дата заявки',
    },
    {
      field: 'customerArshinName',
      headerName: 'Владелец СИ',
    },
    {
      field: 'customerFullName',
      headerName: 'ФИО заказчика',
    },
    {
      field: 'phone',
      headerName: 'Контактный телефон',
    },
    {
      field: 'customerAddress',
      headerName: 'Адрес поверки',
    },
    {
      field: 'verificationDate',
      headerName: 'Плановая дата поверки',
    },
    {
      field: 'isChecked',
      headerName: 'Отметка о поверке',
      renderCell: params => {
        return params.value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CheckCircleIcon fontSize='small' sx={{ color: '#21E1B3' }} />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CancelIcon fontSize='small' sx={{ color: '#FF5963' }} />
          </div>
        )
      },
    },
    {
      field: 'createdBy',
      headerName: 'ФИО инженера по метрологии',
    },
    {
      field: 'agreementNumber',
      headerName: 'Номер договора',
    },
    {
      field: 'comment',
      headerName: 'Примечание',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Редактировать запись'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                <PencilIcon />
              </div>
            </Tooltip>
            <Tooltip title='Удалить запись'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(showBA(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_tickets')
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_tickets`)
  }

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveTickets(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshTickets())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(
        setTitle(
          <Typography variant='subtitle1' sx={{ color: theme.palette.secondary.dark }}>
            Архивация приостановлена
          </Typography>,
        ),
      )
      dispatch(
        setChildren(
          <div style={{ width: '620px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant='subtitle1'>
              Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
              некоторых записях документа
            </Typography>
            <Typography variant='subtitle1'>
              Пожалуйста, найдите такие записи с помощью фильтра "Заполнение записи", дождитесь
              появления / внесите недостающие данные и повторите процесс архивации.
            </Typography>
          </div>,
        ),
      )
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )
    const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Zhurnal_registracii_zayavok_na_poverku_(arhiv)_${currentDate}.pdf`,
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (archiveDateFromBySettings && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFrom, archiveDateTo])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={archiveDateFromBySettings}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  const StaticControls = (
    <IconFrame>
      {count > 0 ? (
        <Tooltip title='Для скачивания PDF отключите фильтры'>
          <div style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}>
            <Pdf />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title='Скачать в формате PDF'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => {
              !entryId && handleDownload('pdf')
              !!entryId && handleDownloadSelectionPdf()
            }}
          >
            <Pdf />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Csv'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('csv')}
          >
            <Csv />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Excel'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('excel')}
          >
            <Excel />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <React.Fragment>
          {count > 0 ? (
            <Tooltip title='Для архивации документа отключите фильтры'>
              <div
                style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}
              >
                <ArchiveIcon />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Архивировать документ'>
              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <ArchiveIcon
                  onClick={() => {
                    dispatch(setTitle('Архивация документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(setBA('Архивировать'))
                    dispatch(setBD('Отменить'))
                    dispatch(showBD(true))
                    dispatch(setConfirmAction(handleArchiveClick))
                    dispatch(setOpen(true))
                  }}
                />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </IconFrame>
  )

  return (
    <React.Fragment>
      <RightSideContainer blockTitle={'Фильтры'}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle1'>Область измерения </Typography>
            <Measurement
              measurementId={measurementId}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle1'>ФИО инженера по метрологии</Typography>
            <Users userId={arshinName} setUserId={changeUserId} />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle1'>Отметка о поверке</Typography>
            <RadioButton
              value={isChecked}
              onChange={changeIsChecked}
              trueLabel={'Да'}
              falseLabel={'Нет'}
              trueValue={'checked'}
              falseValue={'not-checked'}
            />
          </FrameFilter>

          {!entryId && (
            <FrameFilter>
              <Typography variant='subtitle1'>Заполнение записи</Typography>
              <RadioButton
                value={completenessStatus}
                onChange={changeCompletenessStatus}
                trueLabel={'Частичное'}
                falseLabel={'Полное'}
                trueValue={'non-complete'}
                falseValue={'complete'}
              />
            </FrameFilter>
          )}

          <FrameFilter>
            <Typography variant='subtitle1'>Создатель записи</Typography>
            <RadioButton
              value={createdByRole}
              onChange={changeCreatedByRole}
              trueLabel={'Система'}
              falseLabel={'Пользователь'}
              trueValue={'system'}
              falseValue={'user'}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Плановая дата поверки</Typography>
            <DatePicker selectedDate={createdAtFrom} dateChange={handleCreatedAtFromChange} />

            <DatePicker selectedDate={createdAtTo} dateChange={handleCreatedAtToChange} />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата создания</Typography>
            <DatePicker
              selectedDate={verificationDateFrom}
              dateChange={handleVerificationDateFromChange}
            />

            <DatePicker
              selectedDate={verificationDateTo}
              dateChange={handleVerificationDateToChange}
            />
          </FrameFilter>
          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>
      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          number: item?.number,
          contactDate: item?.contact_date,
          customerFullName: item?.customer_fullname || '-',
          phone: item.customer_phone && item.customer_phone.length > 6 ? item.customer_phone : '-',
          customerAddress: item?.customer_address,
          verificationDate: item?.planned_date,
          isChecked: item?.is_checked,
          customerArshinName: item?.customer_arshin_name,
          createdBy: item?.arshin_master_name,
          agreementNumber: item?.agreement_number ? item?.agreement_number : '-',
          comment: item?.comment ? item?.comment : '-',
        }))}
        hasCheckboxSelection={false}
        showToolbar={true}
        noCreating={!entryId ? false : true}
        actions={{
          static: StaticControls,
          dinamic: null,
        }}
        filtersVariant={'server'}
        addButtonCallback={handleAddClick}
        setIsDrawerOpenServer={() => {
          dispatch(setOpenRightContainer(true))
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </React.Fragment>
  )
}

export default TabTickets
