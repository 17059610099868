import React, { useState, useEffect } from 'react'
import api from '../../../services/app/client-server/axios-config.service'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { useDispatch, useSelector } from 'react-redux'

import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { changeData } from '../../../store/slices/app/views/users'

const SelectedUsers = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.authReducer.authData.token)

  const rolesId = useSelector(state => state.usersReducer.createData.rolesId)
  const userId = useSelector(state => state.usersReducer.createData.userId)
  const usersId = useSelector(state => state.usersReducer.createData.usersId)
  const companyId = useSelector(state => state.usersReducer.createData.companyId)

  const [userData, setUserData] = useState(null)
  const [usersData, setUsersData] = useState(null)

  const optionUser = usersData
    ? usersData.map(data => ({
        value: data.id,
        label: `${data.surname} ${data.name}`,
      }))
    : []

  const optionUsers = userData
    ? userData.map(data => ({
        value: data.id,
        label: `${data.surname} ${data.name}`,
      }))
    : []

  const getValueUser = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeData({
          value: 'userId',
          label: selectedOption.value,
        }),
      )

      dispatch(changeData({ value: 'usersId', label: [] }))
    } else {
      dispatch(
        changeData({
          value: 'userId',
          label: null,
        }),
      )

      dispatch(changeData({ value: 'usersId', label: [] }))
    }
  }

  const getValueUsers = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(changeData({ value: 'usersId', label: selectedIds }))
    dispatch(
      changeData({
        value: 'userId',
        label: null,
      }),
    )

    if (!selectedOptions) {
      dispatch(changeData({ value: 'usersId', label: [] }))
      dispatch(
        changeData({
          value: 'userId',
          label: null,
        }),
      )
    }
  }

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/users/list`

        if (companyId) {
          apiUrl += `?company_id=${companyId}`
        }

        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })

        const metrologists = response.data.data.filter(user => user.role === 'metrologist')
        const brigadiers = response.data.data.filter(user => user.role === 'brigadier')

        setUserData(metrologists)
        setUsersData(brigadiers)
      } catch (error) {
        console.error(error)
      }
    }

    fetchUsersData()
  }, [companyId, token])

  const hasUsers = Array.isArray(usersId) && usersId.length > 0

  return (
    <React.Fragment>
      {rolesId === 'metrologist' && (
        <React.Fragment>
          <span
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Ответственный над поверителем
            {!userId ? (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-2px',
                }}
              />
            ) : (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '-2px' }} />
            )}
          </span>
          <Selector
            placeholder={'Выберите руководителя '}
            options={optionUser}
            value={optionUser.find(option => option.value === userId)}
            onChange={getValueUser}
            isSearchable={false}
            noOptionsMessage={() => 'Нет доступных руководителей'}
          />
        </React.Fragment>
      )}

      {rolesId === 'brigadier' && (
        <React.Fragment>
          <span
            style={{
              marginBottom: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Подконтрольные поверители
            {hasUsers ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px', marginTop: '-2px' }} />
            ) : (
              <ErrorIcon
                sx={{
                  color: '#FFD43B',
                  marginLeft: '8px',
                  marginTop: '-2px',
                }}
              />
            )}
          </span>
          <Selector
            placeholder={'Поверители'}
            options={optionUsers}
            value={optionUsers.filter(option => usersId.includes(option.value))}
            onChange={getValueUsers}
            isSearchable={false}
            isMulti={true}
            noOptionsMessage={() => 'Нет доступных метрологов'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default SelectedUsers
