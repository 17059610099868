import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ImageUploading from 'react-images-uploading'
import { Button, Typography, IconButton, Checkbox } from '@mui/material'

import AddNewCircleIcon from '../../../../../../img/icon/AddNewCircleIcon'
import DownloadIcon from '../../../../../../img/icon/DownloadIcon'
import Replace from '../../../../../../img/icon/Replace'
import SearchIcon from '../../../../../../img/icon/SearchIcon'
import DeleteIcon from '../../../../../../img/icon/DeleteIcon'

import Tooltip from '../../../../../../ui/tooltip/index.tsx'

import {
  setOpen,
  setTitle,
  setChildren,
  setBA,
  setBD,
  showBD,
} from '../../../../../../store/slices/app/comps/modal'

const AddImage = ({ title, count }) => {
  const dispatch = useDispatch()

  const [images, setImages] = useState([])

  const onChange = imageList => {
    setImages(imageList.map(image => image.file))
  }

  const modalContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Typography variant='subtitle2'>{title}</Typography>
      <Typography variant='subtitle2'>
        Данные ЦФ должны быть читаемыми и распознаваемыми.
      </Typography>

      <div>
        <ImageUploading multiple value={images} onChange={onChange} maxFileSize={5242880}>
          {({ onImageUpload, onImageUpdate }) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {false ? (
                  <Button
                    variant='text'
                    onClick={() => {}}
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '11px 15px',
                      borderRadius: '12px',
                      color: '#4B5962',
                    }}
                  >
                    Отменить выделение
                  </Button>
                ) : (
                  <Button
                    variant='text'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '11px 15px',
                      borderRadius: '12px',
                      color: '#4B5962',
                    }}
                    onClick={() => {}}
                  >
                    Выделить все
                  </Button>
                )}
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='text'
                    startIcon={<DownloadIcon />}
                    onClick={() => {}}
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '11px 15px',
                      borderRadius: '12px',
                      color: '#4B5962',
                    }}
                  >
                    Скачать
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => {}}
                    startIcon={<DeleteIcon />}
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '11px 15px',
                      borderRadius: '12px',
                      color: '#4B5962',
                    }}
                  >
                    Удалить
                  </Button>
                </div>
              </div>

              {images.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    gap: '16px',
                    flexWrap: 'wrap',
                  }}
                >
                  {images.map((image, index) => (
                    <div
                      key={`image-${index}`}
                      style={{
                        position: 'relative',
                        width: '210px',
                        height: '180px',
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          background: 'white',
                          margin: '10px',
                          borderRadius: '12px',
                        }}
                      />
                      <img
                        src={URL.createObjectURL(image)}
                        alt='Данные ЦФ'
                        width='180px'
                        height='180px'
                        style={{ objectFit: 'cover', borderRadius: '8px' }}
                        onClick={() => {}}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Tooltip title='Заменить изображение'>
                          <IconButton aria-label='replace' onClick={() => onImageUpdate(index)}>
                            <Replace />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Увеличить изображение'>
                          <IconButton aria-label='zoom' onClick={() => {}}>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div
                className='btn_container'
                style={{
                  gap: '16px',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                }}
              >
                <div className='image-upload-container'>
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        marginTop: '40px',
                      }}
                    >
                      <DownloadIcon />
                      <h4
                        style={{
                          display: 'block',
                          textAlign: 'left',
                          fontWeight: '400',
                        }}
                      >
                        Чтобы загрузить изображения, перетащите их сюда или нажмите
                        <span
                          style={{
                            color: '#0084E2',
                            marginLeft: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={onImageUpload}
                        >
                          здесь
                        </span>
                      </h4>
                    </div>
                    <span
                      style={{
                        display: 'block',
                        color: 'gray',
                        marginTop: '14px',
                        opacity: 0.8,
                        lineHeight: '24px',
                        textAlign: 'left',
                      }}
                    >
                      Не более {count} изображений размером до 5 МБ
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  )

  return (
    <>
      <AddNewCircleIcon
        onClick={() => {
          dispatch(setTitle('Добавление материалов фотофиксации'))
          dispatch(setChildren(modalContent))
          dispatch(setBA('Сохранить'))
          dispatch(setBD('Отменить'))
          dispatch(showBD(true))
          dispatch(setOpen(true))
        }}
      />
    </>
  )
}

export default AddImage
