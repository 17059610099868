// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import NavBarMobile from '../components/NavBarMobile'
import NavBarMobileHeader from '../components/NavBarMobileHeader.tsx'
import Feedback from '../components/LocalElements/Feedback/Feedback'

import HelloScreen from './Auth/HelloScreen'

import RoutesComponent from '../../routes/RouteComponent'
import PositionedSnackbar from '../components/LocalElements/Snackbar/Snackbar'
import ModalComponent from '../components/LocalElements/Modal/Modal'
import FileViewerComponent from '../components/LocalElements/Modal/FileViewer'
import ImageViewerComponent from '../components/LocalElements/Modal/ImageViewer'

import ErrorBoundary from '../../services/app/processes/error-boundary.service'

const Home = () => {
  const [readyData, setReadyData] = useState(false)

  return (
    <React.Fragment>
      {true ? (
        <React.Fragment>
          <section
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Feedback />
            <NavBarMobileHeader />
            <ErrorBoundary key={new Date().getTime()}>
              <RoutesComponent />
            </ErrorBoundary>
            <PositionedSnackbar />
            <ModalComponent />
            <FileViewerComponent />
            <ImageViewerComponent />
            <NavBarMobile />
          </section>
        </React.Fragment>
      ) : (
        <HelloScreen />
      )}
    </React.Fragment>
  )
}

export default React.memo(Home)
