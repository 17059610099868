// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable react/jsx-no-target-blank */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'

import Table from '../../LocalElements/Table/Table'
import TableMobile from '../../LocalElements/Table/TableMobile.tsx'
import Tooltip from '../../../../ui/tooltip/index.tsx'
import PdfDownloadButton from '../../../../services/app/files/pdf-meters.service.js'

import { setSelectedMeters } from '../../../../store/slices/app/views/meters.js'
import { settingsFileViewer } from '../../../../store/slices/app/comps/modal.js'

import { setName } from '../../../../store/slices/app/controlers/pageName.js'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer.js'

import useWindowDimensions from '../../../hooks/useWindowDimensions.js'

const Tab = () => {
  const { width } = useWindowDimensions()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector(state => state.meterReducer.metersList)
  const idMeasurement = useSelector(state => state.meterReducer.measurement_id)

  const methods = useSelector(state => state.meterReducer.filterParams.selectedMethods)
  const customers = useSelector(state => state.meterReducer.filterParams.selectedCustomers)
  const titles = useSelector(state => state.meterReducer.filterParams.selectedTitles)
  const linkSI = useSelector(state => state.meterReducer.filterParams.isLinkSI)
  const pdf = useSelector(state => state.meterReducer.filterParams.isPDF)

  const handleShowClick = param => {
    width > 1050 && navigate(`/metriva/meters/${param.row.id}`)
  }

  let colData =
    width > 1050
      ? [
          {
            field: 'numberRegistry',
            headerName: 'Номер в реестре ФИФ',
          },
          {
            field: 'nameSi',
            headerName: 'Наименование',
          },
          {
            field: 'manufacturerName',
            headerName: 'Завод производитель',
          },
          {
            field: 'typeLink',
            headerName: 'Ссылка на прибор',
            renderCell: params => {
              return (
                <span
                  style={{ color: 'red' }}
                  onClick={event => {
                    event.stopPropagation()

                    false && console.log(params.row)
                  }}
                >
                  <a href={params.row.typeLink} target='_blank' style={{ color: '#0093FD' }}>
                    Ссылка на ФГИС Аршин
                  </a>
                </span>
              )
            },
          },
        ]
      : [
          {
            field: 'numberRegistry',
            headerName: 'Номер в реестре ФИФ',
          },
          {
            field: 'nameSi',
            headerName: 'Наименование',
          },
          {
            field: 'manufacturerName',
            headerName: 'Завод производитель',
          },
          {
            field: 'typeLink',
            headerName: 'Ссылка на прибор',
            renderCell: params => {
              return (
                <span>
                  <a href={params.row.typeLink} target='_blank' style={{ color: '#0093FD' }}>
                    Ссылка на ФГИС Аршин
                  </a>
                </span>
              )
            },
          },
        ]

  if (width > 1050) {
    colData.splice(8, 0, {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Tooltip title='Скачать PDF с описанием типа'>
              <PdfDownloadButton
                measurementId={idMeasurement}
                meterId={localItem.id}
                title={localItem.row.numberRegistry}
              />
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => {
    dispatch(setName('База приборов'))
  }, [])

  useEffect(() => {
    dispatch(settingsFileViewer({ value: 'open', label: false }))
  }, [])

  return (
    <React.Fragment>
      {(!data || data.length === 0) && (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
            }}
          >
            <Skeleton
              sx={{ backgroundColor: '#fff' }}
              animation='wave'
              width={'100%'}
              height={66}
            />
            <Skeleton sx={{ marginTop: '-16px' }} animation='wave' width={'100%'} height={66} />
            <Skeleton
              sx={{ backgroundColor: '#fff', marginTop: '-16px' }}
              animation='wave'
              width={'100%'}
              height={66}
            />
            <Skeleton sx={{ marginTop: '-16px' }} animation='wave' width={'100%'} height={66} />
            <Skeleton
              sx={{ backgroundColor: '#fff', marginTop: '-16px' }}
              animation='wave'
              width={'100%'}
              height={66}
            />
            <Skeleton sx={{ marginTop: '-16px' }} animation='wave' width={'100%'} height={66} />
          </div>
        </React.Fragment>
      )}
      {data && data.length > 0 && (
        <React.Fragment>
          {width > 100 ? (
            <Table
              colData={colData}
              rowData={data
                .filter(rowData => rowData.manufacturer_name.indexOf(customers) !== -1)
                .filter(rowData => rowData.name_si.indexOf(titles) !== -1)
                .filter(rowData => {
                  if (methods.length === 0) return rowData
                  if (methods.length > 0) {
                    if (
                      rowData.methods
                        .map(method => method.name)
                        .some(method => methods.includes(method))
                    ) {
                      return rowData
                    }
                  }
                })
                .filter(rowData => {
                  if (linkSI === null) return rowData
                  if (+linkSI === 0) return !!!rowData.type_link ? rowData : null
                  if (+linkSI === 1) return rowData.type_link ? rowData : null
                })
                .filter(rowData => {
                  if (pdf === null) return rowData
                  if (+pdf === 0) return !!!rowData.is_has_pdf ? rowData : null
                  if (+pdf === 1) return rowData.is_has_pdf ? rowData : null
                })
                .map(item => ({
                  id: item.fif_number,
                  numberRegistry: item.fif_number,
                  nameSi: item.name_si,
                  manufacturerName: item.manufacturer_name,
                  typeLink: item.type_link,
                }))}
              hasCheckboxSelection={false}
              rowCallback={handleShowClick}
              showToolbar={true}
              noCreating={true}
              filtersVariant={'server'}
              setIsDrawerOpenServer={() => {
                dispatch(setOpenRightContainer(true))
              }}
              rowSelectionCallback={array => {
                dispatch(setSelectedMeters(array))
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          ) : (
            <TableMobile
              css={{}}
              rowCallback={() => {}}
              rowCallbackParams={''}
              data={data.map(item => {
                return [
                  ['ID', item.id],
                  ['args', []],
                  ['№ СИ в госреестре', item.fif_number],
                  ['Наименование', item.name_si],
                  ['Изготовитель', item.manufacturer_name],
                  [
                    'Утвержд. типы СИ',
                    <a
                      href={item.type_link}
                      target='_blank'
                      style={{ color: 'rgb(0, 132, 226)', textAlign: 'right' }}
                    >
                      Ссылка на ФГИС Аршин
                    </a>,
                  ],
                ]
              })}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Tab
