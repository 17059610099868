// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'
import R from '../../../services/app/client-server/request.service'
import { LabelFrame } from '../../pages/Cases/style'

const MethodPoint = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const methodId = useSelector(state => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)
  const methodPlaces = useSelector(state => state.casesReducer.createData.methodPlaces)
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)

  const options =
    methodId && typeMethodId?.length > 0 && methodPlaces?.length > 0 && data
      ? data.map(data => {
          const additional = data?.additional ? `${data.additional}, ` : ''

          return {
            value: data.id,
            label: `${data.name} (${additional}${
              data?.verification_method === 'field' ? 'выездная' : 'лабораторная'
            } ${data?.verification_type === 'periodic' ? 'периодическая' : 'начальная'} поверка) `,
          }
        })
      : []

  const noOptionsMessage = () =>
    methodId && typeMethodId?.length > 0 && methodPlaces?.length > 0
      ? 'Вы выбрали все доступные пункты для этой методики'
      : 'Выберите методику, способ проведения и тип поверки'

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(
      changeData({
        value: 'methodPoints',
        label: selectedIds,
      }),
    )
    dispatch(changeData({ value: 'deviceIds', label: [] }))
    if (!selectedOptions) {
      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        }),
      )
    }
    dispatch(changeData({ value: 'deviceIds', label: [] }))
  }

  useEffect(() => {
    if (methodId) {
      ;(async () => {
        const queryString =
          `?method_ids=${methodId}` +
          typeMethodId.map(type => `&verification_types[]=${type}`).join('') +
          methodPlaces.map(place => `&method_places[]=${place}`).join('')

        const { data: listPoint } = await R.getPointItem(queryString)
        const point = listPoint.data
        setData(point)
      })()
    }
  }, [methodId, typeMethodId, methodPlaces])

  return (
    <LabelFrame>
      <Selector
        placeholder={'Выберите необходимые пункты'}
        isRequired={true}
        label={{
          text: 'Пункты методики',
          topBg: 'white',
          bottomBg: 'white',
        }}
        isClearable={false}
        isSearchable={false}
        isMulti={true}
        options={options}
        onChange={getValue}
        value={options.filter(option => methodPointIds.includes(option.value))}
        noOptionsMessage={noOptionsMessage}
      />
    </LabelFrame>
  )
}

export default MethodPoint
