import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
  selectedProtocols: [],
  protocolsList: [],
  meta: null | undefined,
  isLoading: true,
  userId: '',
  statusId: '',
  dateFrom: '',
  measurementIdFilter: '',
  approvedTransfer: '',
  approvedArshin: '',
  approvedFsa: '',
  dateTo: '',
  viewVariantMain: 0,
  searchQuery: '',
  stepNumber: 0,

  companyId: '',
  measurementId: '',
  methodId: '',
  dateVerification: '',
  case: {
    caseId: '',
    verificationType: '',
    opticalSensor: '',
    pointMethod: [],
    pointId: null,
    method: '',
  },
}

const protocolReducer = createSlice({
  name: 'protocolReducer',
  initialState,
  reducers: {
    setSelectedProtocols: (state, action) => {
      state.selectedProtocols = action.payload
    },
    setList: (state, action) => {
      state.protocolsList = action.payload
    },
    setMeta: (state, action) => {
      state.meta = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setStatusId: (state, action) => {
      state.statusId = action.payload
    },
    setDateFrom: (state, action) => {
      state.dateFrom = action.payload
    },
    setDateTo: (state, action) => {
      state.dateTo = action.payload
    },
    setMeasurementIdFilter: (state, action) => {
      state.measurementIdFilter = action.payload
    },
    setApprovedTransfer: (state, action) => {
      state.approvedTransfer = action.payload
    },
    setApprovedArshin: (state, action) => {
      state.approvedArshin = action.payload
    },
    setApprovedFsa: (state, action) => {
      state.approvedFsa = action.payload
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload
    },
    resetCompanyId: state => {
      state.companyId = initialState.companyId
    },
    setMeasurementId: (state, action) => {
      state.measurementId = action.payload
    },
    resetMeasurementId: state => {
      state.measurementId = initialState.measurementId
    },
    setMethodId: (state, action) => {
      state.methodId = action.payload
    },
    resetMethodId: state => {
      state.methodId = initialState.methodId
    },
    changeCase: (state, action) => {
      state.case[action.payload.value] = action.payload.label
    },
    setDateVerification: (state, action) => {
      state.dateVerification = action.payload
    },
    setViewVariantMain: (state, action) => {
      state.viewVariantMain = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    resetCase: state => {
      state.case = initialState.case
    },
    setStepNumber: (state, action) => {
      state.stepNumber = action.payload
    },
  },
})

export const {
  setSelectedProtocols,
  setList,
  setMeta,
  setIsLoading,
  setUserId,
  setStatusId,
  setDateFrom,
  setDateTo,
  setMeasurementIdFilter,
  setApprovedTransfer,
  setApprovedArshin,
  setApprovedFsa,
  setCompanyId,
  resetCompanyId,
  setMeasurementId,
  resetMeasurementId,
  setMethodId,
  resetMethodId,
  changeCase,
  setDateVerification,
  setViewVariantMain,
  setSearchQuery,
  resetCase,
  setStepNumber,
} = protocolReducer.actions
export default protocolReducer.reducer
