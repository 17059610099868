// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import Button from '../../../ui/button/index.tsx'
import css from '../../components/Methodologies/styles.css.js'

import { setName } from '../../../store/slices/app/controlers/pageName.js'

const { PageContainer, BoxContainer } = css

const Save = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setName('Протокол сохранен'))
  }, [])

  return (
    <React.Fragment>
      <PageContainer style={{ gap: '14px', justifyContent: 'center', padding: '0px 5%' }}>
        <BoxContainer
          style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '10px' }}
          margin={'0px 0px 10px'}
        >
          <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M12.9382 2.77841C12.7931 2.73181 12.6345 2.74311 12.4998 2.80804C12.3523 2.87913 12.2548 3.0032 12.2197 3.13821L11.744 4.97206C11.5777 5.61339 11.3354 6.23198 11.0235 6.81599C10.5392 7.72267 9.80632 8.42 9.12647 9.00585L7.68773 10.2456C7.46475 10.4378 7.3474 10.7258 7.37282 11.0198L8.18498 20.4125C8.22601 20.887 8.62244 21.25 9.09635 21.25H13.745C16.8813 21.25 19.5238 19.0677 20.0306 16.1371L20.7361 12.0574C20.8332 11.4959 20.4014 10.9842 19.8348 10.9842H14.6537C13.6766 10.9842 12.9344 10.1076 13.0921 9.14471L13.7548 5.10015C13.8456 4.54613 13.8197 3.97923 13.6787 3.43584C13.6072 3.16009 13.3896 2.92342 13.0832 2.82498L12.9382 2.77841L13.1676 2.06435L12.9382 2.77841ZM11.8486 1.45674C12.3312 1.2242 12.8873 1.18654 13.397 1.35029L13.542 1.39686L13.3126 2.11092L13.542 1.39686C14.319 1.64648 14.9252 2.26719 15.1307 3.0592C15.3241 3.80477 15.3596 4.58256 15.2351 5.34268L14.5724 9.38724C14.5639 9.439 14.6038 9.4842 14.6537 9.4842H19.8348C21.3341 9.4842 22.4695 10.8365 22.2142 12.313L21.5087 16.3928C20.8708 20.081 17.5712 22.75 13.745 22.75H9.09635C7.8427 22.75 6.79852 21.7902 6.69056 20.5417L5.8784 11.149C5.81149 10.3753 6.12022 9.61631 6.70855 9.10933L8.14729 7.86954C8.8025 7.30492 9.35404 6.75767 9.70042 6.10924C9.95699 5.62892 10.1557 5.12107 10.2921 4.59542L10.7678 2.76157C10.917 2.18627 11.3166 1.71309 11.8486 1.45674ZM3.46767 9.4849C3.86893 9.46758 4.21261 9.76945 4.24721 10.1696L5.21881 21.4061C5.28122 22.1279 4.71268 22.75 3.98671 22.75C3.30289 22.75 2.75 22.1953 2.75 21.5127V10.2342C2.75 9.83256 3.0664 9.50221 3.46767 9.4849Z'
              fill='#1C274C'
            />
          </svg>
          <Typography variant='h2'>Протокол сохранен</Typography>
          <span style={{ textAlign: 'center' }}>
            Вы можете найти сохраненные протоколы в разделе "Протоколы"
          </span>
        </BoxContainer>
        <Button
          onClick={() => {
            navigate('/metriva/protocol')
          }}
          label='Новый протокол'
          sx={{
            textTransform: 'none',
            fontSize: '16px',
            flexGrow: 3,
            padding: '16px 24px',
            borderRadius: '8px',
            backgroundColor: '#0084E2',
            color: 'white',
            boxSizing: 'border-box',
            '&:disabled': {
              backgroundColor: 'grey',
            },
          }}
        />
        <Button
          onClick={() => {
            navigate('/metriva/protocols')
          }}
          label='Список протоколов'
          color='secondary'
          sx={{
            textTransform: 'none',
            fontSize: '16px',
            flexGrow: 3,
            padding: '16px 24px',
            borderRadius: '8px',
            backgroundColor: '#0084E2',
            color: 'white',
            boxSizing: 'border-box',
            '&:disabled': {
              backgroundColor: 'grey',
            },
          }}
        />
      </PageContainer>
    </React.Fragment>
  )
}

export default Save
